import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppService } from "src/app/core/services/app.service";

@Injectable({providedIn: 'root'})
export class AdminRoutesAuthGuard implements CanActivate {
    constructor(private appService: AppService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.appService.loggedInStatus;
    }
}