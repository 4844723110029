<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_STATE_LABEL}}</h2>
            </div>
            <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="addTask()"
                    *ngIf="!formShow"><span>Add</span></button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <div class="col-sm-12 col-p  main-content">
            <div class="card p-10 post-inner" *ngIf="!formShow">
                <div class="py-10 text-right">
                </div>
                <div class="post-body table-post">
                    <div class="table-responsive">
                        <table
                            class="table table-borderless table-striped table-thead-bordered table-nowrap table-align-middle">
                            <thead class="table-header">
                                <tr>
                                    <th *ngFor="let item of tHeaders;let i=index;">
                                        <div class="p-d-block p-jc-between p-ai-center">
                                            {{item?.header}}
                                        </div>
                                    </th>
                                    <th style="width: 5%;">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="noDataFound">
                                    <td class="post_details_inner text-center" colspan="100">
                                        <div class="post-inner">
                                            <div class="post-body no-data">
                                                <p class="mb-0 font-weight-bold">No Records Found</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of categoryList">
                                    <td *ngFor="let h of tHeaders" [ngClass]="{'text-center': h?.key == 'isLive'}">
                                        <ng-container>
                                            <span>{{getColValue(item[h?.key])}}</span>
                                              </ng-container>
                                    </td>
                                    <td class="d-flex">
                                        <button class="btn btn-sm btn-success btn-color mr-2" href="javascript:void(0);"
                                            title="Edit" (click)="update(item)"><i class="fa fa-edit mr-1"></i>
                                        </button>
                                        <button class="btn btn-sm btn-danger btn-color" 
                                            href="javascript:void(0);" title="Delete" (click)="deleteConfirm(item)"><i
                                                class="fa fa-trash mr-1"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngIf="formShow">
                <div class="modal-content border-0">
                    <div class="modal-header  bg-danger py-10">
                        <h4 class="modal-title float-left ">
                            {{buttonName==commonEnum.SAVE ? 'Create' : 'Update'}} {{redirectUrls.ADMIN_STATE_LABEL}}
                        </h4>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="postForm">
                            <div class="row">
                                <form [formGroup]="postForm">
                                    <div class="row">
                                        
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="name">Country<sup>*</sup></label>
                                            <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="countryId">
                                           <option value="" selected>-- Select --</option>
                                           <option *ngFor="let item of countryList" [value]="item?.id">
                                               {{item?.name}}
                                           </option>
                                       </select> <div *ngIf="submitted && postForm.get('countryId').errors" class="invalid-feedback">
                                                <div *ngIf="postForm.get('countryId').errors.required">Country is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="name">Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="name" name="name"
                                                id="name" placeholder="Enter Name" aria-label="name" maxlength="10"
                                                #name
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('name').errors }">
                                            <div *ngIf="submitted && postForm.get('name').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('name').errors.required">Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="shortName">Short Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="shortName" 
                                                placeholder="Enter Short Name" aria-label="shortName"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('shortName').errors }">
                                            <div *ngIf="submitted && postForm.get('shortName').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('shortName').errors.required"> Short Name is required.
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <hr class="edit-last-separator-hr" />
                                    <!-- {{postForm.value | json}} -->
                                </div>
                            </div>

                            <div class="form-group actionBtn-form-group">
                                <div class="float-right">
                                    <button type="button" class="btn btn-danger btn-md mr-1"
                                        (click)="cancel()">Cancel</button>
                                    <button type="button" class="btn btn-success btn-md"
                                        (click)="save(buttonType.SUBMIT_CLOSE)"
                                        *ngIf="buttonName==commonEnum.SAVE && !processing">Save</button>
                                    <button type="button" class="btn btn-success btn-md "
                                        (click)="save(buttonType.SUBMIT_UPDATE)"
                                        *ngIf="buttonName==commonEnum.UPDATE && !processing">Update</button>
                                    <button class="btn btn-success btn-md" type="button" *ngIf="processing"
                                        disabled>{{commonEnum.PROCESSING}}</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>