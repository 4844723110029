<div class="content-header" *ngIf="(adminType == null && !formShow) || (adminType == 'super' && formShow)">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{ redirectUrls.ADMIN_USERS_LABEL }}</h2>
                <!-- <button class="btn btn-prinary" (click)="verifyOtpModel()">verity</button> (ngModelChange)="loadData()" -->
            </div>
            <div class="col-sm-6 text-right d-flex justify-content-end align-items-center">
                <div class="input-group" *ngIf="!formShow">
                    <input type="text" class="form-control search form-control-sm" maxlength="30" [(ngModel)]="search" [ngModelOptions]="{ standalone: true }" name="search" id="search" placeholder="Search Name" aria-label="search" />
                    <span class="fa fa-search form-control-feedback" (click)="superAdminData($event)" title="Search"></span>
                    <!-- <div class="input-group-append">
                        <button class="input-group-text cursor-pointer"><i class="fa fa-search"></i>&nbsp;</button>
                    </div> -->
                </div>
                <button class="btn btn-danger btn-sm float-right add-btn" *ngIf="!formShow" (click)="addForm('super')">
          Add
        </button>
                <button class="btn btn-danger btn-sm float-right add-btn" *ngIf="formShow" (click)="save('super')">
          Save
        </button>
                <button class="btn btn-danger btn-sm float-right add-btn ml-1" *ngIf="formShow" (click)="addForm(null)">
          Cancel
        </button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m" *ngIf="!formShow">
        <div class="cards">
            <div class="row">
                <ng-container *ngIf="!isAdmingDataLoaded">
                    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of skeletonArray">
                        <div class="user-card card">
                            <div class="card-body">
                                <div class="d-flex justify-content-center">
                                    <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
                                    <i class="skeleton pointer" aria-hidden="true">
                    <ngx-skeleton-loader
                      count="1"
                      appearance="line"
                      [theme]="{ width: '20', height: '20px' }"
                    ></ngx-skeleton-loader>
                  </i>
                                </div>
                                <h5 class="card-title mb-0">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '80%', height: '17px' }"></ngx-skeleton-loader>
                                </h5>
                                <p class="card-text mb-0">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '100%', height: '17px' }"></ngx-skeleton-loader>
                                </p>
                                <p class="card-text">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '75%', height: '17px' }"></ngx-skeleton-loader>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isAdmingDataLoaded">
                    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of usersList">
                        <div class="user-card card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <img class="card-img-top" *ngIf="item?.pictures == ''" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                    <img class="card-img-top" *ngIf="item?.pictures !== ''" [src]="item?.pictures" onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';" />
                                    <i class="fa fa-pen pointer" (click)="edit(item, 'super')" aria-hidden="true"></i>
                                </div>
                                <h5 class="card-title">{{ item?.firstName }}</h5>
                                <p class="card-text">
                                    <!-- <small>Email:</small> -->
                                    {{ item?.email }}
                                </p>
                                <p class="card-text">{{ item?.mobileNumber }}</p>
                                <!-- <div class="dropdown">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                        Dropdown button
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Link 1</a>
                                        <a class="dropdown-item" href="#">Link 2</a>
                                        <a class="dropdown-item" href="#">Link 3</a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row text-center" *ngIf="!usersList.length && isAdmingDataLoaded">
                    <p class="p-5 text-secondary">No Data Found..!!</p>
                </div>

                <pagination [ngClass]="{ 'd-none': noDataFound, 'd-flex': !noDataFound }" class="justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords"
                    (pageChanged)="pageChanged($event)">
                </pagination>

                <!-- </ng-container> -->
            </div>
        </div>
    </div>
</div>
<div class="content-header" *ngIf="
    (adminType == null && !formShow) || (adminType == 'station' && formShow)
  ">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">
                    {{ redirectUrls.ADMIN_STATION_USERS_LABEL }}
                </h2>
            </div>
            <div class="col-sm-6 text-right d-flex justify-content-end align-items-center">
                <div class="input-group" *ngIf="!formShow">
                    <!-- <input type="text" class="form-control search form-control-sm" maxlength="30" [(ngModel)]="search2" [ngModelOptions]="{ standalone: true }" name="search" id="search" placeholder="Search Name" aria-label="search" />
                    <span class="fa fa-search form-control-feedback" (click)="stationUsersData()" title="Search"></span> -->

                    <input class="form-control" type="text" [(ngModel)]="search2" placeholder="Search">

                    <span class="fa fa-search form-control-feedback" (click)="searchData($event)" title="Search"></span>


                    <!-- <div class="input-group-append">
                        <button class="input-group-text cursor-pointer"><i class="fa fa-search"></i>&nbsp;</button>
                    </div> -->
                </div>
                <button class="btn btn-danger btn-sm float-right add-btn" *ngIf="!formShow" (click)="addForm('station')">
          <span>Add</span>
        </button>
                <button class="btn btn-danger btn-sm float-right add-btn" *ngIf="formShow" (click)="save('station')">
          <span>Save</span>
        </button>
                <button class="btn btn-danger btn-sm float-right add-btn ml-1" *ngIf="formShow" (click)="addForm(null)">
          <span>Cancel</span>
        </button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m" *ngIf="!formShow">
        <div class="cards">
            <div class="row">
                <!-- <ng-container *ngIf="!isStationDataLoaded">
                    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of skeletonArray">
                        <div class="user-card card">
                            <div class="card-body">
                                <div class="d-flex justify-content-center">
                                    <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
                                    <i class="skeleton pointer" aria-hidden="true">
                    <ngx-skeleton-loader
                      count="1"
                      appearance="line"
                      [theme]="{ width: '20', height: '20px' }"
                    ></ngx-skeleton-loader>
                  </i>
                                </div>
                                <h5 class="card-title mb-0">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '80%', height: '17px' }"></ngx-skeleton-loader>
                                </h5>
                                <p class="card-text mb-0">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '100%', height: '17px' }"></ngx-skeleton-loader>
                                </p>
                                <p class="card-text">
                                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ width: '75%', height: '17px' }"></ngx-skeleton-loader>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container> -->
                <ng-container *ngIf="stationDataLoaded">
                    <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of stationUsersListing">
                        <div class="card user-card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <!-- <img class="card-img-top" *ngIf="item?.pictures == ''" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" /> -->
                                    <!-- <img class="card-img-top" *ngIf="item == 'null'" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" /> -->

                                    <img class="card-img-top" *ngIf="item?.pictures" src="{{item.pictures}}" />
                                    <img class="card-img-top" *ngIf="item == null" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                    <img class="card-img-top" *ngIf="item?.pictures == ''" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                                    <!-- <img class="card-img-top" *ngIf="item.contact?.picture !== ''" [src]="imgepath + item?.pictures" onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';" /> -->
                                    <!-- <img class="card-img-top" src="{{imgepath}}{{item?.pictures}}"> -->
                                    <!-- <i class="fa fa-pen pointer" (click)="edit(item,'station')" aria-hidden="true"></i> -->
                                </div>
                                <h4 class="card-title" *ngIf="item">{{ item.name }}</h4>
                                <h4 class="card-title" *ngIf="item == null"> Dummy Name </h4>
                                <p class="card-text" *ngIf="item"> {{ item.email }} </p>
                                <p class="card-text" *ngIf="item == null"> dummy@gmail.com </p>
                                <p class="card-text" *ngIf="item">{{ item.mobileNumber }}</p>
                                <p class="card-text" *ngIf="item == null"> 99999XXXXX </p>
                                <!-- <div class="dropdown">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                        Dropdown button
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#">Link 1</a>
                                        <a class="dropdown-item" href="#">Link 2</a>
                                        <a class="dropdown-item" href="#">Link 3</a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row text-center" *ngIf="!stationUsersListing.length && isStationDataLoaded">
                    <p class="p-5 text-secondary">No Data Found..!!</p>
                </div>
                <pagination [ngClass]="{ 'd-none': noDataFound2, 'd-flex': !noDataFound2 }" class="justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords2"
                    [ngModel]="pageIndex2" (pageChanged)="pageChanged2($event)">
                </pagination>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row row-m" *ngIf="formShow">
        <label for="" class="img-label" *ngIf="editForm">
      <span class="remove-icon"
        ><i *ngIf="url !== null" class="fa fa-times text-danger pointer"></i
      ></span>
      <input
        class="inputfile"
        id="file"
        accept="image/*"
        (change)="onImage1Selected($event)"
        type="file"
      />
      <label
        class="fs-14 dis-inline img-circle"
        for="file"
        [ngClass]="{ 'img-text': url == null }"
      >
        <span *ngIf="url == null">Select Image</span>
        <img
          [src]="url"
          class="img-show"
          width="100"
          height="100"
          alt=""
          *ngIf="url !== null"
        />
      </label>
        </label>
        <form [formGroup]="postForm" class="mt-5">
            <div class="row col-10">
                <div class="form-group col-lg-4 col-p">
                    <div class="input-group">
                        <div class="input-group-append">
                            <select class="form-select h-100" formControlName="prefix">
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
              </select>
                        </div>
                        <!-- <input type="text" class="form-control first-name-ctrl" appAlphabetOnly maxlength="30" formControlName="fullName" name="fullName" id="fullName" placeholder="Full Name*" aria-label="fullName" #fullName [ngClass]="{
                'is-invalid': submitted && postForm.get('fullName').errors
              }" /> -->
                        <input type="text" class="form-control first-name-ctrl" maxlength="30" formControlName="fullName" name="fullName" id="fullName" placeholder="Full Name*" aria-label="fullName" #fullName [ngClass]="{
                'is-invalid': submitted && postForm.get('fullName').errors
              }" />
                        <small class="d-block w-100 text-end dis-inline lineH-1 float-right input-hint mb-0">
              {{ fullName.value.length }}/30
            </small>
                        <div *ngIf="submitted && postForm.get('fullName').errors" class="invalid-feedback">
                            <div *ngIf="postForm.get('fullName').errors.required">
                                Full Name is required.
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group col-lg-4 col-p">
                    <input type="text" class="form-control" appAlphabetOnly maxlength="30" #middleName formControlName="middleName" name="middleName" id="middleName" placeholder="Middle Name" aria-label="middleName" [ngClass]="{
              'is-invalid': submitted && postForm.get('middleName').errors
            }" />
                    <small class="dis-inline lineH-1 float-right input-hint mb-0">
            {{ middleName.value.length }}/30
          </small>
                    <div *ngIf="submitted && postForm.get('middleName').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('middleName').errors.required">
                            Middle Name is required.
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group col-lg-4 col-p">
                    <input type="text" class="form-control" appAlphabetOnly maxlength="30" #lastName formControlName="lastName" name="lastName" id="lastName" placeholder="Last Name*" aria-label="lastName" [ngClass]="{
              'is-invalid': submitted && postForm.get('lastName').errors
            }" />
                    <small class="dis-inline lineH-1 float-right input-hint mb-0">
            {{ lastName.value.length }}/30
          </small>
                    <div *ngIf="submitted && postForm.get('lastName').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                    </div>
                </div> -->
                <div class="form-group col-lg-4 col-p">
                    <input type="text" class="form-control" maxlength="30" #userName formControlName="userName" name="userName" id="userName" placeholder="User Name*" aria-label="userName" [ngClass]="{
              'is-invalid': submitted && postForm.get('userName').errors
            }" />
                    <small class="dis-inline lineH-1 float-right input-hint mb-0">
            {{ userName.value.length }}/30
          </small>
                    <div *ngIf="submitted && postForm.get('userName').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('userName').errors.required">
                            User Name is required.
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-4 col-p">
                    <input type="email" class="form-control" formControlName="email" name="email" id="email" placeholder="Email*" aria-label="email" [ngClass]="{
              'is-invalid': submitted && postForm.get('email').errors
            }" [readonly]="editForm" />
                    <!-- <small class="dis-inline lineH-1 float-right input-hint mb-0">
                      {{email.value.length}}/100
                  </small> -->
                    <div *ngIf="submitted && postForm.get('email').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('email').errors.required">
                            Email is required.
                        </div>
                        <div *ngIf="postForm.get('email').errors.email">
                            Specified Email is not valid.
                        </div>
                    </div>
                </div>
                <div class="form-group col-lg-4 col-p">
                    <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="status">
            <option value="" disabled selected>Active/InActive</option>
            <option *ngFor="let item of activeList" [value]="item?.val">
              {{ item?.label }}
            </option>
          </select>
                    <div *ngIf="submitted && postForm.get('status').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('status').errors.required">
                            Active/InActive is required.
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group col-lg-4 col-p">
                    <input type="text" class="form-control" formControlName="countryCode" name="countryCode" id="countryCode" placeholder="Country Code" aria-label="countryCode" readonly [ngClass]="{ 'is-invalid': submitted && postForm.get('countryCode').errors }">
                    <div *ngIf="submitted && postForm.get('countryCode').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('countryCode').errors.required">Country Code is required.
                        </div>
                    </div>
                </div> -->
                <div class="form-group col-lg-4 col-p">
                    <input type="text" class="form-control" maxlength="10" prefix="+91" numberOnly #mobileNumber formControlName="mobileNumber" name="mobileNumber" id="mobileNumber" placeholder="Mobile Number*" aria-label="mobileNumber" [ngClass]="{
              'is-invalid': submitted && postForm.get('mobileNumber').errors
            }" />
                    <small class="dis-inline lineH-1 float-right input-hint mb-0">
            {{ mobileNumber.value.length }}/10
          </small>
                    <div *ngIf="submitted && postForm.get('mobileNumber').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('mobileNumber').errors.required">
                            Mobile Number is required.
                        </div>
                    </div>
                </div>

                <!-- <div class="form-group col-lg-4 col-p">
                    <input type="tel" class="form-control" formControlName="mobileNumber" name="mobileNumber" id="mobileNumber" placeholder="+91 Mobile Number2" aria-label="mobileNumber" [ngClass]="{ 'is-invalid': submitted && postForm.get('mobileNumber').errors }">
                    <div *ngIf="submitted && postForm.get('mobileNumber').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('mobileNumber').errors.required">Mobile Number is required.
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group col-lg-4 col-p">
                    <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="gender">
                           <option value="" selected>Gender</option>
                           <option *ngFor="let item of genderList" [value]="item">
                               {{item}}
                           </option>
                       </select>
                    <div *ngIf="submitted && postForm.get('gender').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('gender').errors.required">Gender is required.
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group col-lg-4 col-p">
                    <input type="date" class="form-control" formControlName="dateOfBirth" name="dateOfBirth" id="dateOfBirth" placeholder="DOB" aria-label="dateOfBirth" [ngClass]="{ 'is-invalid': submitted && postForm.get('dateOfBirth').errors }">
                    <div *ngIf="submitted && postForm.get('dateOfBirth').errors" class="invalid-feedback">
                        <div *ngIf="postForm.get('dateOfBirth').errors.required">DOB is required.
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group col-lg-4 col-p">
                            <input type="text" class="form-control" formControlName="addressLine" name="addressLine" id="addressLine"
                                placeholder="Address Line" aria-label="addressLine"
                                [ngClass]="{ 'is-invalid': submitted && postForm.get('addressLine').errors }">
                            <div *ngIf="submitted && postForm.get('addressLine').errors" class="invalid-feedback">
                                <div *ngIf="postForm.get('addressLine').errors.required">Address Line is required.
                                </div>
                            </div>
                        </div> -->
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr class="edit-last-separator-hr" />
                </div>
            </div>
            <div class="form-group col-lg-4 col-p" *ngIf="!editForm">
                <label class="m-0 text-light"> Password </label>
                <input type="password" class="form-control" formControlName="password" name="password" id="password" placeholder="Password*" aria-label="password" [ngClass]="{
            'is-invalid': submitted && postForm.get('password').errors
          }" />
                <div *ngIf="submitted && postForm.get('password').errors" class="invalid-feedback">
                    <div *ngIf="postForm.get('password').errors.required">
                        Password is required.
                    </div>
                    <div *ngIf="postForm.get('password').errors.pattern">
                        Password must contain at least: 8Digits ,one uppercase letter, one lowercase letter, one digit, and any special character .
                    </div>
                </div>
                <!-- <button class="btn btn-danger btn-sm border float-left add-btn1" *ngIf="formShow" (click)="save('station')"><span>Save</span></button> -->
            </div>
        </form>
    </div>
</div>
<!-- <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Email Verification</h5>
                <button type="button" class="close btn btn-danger btn-icon btn-sm" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div class="modal-body">
                <form action="" [formGroup]="verifyForm">
                    <div class="form-group col-p">
                        <input type="text" class="form-control" formControlName="emailVerificationCode" name="emailVerificationCode" id="emailVerificationCode" placeholder="Enter OTP" aria-label="emailVerificationCode" #otp [ngClass]="{ 'is-invalid': otpSubmitted && verifyForm.get('emailVerificationCode').errors }">
                        <div *ngIf="otpSubmitted && verifyForm.get('emailVerificationCode').errors" class="invalid-feedback">
                            <div *ngIf="verifyForm.get('emailVerificationCode').errors.required">Otp is required.
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="onSubmitCode()">Submit</button>
            </div>
        </div>
    </div>
</div> -->