<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light fs-48">
                    {{ redirectUrls.ADMIN_VEHICLES_LABEL }}
                </h2>
            </div>
            <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="addTask()" *ngIf="!formShow">
          <span>Add</span>
        </button>
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="cancel()" *ngIf="formShow">
          <span>Cancel</span>
        </button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m" *ngIf="!formShow">
        <div class="cards">
            <div class="row">
                <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of vehicleList">
                    <div class="card vehicle-card">
                        <div class="card-body">
                            <div class="d-flex">
                                <img class="card-img-top" src="https://cdn.dribbble.com/users/310943/screenshots/2792692/empty-state-illustrations.gif" alt="Card image" />
                                <i class="fa fa-pen pointer" (click)="edit(item)" aria-hidden="true"></i>
                            </div>
                            <h4 class="card-title">
                                {{ item?.name }}
              </h4>
              <p class="card-text mb-0">
                 {{ item?.vehicleManufactureData?.organizationName }}
                                </p>
                                <p class="card-text mb-0">
                                    {{ item?.vehiclePortData?.name }}
                                </p>
                        </div>
                    </div>
                </div>
                <div class="row text-center" *ngIf="!vehicleList.length">
                    <p class="p-5 text-secondary">No Data Found..!!</p>
                </div>
                <pagination [ngClass]="{'d-none': noDataFound,'d-flex': !noDataFound}" class=" justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords"
                    (pageChanged)="pageChanged($event)">
                </pagination>
            </div>
        </div>
    </div>
    <div class="row row-m" *ngIf="formShow">
        <app-vehicles (cancel)="addTask(true)" [parentData]="parentObj"></app-vehicles>
    </div>
</div>