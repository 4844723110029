import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LOGIN } from '../core/configurations/url';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { ApexTheme } from 'ng-apexcharts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  countData: any = [];
  noDataFound: boolean;
  currentUser: any;
  searchKeyword: FormControl = new FormControl('');
  isOpen = false;
  searchedStationsList: { name: string; picture: string; id: string }[];
  paymentsHistory: any;
  total: number;
  chartOptions: any;
  theme: ApexTheme;
  color = ['#ff2bc1'];
  profitableStationsList: any[] = [];
  monthlyPayments: number[];
  showPaymentsGraph = false;
  stationsStausChart: any;
  stationReportList: any[];
  showStationsGraph = false;
  constructor(
    private commonService: CommonService,
    private appService: AppService,
    public overlay: Overlay
  ) {}

  ngOnInit(): void {
    // this.chartOptions = {
    //   series: [
    //     {
    //       name: "Montly Payments",
    //       data: [1000, 41000, 35000, 51000, 49000]
    //     }
    //   ],
    //   chart: {
    //     height: 250,
    //     type: "bar"
    //   },
    //   title: {
    //     text: "Payment Report"
    //   },
    //   xaxis: {
    //     categories: ["Jan", "Feb",  "Mar",  "Apr",  "May"]
    //   }
    // };
    this.currentUser = this.appService.getUserDetails();
    this.searchKeyword.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value: string) => {
        this.searchStations(value);
      });
    this.paymentHistory();
    this.profitableStations();
    this.loadData();
    this.getStationsReport();
  }

  loadData() {
    const url = LOGIN.dashboard;
    this.noDataFound = false;
    let obj = { userId: this.currentUser._id };
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.countData = res.responseData;
          this.noDataFound = true;
        }
      }
    );
  }

  toggleOverlay() {
    this.isOpen = !this.isOpen;
  }

  searchStations(value: string) {
    this.commonService.commonApiCall(
      LOGIN.searchEvStations + `?name=${value}`,
      HttpMethod.POST,
      { name: value },
      (res, statusFlag) => {
        if (res.responseData.length) {
          console.log(res, statusFlag);
          this.searchedStationsList = res.responseData.map((station) => ({
            name: station.name,
            picture: station.stationPictures.length
              ? station.stationPictures
              : '../../assets/img/ev-charging.gif',
            id: station._id,
          }));
          // this.searchedStationsList = [...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList,...this.searchedStationsList]
        } else {
          this.searchedStationsList = [];
        }
      }
    );
  }

  getStationsReport(): void {
    this.commonService.commonApiCall(
      LOGIN.stationReport,
      HttpMethod.POST,
      {},
      (res) => {
        const activeSeries = [];
        const inactiveSeries = [];
        const xaxisLabels = [];
        this.stationReportList = res.responseData;
        this.stationReportList.forEach((ele) => {
          activeSeries.push(ele.activeCount);
          inactiveSeries.push(ele.inActiveCount);
          xaxisLabels.push(ele.createdAt);
        });
        this.renderStationReportGraph(
          activeSeries,
          inactiveSeries,
          xaxisLabels
        );
      }
    );
  }

  paymentHistory(): void {
    this.commonService.commonApiCall(
      LOGIN.paymentHistory,
      HttpMethod.POST,
      {},
      (res) => {
        this.paymentsHistory = res.monthlyProfit;
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        this.monthlyPayments = [
          0,
          ...this.paymentsHistory.map((ele) => ele.total),
        ];
        const monthNames = this.paymentsHistory.map(
          (ele) => months[ele._id.month]
        );
        this.renderPaymentsGraph(['Jan', ...monthNames]);
        this.total = this.paymentsHistory
          .map((ele) => ele.total)
          .reduce((a, b) => a + b);
      }
    );
  }

  renderStationReportGraph(
    activeSeries: number[],
    inactiveSeries: number[],
    xaxisLabels: string[]
  ): void {
    this.stationsStausChart = {
      series: [
        {
          name: 'Active statoins',

          data: activeSeries,
        },
        {
          name: 'Inactive statoins',
          data: inactiveSeries,
        },
      ],
      chart: {
        height: 350,
        type: 'line',
        foreColor: '#fff',
        toolbar: {
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        width: 4,
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM',
          style: {
            colors: Array(xaxisLabels.length).fill('#fff'),
          },
          // formatter: function (value, timestamp) {
          //   return new Date(timestamp) // The formatter function overrides format property
          // },
        },
        categories: xaxisLabels,
      },
      title: {
        text: 'Station Analytics',
        align: 'left',
        style: {
          fontSize: '18px',
          color: '#fff',
        },
      },
      fill: {
        colors: ['#ff2bc1', '#ff943f'],
        type: 'solid',
        // gradient: {
        //   shade: "dark",
        //   gradientToColors: ["#FDD835"],
        //   shadeIntensity: 1,
        //   type: "horizontal",
        //   opacityFrom: 1,
        //   opacityTo: 1,
        //   stops: [0, 100, 100, 100]
        // }
      },
      markers: {
        size: 4,
        colors: ['#FFA41B'],
        strokeColors: '#fff',
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      grid: {
        borderColor: '#ff2bc11a',
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        labels: {
          colors: ['#fff', '#fff'],
        },
      },
      yaxis: {
        min: -10,
        max: 40,
        title: {
          text: 'Stations count',
          style: {
            color: '#fff',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            colors: Array(xaxisLabels.length).fill('#fff'),
          },
          // formatter: function (value, timestamp) {
          //   return new Date(timestamp) // The formatter function overrides format property
          // },
        },
      },
    };
    console.log(this.stationsStausChart);
    this.showStationsGraph = true;
  }

  renderPaymentsGraph(months?: string[]): void {
    this.chartOptions = {
      chart: {
        height: 380,
        type: 'bar',
        foreColor: '#6D6D6D',
        toolbar: {
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      title: {
        text: 'Payment Report',
        style: {
          fontSize: '17px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#fff',
        },
      },
      series: [
        {
          name: 'Montly Payments',
          data: this.monthlyPayments,
        },
      ],
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.5,
          type: 'vertical',
          opacityFrom: 0.2,
          opacityTo: 0.9,
          shade: 'light',
          colorStops: [
            {
              offset: 0,
              color: '#ff2bc1',
              opacity: 1,
            },
            {
              offset: 80,
              color: '#ff943f',
              opacity: 1,
            },
          ],
        },
      },
      grid: {
        borderColor: '#ff2bc11a',
      },
      xaxis: {
        labels: {
          style: {
            colors: Array(months.length).fill('#fff'),
          },
          // formatter: function (value, timestamp) {
          //   return new Date(timestamp) // The formatter function overrides format property
          // },
        },
        categories: months,
      },
      yaxis: {
        labels: {
          style: {
            colors: Array(months.length).fill('#fff'),
          },
          // formatter: function (value, timestamp) {
          //   return new Date(timestamp) // The formatter function overrides format property
          // },
        },
      },
    };
    console.log(this.chartOptions);
    this.showPaymentsGraph = true;
  }

  profitableStations(): void {
    this.commonService.commonApiCall(
      LOGIN.profitableStations,
      HttpMethod.POST,
      {},
      (res) => {
        this.profitableStationsList = res.responseData;
      }
    );
  }
}
