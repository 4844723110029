import { HttpMethod } from './../core/enums/http-handler.enum';
import { Component, OnInit } from '@angular/core';
import { TRANSACTIONS_URL } from '../core/configurations/url';
import { CommonService } from '../core/services/common.service';
import { RedirectRoutes } from '../core/enums/route.enum';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  tHeaders: any = [
    {
      header: "S.No",
      key: "sNo"
    },
    {
      header: "Ticket Number",
      key: "ticketNumber"
    },
    {
      header: "Station Name",
      key: "stationName"
    },
    {
      header: "City",
      key: "city"
    },
    {
      header: "Amount",
      key: "amount"
    }];
  noDataFound: boolean;
  transactionsList: any = [];
  tabId: number = 1;
  transactionTabs = ['Day', 'Monthly', 'Yearly'];
  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  loadData(){
    let url = TRANSACTIONS_URL.orderDetails;
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      null,
      (res,statusFlag) => {
        if(statusFlag){
          // console.log(res);
          this.transactionsList = res.responseData;
        }

      }
    )
  }
   //key val return
   getColValue(colValue) {
    if (Array.isArray(colValue)) {
      const arrayElements = [];
      colValue.forEach((ele) => {
        if (typeof ele !== 'object') {
          arrayElements.push(ele);
        }
      });
      return arrayElements.toString();
    }
    return colValue;
  }
  tab(id) {
    this.tabId = id;
  }
}
