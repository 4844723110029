import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Appconfig } from '../enums/app-config.enum';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private isLoggedIn = false;
  private notify = new Subject<{option: string; value: any}>();
  notifyObservable$ = this.notify.asObservable();
  firebaseToken: string;
    constructor() { }
    
    set loggedInStatus(value: boolean) {
      this.isLoggedIn = value;
  }
  
  notifyOther(data: {option: string; value: any}): void {
      if (data) {
          this.notify.next(data);
      }
  }
  get loggedInStatus() {
    return this.isLoggedIn;
  }
  setUserDetails(token) {
    this.isLoggedIn = true;
    localStorage.setItem(Appconfig.CURRENTUSER, JSON.stringify(token));
  }
  getUserDetails() {
    return JSON.parse(localStorage.getItem(Appconfig.CURRENTUSER));
  }
  clearUserDetails() {
    this.notifyOther({option: 'logout', value: false});
    this.isLoggedIn = false;
    localStorage.removeItem(Appconfig.CURRENTUSER); // localStorage.clear();
  }
   //to check null or undefined
   checkNullOrUndefined(val) {
    if (val === null || val === undefined) {
      return true;
    } else {
      return false;
    }
  }
}
