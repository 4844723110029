import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  currentUser: any;
  isMaxSidebar: boolean = true;
  constructor(private appService: AppService, private router: Router) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
  }
  logout(){
    this.appService.clearUserDetails();
    this.router.navigate([this.redirectUrls.HOME]);
  }
}
