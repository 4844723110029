<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_NOTIFICATIONS_LABEL}}</h2>
                <!-- <h2 class="m-0 text-light">{{redirectUrls.ADMIN_REQUEST_COMPONENT_POST_LABEL}}</h2> -->
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <form [formGroup]="notificationsForm" (ngSubmit)="sendNotification()">
        <div class="form-group mt-3">
            <select formControlName="roleName" name="user" id="user" class="form-select">
                <!-- <option value="">Select User</option> -->
                <option value="StationAdmin">StationAdmin</option>
                <option value="User">User</option>
            </select>
            <!-- <input class="form-control" formControlName="email" placeholder=" " id="email" type="email" name="email" [ngClass]="{ 'is-invalid': submitted && postForm.get('email').errors }" /> -->
            <!-- <label for="user">Select User<sup>*</sup></label> -->
            <div *ngIf="submitted && notificationsForm.get('user').errors" class="invalid-feedback">
                <div *ngIf="notificationsForm.get('user').errors.required">User is required.
                </div>
            </div>
        </div>
        <div class="form-group col-md-6 col-sm-12 col-xs-12 mt-3">
            <input type="text" class="form-control" maxlength="100" placeholder="Title" formControlName="title">
            <small class="d-block w-100 text-end dis-inline lineH-1 float-right input-hint mb-0">
              {{ notificationsForm.controls.title.value.length }}/100
            </small>
        </div>
        <div class="form-group mt-3">
            <textarea class="form-control" maxlength="500" name="" placeholder="Message" id="" cols="30" rows="10" formControlName="description"></textarea>
            <small class="d-block w-100 text-end dis-inline lineH-1 float-right input-hint mb-0">
              {{ notificationsForm.controls.description.value.length }}/500
            </small>
        </div>
        <div class="form-group mt-3">
            <button class="btn btn primary theme-bg">
                Send
            </button>
        </div>
    </form>
    <!-- <p>notifications works!</p> -->
</div>