import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LOGIN } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  postForm: FormGroup;
  redirectUrls = RedirectRoutes;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private sweetAlert: SweetalertService

  ) { }

  ngOnInit(): void {
    this.createForm();

  }
  createForm() {
    this.postForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }
  onSubmit() {
    let url = LOGIN.TOKEN;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      this.postForm.value,
      (result, statusFlag) => {
        console.log("res", result);
        if (statusFlag) {
          if (result) {
          }
        }
        // this.spinner.hide();
      }
    );
  }
}
