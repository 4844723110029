<section>
    <form action="" autocomplete="off" [formGroup]="postForm">
        <div class="left-content mr-3">
            <img class="fill" src="assets/img/Fill.png" alt="">
            <img class="d-block groups-img" src="assets/img/group.png" alt="">
            <img class="d-block down-fill" src="assets/img/Fill.png" alt="">
        </div>
        <div class="right-content flex-fill">
            <div class="content">
                <div class="login-details">
                    <h4>{{resetFormShow ? redirectUrls.ADMIN_RESET_LABEL : redirectUrls.ADMIN_FORGOT_LABEL}}</h4>
                    <span class="lorem" *ngIf="!resetFormShow">Please enter your existing email</span>
                    <div class="form-controls">
                        <div class="form-group mt-3">
                            <input class="form-control" [readonly]="resetFormShow" formControlName="email" placeholder=" " id="email" type="email" name="email" pattern="" [ngClass]="{ 'is-invalid': submitted && postForm.get('email').errors }" />
                            <label for="email">Email<sup>*</sup></label>
                            <div *ngIf="submitted && postForm.get('email').errors" class="invalid-feedback">
                                <div *ngIf="postForm.get('email').errors.required">Email is required.
                                </div>
                            </div>
                            <span class="password-eye pwd-notshow" *ngIf="showPassword && resetFormShow" (click)="showPasswordHandler()"><i
                              class="fa fa-eye-slash" aria-hidden="true"></i></span>
                            <span class="password-eye pwd-show" *ngIf="!showPassword && resetFormShow" (click)="showPasswordHandler()"><i class="fa fa-eye"
                              aria-hidden="true"></i></span>
                        </div>
                        <div class="form-group mt-3" formGroupName="password" *ngIf="resetFormShow">

                            <input class="form-control" [type]="showPassword?'text':'password'" formControlName="newPassword" id="password" name="password" placeholder=" " [ngClass]="{ 'is-invalid': submitted && i.get('newPassword').errors }" />
                            <label for="password">Password<sup>*</sup></label>
                            <div *ngIf="submitted && i.get('newPassword').errors" class="invalid-feedback">
                                <div *ngIf="i.get('newPassword').errors.required">Password is required.
                                </div>
                                <div *ngIf="i.get('newPassword').errors.pattern">Password must contain at least: one uppercase letter, one lowercase letter, one digit, and any special character.</div>
                            </div>

                        </div>
                        <div class="form-group mt-3" formGroupName="password" *ngIf="resetFormShow">
                            <input class="form-control" type="password" formControlName="confirmPassword" id="password" name="password" placeholder=" " [ngClass]="{ 'is-invalid': submitted && i.get('confirmPassword').errors }" />
                            <label for="password">Confirm Password<sup>*</sup></label>
                            <div *ngIf="submitted && i.get('confirmPassword').errors" class="invalid-feedback">
                                <div *ngIf="i.get('confirmPassword').errors.required">Confirm Password is required.
                                </div>

                                <div *ngIf="i.get('confirmPassword').errors.notEqual">Password and Confirm Password <strong>do not
                              match</strong>.</div>
                            </div>
                        </div>
                        <div class="back-to-login block w-100 mt-2 mb-3">
                            <a class="text-right forgot-password" href="javascript:void(0)" [routerLink]="'/'+ redirectUrls.HOME">
                          Back to login
                      </a>
                        </div>
                        <div class="actions text-center">
                            <button type="submit" class="btn btn-primary" *ngIf="!resetFormShow" (click)="onSubmit()">Send</button>
                            <button type="submit" class="btn btn-primary" *ngIf="resetFormShow" (click)="onResetSubmit()">Submit</button>
                            <!-- <button type="submit" class="btn btn-danger mr-2">Cancel</button> -->
                        </div>
                    </div>
                </div>
                <p class="mt-5 text-center">Term and Condition <a href="javascript:void(0)" [routerLink]="'/'+ redirectUrls.ADMIN_TERMS">Click here</a></p>
            </div>
        </div>
    </form>
</section>