export const CommonEnum = {
  YES: 'Yes',
  NO: 'No',
  SAVE :"Save",
  UPDATE :"Update",
  PROCESSING : 'Processing...',
  MANDOTORY_FIELDS :'Mandatory fields are required',
  DELETE: 'Are you sure you want to delete',
  DEACTIVATE :'Are you sure you want to Deactivate?',
  REMOVE :'Are you sure you want to Remove?',
  ACTIVATE :'Are you sure you want to Activate?',
};
