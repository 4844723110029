<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_LANGUAGES_LABEL}}</h2>
            </div>
            <!-- <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="addTask()"
                    *ngIf="!formShow"><span>Add</span></button>
            </div> -->
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <form [formGroup]="postForm" class="row d-flex">
            <div class="form-group col-lg-4 col-p">
                <input type="text" class="form-control" #name appAlphabetOnly formControlName="name" name="name" id="name" placeholder="Add Language" aria-label="name" maxlength="10" [ngClass]="{ 'is-invalid': submitted && postForm.get('name').errors }">
                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                  {{name.value.length}}/10
              </small>
                <div *ngIf="submitted && postForm.get('name').errors" class="invalid-feedback">
                    <div *ngIf="postForm.get('name').errors.required">Name Of the Language is required.
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="save()" style="width: 100px;height: 40px;"><span>Add</span></button>
            </div>
        </form>
        <div class="row">
            <div class="col-md-4">
                <ul class="list-group">
                    <li class="list-group-item d-flex align-items-center" *ngFor="let item of languageList">
                        <i class="fas fa-circle"></i>
                        <span class=" mr-1"> {{item?.name}}</span>
                        <div class="form-check form-switch border-0 m-0">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" [checked]="item?.status" (change)="statusUpdate($event.target.checked,item)">
                            <label class="form-check-label" for="flexSwitchCheckChecked">&nbsp;</label>
                        </div>
                    </li>
                    <li class="row list-group-item  text-center" *ngIf="!languageList.length">
                        <p class="p-5 text-secondary">No Data Found..!!</p>
                    </li>
                    <pagination [ngClass]="{'d-none': noDataFound,'d-flex': !noDataFound}" class=" justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords"
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </ul>
            </div>
        </div>
        <!-- <div class="col-sm-12 col-p  main-content">
            <div class="card p-10 post-inner" *ngIf="!formShow">
                <div class="py-10 text-right">
                </div>
                <div class="post-body table-post">
                    <div class="table-responsive">
                        <table
                            class="table table-borderless table-striped table-thead-bordered table-nowrap table-align-middle">
                            <thead class="table-header">
                                <tr>
                                    <th *ngFor="let item of tHeaders;let i=index;">
                                        <div class="p-d-block p-jc-between p-ai-center">
                                            {{item?.header}}
                                        </div>
                                    </th>
                                    <th style="width: 5%;">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="noDataFound">
                                    <td class="post_details_inner text-center" colspan="100">
                                        <div class="post-inner">
                                            <div class="post-body no-data">
                                                <p class="mb-0 font-weight-bold">No Records Found</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of languageList">
                                    <td *ngFor="let h of tHeaders" [ngClass]="{'text-center': h?.key == 'isLive'}">
                                        <ng-container>
                                            <span
                                                *ngIf=" h?.key != 'status' && h?.key != 'icon'">{{getColValue(item[h?.key])}}</span>
                                            <span *ngIf="h?.key == 'status'">{{item?.status ? 'Yes':'No'}}</span>
                                            <span class="pointer margin-right-p" *ngIf="h?.key == 'icon'">
                                                <img src="{{imgUrl}}/{{item?.icon}}" width="50">
                                            </span>  </ng-container>
                                    </td>
                                    <td class="d-flex">
                                        <button class="btn btn-sm btn-success btn-color mr-2" href="javascript:void(0);"
                                            title="Edit" (click)="update(item)"><i class="fa fa-edit mr-1"></i>
                                        </button>
                                        <button class="btn btn-sm btn-danger btn-color"
                                            href="javascript:void(0);" title="Delete" (click)="deleteConfirm(item)"><i
                                                class="fa fa-trash mr-1"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngIf="formShow">
                <div class="modal-content border-0">
                    <div class="modal-header  bg-danger py-10">
                        <h4 class="modal-title float-left ">
                            {{buttonName==commonEnum.SAVE ? 'Create' : 'Update'}}
                        </h4>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="postForm">
                            <div class="row">
                                <form [formGroup]="postForm">
                                    <div class="row">
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="name">Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="name" name="name"
                                                id="name" placeholder="Enter Name" aria-label="name" maxlength="10"
                                                #name
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('name').errors }">
                                            <div *ngIf="submitted && postForm.get('name').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('name').errors.required">Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="shortName">Short Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="shortName"
                                                placeholder="Enter Short Name" aria-label="shortName"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('shortName').errors }">
                                            <div *ngIf="submitted && postForm.get('shortName').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('shortName').errors.required"> Short Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="code">Code<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="name" name="code"
                                                 placeholder="Enter Code" aria-label="code"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('code').errors }">
                                            <div *ngIf="submitted && postForm.get('code').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('code').errors.required">Code is required.
                                                </div>
                                            </div>
                                        </div>                                        <div class="form-group col-lg-4 col-p">
                                            <label for="stat">Status<sup>*</sup></label>
                                            <div class="form-check form-switch form-control">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" formControlName="status">
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    Yes/No</label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-8 col-p">
                                            <label for="description">Description<sup>*</sup></label>
                                            <textarea type="text" class="form-control" formControlName="description"
                                                name="description" id="description" placeholder="Enter Description"
                                                aria-label="description" maxlength="50" #description
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('description').errors }"></textarea>
                                            <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                                {{description.value.length}}/50
                                            </small>
                                            <div *ngIf="submitted && postForm.get('description').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('description').errors.required">
                                                    Description is required.
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <hr class="edit-last-separator-hr" />
                                </div>
                            </div>

                            <div class="form-group actionBtn-form-group">
                                <div class="float-right">
                                    <button type="button" class="btn btn-danger btn-md mr-1"
                                        (click)="cancel()">Cancel</button>
                                    <button type="button" class="btn btn-success btn-md"
                                        (click)="save(buttonType.SUBMIT_CLOSE)"
                                        *ngIf="buttonName==commonEnum.SAVE && !processing">Save</button>
                                    <button type="button" class="btn btn-success btn-md "
                                        (click)="save(buttonType.SUBMIT_UPDATE)"
                                        *ngIf="buttonName==commonEnum.UPDATE && !processing">Update</button>
                                    <button class="btn btn-success btn-md" type="button" *ngIf="processing"
                                        disabled>{{commonEnum.PROCESSING}}</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>