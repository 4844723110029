<section class="bg-29000f">
    <nav class="navbar navbar-dark navbar-expand-md navbar-toggleable-md bg-dark fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" (click)="collapseNavMenu()">
                <div class="left-content mr-3">
                    <img class="d-block groups-img" src="../../assets/img/logo-new.png" alt="">
                </div>
            </a>
            <button class="navbar-toggler navbar-toggler-right" type="button" (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
            <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="m-3 nav-item" (click)="setActiveLink(1)" [class.active]="activeLink === 1">
                        <a class="nav-link text-white font-weight-500" (click)="collapseNavMenu()">CREDITS</a>
                    </li>
                    <li class="m-3 nav-item" (click)="setActiveLink(2)" [class.active]="activeLink === 2">
                        <a class="nav-link text-white font-weight-500" (click)="collapseNavMenu()">REPLACEMENTS</a>
                    </li>
                    <li class="m-3 nav-item" (click)="setActiveLink(3)" [class.active]="activeLink === 3">
                        <a class="nav-link text-white font-weight-500" (click)="collapseNavMenu()">RETURNS</a>
                    </li>
                    <li class="m-3 nav-item" (click)="setActiveLink(4)" [class.active]="activeLink === 4">
                        <a class="nav-link text-white font-weight-500" (click)="collapseNavMenu()">COOKIES</a>
                    </li>
                    <li class="m-3 nav-item" (click)="setActiveLink(5)" [class.active]="activeLink === 5">
                        <a class="nav-link text-white font-weight-500" (click)="collapseNavMenu()">TERMS</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <section (click)="collapseNavMenu()" class="margin-top-6 bg-29000f--First" *ngIf="targetcredit">
        <!-- Credit-Section-Start -->
        <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-10 md-offset-1 col-xs-12 m-auto">
                    <h2>Credits</h2>
                    <p>
                        Some of the images and Icons that we use are hand picked from third party web sites. We sincerely thank their support for SMBs/SMEs like us. Wherever applicable we provide the original image source (author and/or publisher).
                    </p>
                    <p>
                        <a style="font-weight: 400;" href="https://www.flaticon.com/" target="_blank">FlatIcon</a>
                    </p>
                    <p>
                        <a style="font-weight: 400;" href="https://www.freepik.com/" target="_blank">Freepik</a>
                    </p>
                </div>
            </div>
        </div>
        <!-- Credit-Section-End -->
    </section>

    <section (click)="collapseNavMenu()" class="margin-top-6 bg-29000f--Second" *ngIf="targetreplace">
        <!-- Replacement-Section-Start -->
        <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-10 md-offset-1 col-xs-12 m-auto">
                    <h2>Warranty And Replacement Policy</h2>
                    <p>
                        To provide 100% customer satisfaction we do provide Replacement Warranty. Replacement warranty may vary from one product to other. Replacement warranty may vary from one seller to other. We encourage you to check the policies before you place your orders.
                        If the product is not functional due to manufacturing defect and if the product is still under warranty period, then we may arrange for a service/repair.
                    </p>
                    <p>
                        Even after service/repair if the product is not functional then we may arrange a replacement for your product. Please note that our suppliers and partners might have discontinued such a product over a period; in those circumstances we will try our best
                        to arrange for an alternate product which is very similar to the functional aspects of your product, with your consent.
                    </p>
                    <p>
                        Service, Repair or Replacement requests due to damages of any nature apart from manufacturing defects will not be encouraged. Please send an email to <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>                        for any queries on warranty. Please do mention your order number in the subject of your email so that we can assist you more efficiently.
                    </p>
                </div>
            </div>
        </div>
        <!-- Replacement-Section-End -->
    </section>

    <section (click)="collapseNavMenu()" class="margin-top-6 bg-29000f--Third" *ngIf="targetreturn">
        <!-- Return-Section-Start -->
        <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-10 md-offset-1 col-xs-12 m-auto">
                    <h2>Returns And Refunds Policy</h2>
                    <p>
                        Our Return policies and simple and easy to understand. Your satisfaction is our priority.
                    </p>
                    <p>
                        Our return policies may vary from one category to another category. Our merchant partners may have a different policy from one product to the other. We encourage you to read and understand our terms and return policies before you place your order.
                    </p>
                    <p>
                        For all non-perishable products and product categories, wherever return is applicable, If the return is requested due to damaged goods due to transit and delivery and/or any other manufacturing defects then we will refund full order value, including shipping
                        or any other charges.
                    </p>
                    <p>
                        If you would like to return an non-perishable product for some other reasons then we will refund the order value (excluding forward shipping, return shipping charges and/or any other charges as communicated under product or category).
                    </p>
                    <p>
                        Refund will be credited back to you to your Bank Account or Credit Card or Debit Card or UPI Handle. We try our best to process the refund within 10 to 14 working days, from the day we received your returned good and verified by our warehouse partners.
                        Occasionally, refunds may be reflected after 15 working days for few orders (varies from one bank to other bank that are not in our control).
                    </p>
                    <p>
                        For Food And Perishable we may not ask you to return the products. If you are not satisfied with the quality of the product, against manufacturing defects, and/or if you have received an opened or damaged Perishable product package then we will refund
                        the order value. Our return or refund policy on perishable products may vary from one partner to other.
                    </p>
                    <p>
                        You may visit and check our Terms and Policies to know about other policies and terms of use. Please send an email to <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b> for any queries on return
                        or refund. Please do mention your order number in the subject of your email so that we can assist you more efficiently. We generally respond in 2 to 4 working days.
                    </p>
                </div>
            </div>
        </div>
        <!-- Return-Section-End -->
    </section>

    <section (click)="collapseNavMenu()" class="margin-top-6 bg-29000f--Fourth" *ngIf="targetcookie">
        <!-- Cookie-Section-Start -->
        <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-10 md-offset-1 col-xs-12 m-auto">
                    <div>
                        <h2>Cookie Notice / Cookie Policy</h2>
                        <b class="text-white"> What is this Cookie Policy about ? </b>
                        <p> This Cookie Notice describes how and why the StayHungry StayNatural LLP Group and our subsidiaries (“we,” “us” or “SHSN”) use cookies, web beacons, pixels, tags, scripts and other similar technologies in the course of our business,
                            including through websites and apps that link to this Cookie Notice. It also explains your rights to control our use of these tracking technologies. For additional information about our privacy practices, please review our
                            Privacy Notice.
                        </p>

                        <b class="text-white"> WHAT ARE COOKIES? </b>
                        <p> Cookies are small data files placed on your computer or other internet-enabled device that enable our features and functionality. They allow us to record information when you visit or interact with our websites, Mobile Apps, products
                            and services (collectively, our “Websites”). Other tracking technologies, such as web beacons and pixels work similarly to cookies, placing small data files on your device that monitor your Website activity.
                        </p>

                        <b class="text-white"> WHAT KINDS OF COOKIES AND TRACKING TECHNOLOGIES DO WE USE? </b>
                        <p> PERSISTENT AND SESSION COOKIES : We use two categories of cookies: Persistent cookies and session cookies. Persistent cookies are cookies that help us recognize you. They are stored on your device in between browser sessions, allowing
                            us to remember your preferences and actions across multiple sites and on multiple visits. Session cookies expire at the end of your browser session, allowing us to link your actions during a particular browsing session.
                        </p>

                        <b class="text-white"> THIRD PARTY COOKIES </b>
                        <p> In addition to the first party cookies set by us, we also allow third parties to set cookies on our Websites. Third parties may use cookies, web beacons, pixels, tags, scripts and other similar tracking technologies to enable the
                            third party’s features or functionalities to be provided through the Website you are using. The third party setting these cookies can recognize your device both when it visits our Website and when it visits certain other websites
                            or services. For example, our paid endorsers, or affiliates, may use third party cookies to identify you as a referral so they can be compensated if you sign up for services from us as a result of visiting one of our paid endorsers.
                            For example, third party vendors such as Google may use cookies or other device identifiers to provide you with targeted advertisements based on your past visits to our Website.
                        </p>

                        <b class="text-white"> CROSS-DEVICE TRACKING </b>
                        <p> We perform cross-device tracking which allows us to provide more relevant advertising to you on multiple devices. We do this by identifying browsing activity on your smartphones, tablets, desktop computers and other devices connected
                            to the same IP address or logged into the same account to better understand the products and services that might be of interest to you.
                        </p>

                        <b class="text-white"> LOCAL STORAGE OBJECTS </b>
                        <p> We also use Local Storage Objects (LSOs), such as HTML5, to, among other things, optimize screen presentation, video and other preference information.
                        </p>

                        <b class="text-white"> GOOGLE ANALYTICS </b>
                        <p> We use Google Analytics which is a web analytics tool that helps us understand how users engage with our Websites. Like many services, Google Analytics uses first-party cookies to track user interactions as in our case, where they
                            are used to collect information about how users use our Website. This information is used to compile reports and to help us improve our Websites. The reports disclose website trends without identifying individual visitors.
                        </p>


                        <b class="text-white"> WHY DO WE USE COOKIES? </b>
                        <p> We use the following types of cookies for the reasons described below. These cookies may be set by us or a third party service provider to perform the functions described below:
                        </p>

                        <p>
                            Required: These cookies and tracking technologies are required to help our websites work correctly. For example, these cookies allow you to navigate our Website and use essential features, including secure areas and shopping baskets.
                        </p>

                        <p>
                            Analytics: These cookies and tracking technologies help us understand how customers and visitors interact with our Websites. They provide us with information about areas of our Websites visited, time spent on our Websites, transactions performed, and
                            any error messages you receive. These cookies allow us to improve the performance of our Websites. They may collect your IP address but only for the purpose of identifying general locations of visitors and identifying fraudulent
                            or spam traffic.
                        </p>

                        <p>
                            Functional: These cookies and tracking technologies allow our Websites to remember choices you make to give you better functionality and a personalized experience. For example, when you select a specific currency on one of our Websites, we will remember
                            your currency selection when you return.
                        </p>

                        <p>
                            Advertising: These cookies and tracking technologies allow us to deliver content, including advertisements, relevant to your specific interests. This content may be delivered on our Websites or on third party websites or services. They allow us to understand
                            and improve the relevancy of our advertisements. They may track personal information, including your IP address.
                        </p>

                        <p>
                            <b>HOW CAN I CONTROL COOKIES? </b><br/> If you visit our Websites from the European Union and/or the European Economic Area, only required cookies, as described above, will be used on our Websites by default. Other cookies
                            and tracking technologies will only be used when you consent by visiting each <a href="https://preferences-mgr.truste.com/" target="_blank">Website’s Cookie Management Center</a> where available.
                        </p>

                        <p>
                            Where you visit our Website from outside Europe, all cookies and similar tracking technologies described above will be used by default. If you would prefer not to receive personalized ads based on your browser or device usage, you may generally express
                            your opt-out preference to no longer receive tailored advertisements. Please note that you will continue to see advertisements, but they will no longer be tailored to your interests.
                        </p>

                        <p>
                            If you opt out of receiving certain cookies from us, your web browser will be associated with a generic “opt-out” cookie, which will prevent us from associating any non-personal information with your browser. Our “opt-out” cookie has no expiration date.
                            Since this program is cookie-based, you may need to opt out again if you do any of the following:
                        </p>

                        <p>
                            Delete your cookies;<br /> Block cookies, including third party cookies;<br /> Switch internet browsers;<br /> Change computers; or<br /> Upgrade your web browser.<br />
                        </p>

                        <p>
                            Internet browsers allow you to change your cookie settings via the “options’ or “preferences” menu in your browser. Please note that if you set your browser to refuse or block all cookies, certain features or functionalities of our Websites will be limited
                            or unavailable to you.
                        </p>

                        <p>
                            Some internet browsers include the ability to transmit “do not track” signals. Because no industry standard has yet been developed with respect to “do not track,” our Websites do not currently process or respond to such “do not track” signals. Some internet
                            browsers may offer their own management tools for removing HTML5 and other Local Storage Objects.
                        </p>

                        <p>
                            You can opt out of Google’s use of cookies or device identifiers without affecting how you visit or use our Website. For more information on opting out of Google’s use of cookies across all websites you use, <a href="https://adssettings.google.com/authenticated"
                                target="_blank">visit</a> this Google page. To provide you with more choice on how your data is collected by Google Analytics, Google has developed an opt-out browser add-on, which is available here <a href="https://tools.google.com/dlpage/gaoptout"
                                target="_blank">here</a> and enables you to opt out of being tracked by Google Analytics. To learn how to manage privacy and storage settings for Flash cookies, <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                                target="_blank">visit</a> Further information about deleting and blocking cookies can be found
                            <a href="https://www.allaboutcookies.org" target="_blank">here</a> Further information about our advertisers’ use of cookies can be found at
                            <a href="https://www.youronlinechoices.eu/" target="_blank">European Interactive Digital Advertising Alliance</a>
                            <a href="https://www.iab.com/" target="_blank">Internet Advertising Bureau (US)</a>
                            <a href="https://www.iabeurope.eu/" target="_blank">Internet Advertising Bureau (EU)</a>
                        </p>


                        <b class="text-white"> HOW CAN I GET FURTHER INFORMATION? </b>
                        <p> If you have any questions about our use of cookies or other tracking technologies, please send an email to <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Return-Section-End -->
    </section>

    <section (click)="collapseNavMenu()" class="margin-top-6 bg-29000f--Fifth" *ngIf="targetuse">
        <!-- Terms-Section-Start -->
        <div class="container-fluid pt-5">
            <div class="row">
                <div class="col-lg-10 md-offset-1 col-xs-12 m-auto">
                    <h2> Terms of Use </h2>
                    <p>
                        <b>
    StayHungry StayNatural LLP<br/>
    GreatIndianTrade.In<br/>
    GreatIndianTrade.com<br/>
    EVPitStop.In<br/>
    StayHungryStayNatural.com<br/>
    PRIVACY NOTICE<br/>
    Last Updated : 11-June-2021</b><br/>
                    </p>

                    <p>
                        <b> TABLE OF CONTENTS </b>
                    </p>

                    <p>
                        <b> ABOUT US <br/>
    INFORMATION COVERED BY THIS PRIVACY NOTICE <br/>
    INFORMATION WE COLLECT FROM YOU <br/>
    INFORMATION YOU VOLUNTARILY PROVIDE TO US <br/>
    INFORMATION WE COLLECT ABOUT YOUR USAGE AND DEVICES <br/>
    INFORMATION WE ACQUIRE FROM SOCIAL MEDIA <br/>
    INFORMATION WE ACQUIRE FROM OTHER SOURCES <br/>
    HOW WE USE YOUR INFORMATION <br/>
    SHARING OF INFORMATION <br/>
    SHARING WITHIN THE STAYHUNGRY STAYNATURAL CORPORATE FAMILY <br/>
    SHARING WITH PARTNERS <br/>
    SHARING WITH THIRD PARTY SERVICE PROVIDERS AND VENDORS <br/>
    CORPORATE REORGANIZATIONS <br/>
    AUTHORIZED USERS <br/>
    LEGAL PROCESS <br/>
    SECURITY <br/>
    TRACKING TECHNOLOGIES AND ONLINE ADVERTISING <br/>
    CHOICE/OPT-OUT <br/>
    MARKETING COMMUNICATIONS FROM US <br/>
    EMAIL <br/>
    PHONE <br/>
    COOKIES <br/>
    INFORMATION FROM THIRD PARTIES <br/>
    UNAUTHORIZED ACCOUNTS <br/>
    RETENTION OF PERSONAL INFORMATION <br/>
    LEGAL BASIS FOR PROCESSING YOUR INFORMATION <br/>
    YOUR RIGHT TO CONTROL HOW YOUR PERSONAL INFORMATION IS USED <br/>
    RESIDENTS OF THE EUROPEAN UNION OR UNITED KINGDOM <br/>
    RESIDENTS OF CALIFORNIA <br/>
    USERS LOCATED IN BRAZIL <br/>
    THIRD PARTY LINKS AND INFORMATION COLLECTION AND USE BY OUR USERS <br/>
    CHILDREN <br/>
    INTERNATIONAL DATA TRANSFER <br/>
    COMPLAINTS </b> <br/>
                    </p>

                    <p>
                        <b> ABOUT US</b>
                        <b> StayHungry StayNatural LLP </b> and our subsidiaries (“we,” “us” or “SHSNLLP” or “SHSN” or “GIT” or "EVPS") are a leading provider of online tools for small businesses to establish and build their web presence, get found in
                        online search, and connect with customers. Our brands can be found at <a href="https://GreatIndianTrade.In" target="_blank">GreatIndianTrade.In</a> ,
                        <a href="https://GreatIndianTrade.com" target="_blank">GreatIndianTrade.com</a> ,
                        <a href="https://StayHungryStayNatural.com" target="_blank">StayHungryStayNatural.com</a> We apply common Privacy policy for all our brands and Subsidiaries.
                    </p>

                    <p>
                        We take at-most care about protecting the personal information of our customers, partners and visitors who use our websites, products and services (collectively, our “Users”). This privacy notice provides details about how your personal information is
                        collected, shared and used by us.
                    </p>

                    <b> INFORMATION COVERED BY THIS PRIVACY NOTICE </b>
                    <p>
                        This privacy notice, which may also be referred to as a “privacy policy” or simply “privacy” on some of our websites, covers personal information, including any information we collect, use and share from you, as described further below. This privacy notice
                        applies to all websites and brands, products and services, and mobile applications (collectively, the “Services” or “Products”). This privacy notice does not cover how our Users may use or share data that they collect using our
                        services. When you purchase a Product or Service from us, your personal information will be collected, used, and shared in consistent with the provisions of this privacy notice. In addition, the addendums related to Services offered
                        by SHSN (or its affiliates) or individuals in certain locations also apply to your purchase or use of those Services. We publish these addendums as part of this privacy notice, as and when we make some updates and changes.
                    </p>

                    <b> INFORMATION WE COLLECT FROM YOU </b>
                    <p>
                        In the course of your use of the Services, we obtain the following information about you as described below. We collect this data for the purposes described under “How We Use Your Information”.
                    </p>

                    <b> INFORMATION YOU VOLUNTARILY PROVIDE TO US </b>
                    <p>
                        We collect the following information from you when you provide it to us with your consent: Information you provide prior to any registration process, such as your email when you provide it to us; Information that you provide during any registration process,
                        including in connection with a co-branded offer (such as your name, company name, email address, phone number, billing address or geographic location and any other shopping, shipping preferences); Information that you provide,
                        when you call or email us (for support or otherwise) or when you use our products or services. We don’t store your credit card or debit card or payment details; Our payment service providers may optionally store your card details
                        with your consent. We will not be responsible for any financial fraud on your payment instruments that you could have used on our websites or products or services or mobile applications.
                    </p>


                    <b> INFORMATION WE COLLECT ABOUT YOUR USAGE AND DEVICES </b>
                    <p> We collect data, either directly or through one of our partners, relating to your online activity on our websites, including the following: IP address; Browser type and version; Type of device you use, operating system version, and
                        device identifier; Geographic location; Pages you view; How you got to our Services and any links you click on to leave our Services; When you update your information, communicate with us, or order new Services; Metadata about
                        your use of our Services and emails we send to you (including clicks and opens); Metadata about how your customers, contacts, and users interact with the Services we provide you; Your interactions with any videos or images we offer;
                        Issues you encounter requiring our support or assistance; and Any device or other method of communication you use to interact with the Services. We store the data we collect in a variety of places within our infrastructure, including
                        system log files, back end databases and analytics systems.
                    </p>

                    <b> INFORMATION WE ACQUIRE FROM SOCIAL MEDIA </b>
                    <p> We collect information from third party social networking sites, including information that they provide to us if you use your credentials at such social networking sites to log into some of our Services (such as your name, social
                        media account name and email address to pre-populate our sign-up form). The information you allow us to access varies by social networking site and depends on the level of privacy settings you have in place at the social networking
                        site. You can control and find out more about these privacy settings at the applicable social networking site.
                    </p>

                    <b> INFORMATION WE ACQUIRE FROM OTHER SOURCES </b>
                    <p> We also collect the following information from other sources: Information you provide to us to our partners; Information you provide to us in surveys; Information that is publicly available; and Information you consent to us receiving
                        from third parties. We also collect information that you may provide during your telephone conversations with us, which we may monitor or record. We may combine any information we collect about you with other information we have
                        about you to help us update, expand and analyze our records, identify new customers, and provide products and services that may be of interest to you. If you provide us personal information about others, or if others give us your
                        information, we will only use that information for the specific reason for which it was provided to us and other promotional activities that we may have with our Products and Services.
                    </p>

                    <b> HOW WE USE YOUR INFORMATION </b>
                    <p> We use the information we collect, both on its own and combined with any other information we collect about you, for the following business and commercial purposes: To provide the requested Services to you; To provide you with useful
                        content; To ensure the proper functioning of our Services; To offer and improve our Services; To provide you with requested information or technical support; To facilitate your movement through our websites or your use of our Services;
                        To do a better job of advertising and marketing our Services (subject to your consent where required by applicable law); To advertise and market third party products and services (subject to your consent where required by applicable
                        law); To diagnose problems with our servers or our Services; In connection with our security and compliance programs; To administer our websites; To communicate with you; To target current or prospective customers with our products
                        or services through online advertisements served on third-party sites by third-party vendors, such as Google (subject to your consent where required by applicable law); To assist us in offering you a personalized experience or
                        otherwise tailor our Services to you; and As otherwise described in this privacy notice. We also use the information we receive to produce reports on trends and statistics, such as mobile search trends, email open rates by industry,
                        campaign best practices or the number of users that have been exposed to, or clicked on, our websites or evaluated or purchased our products or services. We also use recorded telephone conversations for quality control purposes,
                        to train our employees and for our own protection and to improve customer experiences.
                    </p>

                    <b> SHARING OF INFORMATION </b>
                    <p> We share your personal information according to this privacy notice, with your consent or as necessary to provide you the Services you request, as well as to operate our business. As further described below, we will only share certain
                        personal information with: Other members of the StayHungry StayNatural LLP corporate family;
                    </p>

                    <b> Our partners; </b>
                    <p> Third party service providers and vendors; In connection with a sale, merger, acquisition or corporate reorganization; Authorized users within your organization; and For legal reasons. Further information about the third parties with
                        whom we share personal information is available here.
                    </p>

                    <b> SHARING WITHIN THE STAYHUNGRY STAYNATURAL CORPORATE FAMILY </b>
                    <p> We share personal information with other members of the StayHungry StayNatural LLP corporate family to allow our corporate affiliates to contact you with offers, services or products that may be of interest to you and to provide you
                        with their products and services. Any such corporate affiliate may use your information only according to the terms of this privacy notice. If you are located in a jurisdiction where such sharing requires your permission, we will
                        only do so with your consent.
                    </p>

                    <b> SHARING WITH PARTNERS </b>
                    <p> When we share your personal information with certain third-party partners, including marketing and advertising partners, that information includes your name, email address and other information enabling partners to: Assist you in using
                        our Services, Contact you with offers, services or products that may be of interest to you, and Provide you with their products or services. Our partners may use cookies, web beacons, pixels, tags, scripts, device recognition and
                        similar technologies in order to provide you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based advertising <a href="https://optout.aboutads.info/" target="_blank">click here </a>                        , or if you are located in the European Union, <a href="http://youronlinechoices.eu/" target="_blank">click here </a> , <a href="https://youradchoices.com/appchoices" target="_blank">Click here </a> to opt out of interest-based
                        advertising on your mobile device(s). Please note you will continue to receive generic ads. We request you to read our Cookie Notice.
                    </p>

                    <p>
                        If you are in a jurisdiction where such sharing requires your permission, we will only do so with your consent. Please note that if you access our services through a tool that hides your location, such as through a virtual private network, you may not
                        receive our request for permission because we were not able to identify you as being located in a jurisdiction where your permission is required. Further, our partners are prohibited from using your contact information for any
                        purpose beyond those set forth above without your consent. Further information about certain partners we share personal information. List of partners with whom we share the relevant data will be published and updated from time
                        to time.
                    </p>

                    <p>
                        In the event we collect information from you in connection with an offer that is jointly presented by us and a partner, we will let you know who is collecting the information and whose privacy notice applies, as well as any options you may have regarding
                        use of your information.
                    </p>

                    <b> SHARING WITH THIRD PARTY SERVICE PROVIDERS AND VENDORS </b>
                    <p> Occasionally, we enter into contracts with carefully selected third parties to assist us in servicing you (for example, providing you with customer service, fraud detection and deterrence or access to advertising assets and providing
                        us with information technology and storage services) or to assist us in our own marketing and advertising activities (including providing us with analytic information and search engine optimization services). Additional information
                        about certain third-party service providers we share personal information with is available here. Our contracts with such third parties prohibit them from using any of your personal information for any purpose beyond the purpose
                        for which it was shared.
                    </p>

                    <p>
                        If you purchase a product or service from a third party through one of our brands, we will pass your personal information to such third party in order for them to fulfill your order.
                    </p>

                    <p>
                        We offer features that allow you to better target who you contact through our products and services. These features allow you to optimize your campaigns, segment your lists, and better customize your offerings to your customers. In order to do this, we
                        partner with third parties who can provide you with information about your contacts. If both you and your contacts are located outside of the European Union, this may include demographic information and geographic location. We
                        require that these third parties are contractually or legally permitted to share this information with you.
                    </p>

                    <p>
                        To provide you with these features, we may send third parties certain pseudonymized personal data about your contacts. These third parties are prohibited from using this personal data for any purpose beyond that for which it was shared. We also share
                        non-personal information with certain third parties, including the media, industry observers, marketing and advertising partners, vendors, customers, potential customers or partners. For example, we disclose mobile search trends,
                        email open rates by industry, campaign best practices or the number of users that have been exposed to, or clicked on, our websites or evaluated or purchased our products and services.
                    </p>

                    <p>
                        Our third-party service providers and vendors may use cookies, web beacons, pixels, tags, scripts and similar technologies in order to provide you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based
                        advertising <a href="https://optout.aboutads.info/" target="_blank">click here </a> , or if you are in the European Union, <a href="https://optout.aboutads.info/" target="_blank">click here </a> . <a href="https://youradchoices.com/appchoices"
                            target="_blank">Click here </a> to opt out of interest-based advertising on your mobile device(s). Please note you will continue to receive generic ads. Please read our Cokkie Policy to access our Cookie Notice.
                    </p>

                    <b> CORPORATE REORGANIZATIONS </b>
                    <p> If we are involved in a merger, acquisition, a sale of all or a substantial portion of our assets, or other similar sale transaction, your information will be transferred as part of that transaction. We will notify you by email and/or
                        a prominent notice on our website of any such transfer and any choices you may have regarding your information.
                    </p>

                    <b> AUTHORIZED USERS </b>
                    <p> All users authorized by you to have access to your account can view personal information stored in the account. A primary account holder can view personal information saved in subaccounts to which they have authorized access. We share
                        information about authorized users only for legitimate purposes consistent with this privacy notice, including servicing your account and marketing products and services to you.
                    </p>

                    <b> LEGAL PROCESS </b>
                    <p> If legally required to do so, or if we have a good faith belief that such disclosure is reasonably necessary, we may disclose your personal information to courts of law, public authorities (including to meet national security or law
                        enforcement requirements), and other relevant third parties, such as internet service providers, to conduct an investigation, respond to a third party or law enforcement subpoena or court order, to bring legal action, prevent harm
                        to others or pursue other relief when you or a third party are or may be: Violating our terms and conditions of use. Causing injury or other harm to, or otherwise violating the property or other legal rights, of us, other users,
                        or third parties; or Violating federal, state, local, or other applicable law. This disclosure can include transferring your information to the U.S. and other countries outside the European Economic Area. To the extent we are legally
                        permitted to do so, we will notify you in the event that we are required to provide your personal information to third parties in connection with a subpoena.
                    </p>

                    <b> SECURITY </b>
                    <p> The transmission of information via the internet, email, phone call or text message etc. are not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your information
                        transmitted through the Services or over email; any transmission is at your own risk. Once we have received your information, we will take appropriate technical and organizational measures to safeguard your personal information
                        against loss, theft and unauthorized use, access, or modification. We don’t store any financial card and/or bank account information. When we collect financial account information, such as credit card numbers, we protect its transmission
                        using encryption such as the Transport Layer Security (TLS) protocol.
                    </p>

                    <b>TRACKING TECHNOLOGIES AND ONLINE ADVERTISING</b>
                    <p> We use cookies, web beacons, pixels, tags, scripts and other similar technologies in the course of our business. Information about the technologies we use, why we use them (for example, in connection with online advertising), and how
                        you can control them can be found in our Cookie Notice.
                    </p>

                    <b> CHOICE/OPT-OUT OF MARKETING COMMUNICATIONS FROM US </b>
                    <b> EMAIL </b>
                    <p> You always have the opportunity to opt out of our marketing communications with you or change your preferences for all non-transactional email messages from us or by emailing us at <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>                        . Some communications from us are considered transactional or service communications (for example, important account notifications and billing or order information). You agree to receive these transactional and service communications
                        as a condition of the Services that we provide to you. You must cancel your accounts for all Services, if you do not wish to receive any transactional or service communications from us. To cancel your account, please send an email
                        to <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>. You may still receive marketing communications from us even after you cancel your account unless you also opt-out of our marketing
                        communications, as described above.
                    </p>

                    <b> PHONE </b>
                    <p> If you do not want to receive marketing calls by phone, you may contact customer support at the brand from which you purchased your Services and indicate your preference to us. Contact information for specific brands can be found on
                        the homepage of each brand. Note that we may use automated dialing technology to place marketing calls. In the event we do use such technology for marketing calls, we will do so with your consent as required to comply with applicable
                        law. You do not need to agree to receive automated marketing phone calls or texts from us in order to use the Services.
                    </p>

                    <b> COOKIES </b>
                    <p> For information about how to manage and opt out from cookies, please visit our Cookie Notice.
                    </p>

                    <b> INFORMATION FROM THIRD PARTIES </b>
                    <p> To manage the information we receive about you from a social networking site or other third party (if applicable), you will need to follow the instructions from that party for updating your information and changing your privacy settings,
                        where available. The information we collect is covered by this privacy notice and the information the third-party collects is subject to such third party’s privacy practices. Privacy choices you have made on any third-party site
                        will not apply to our use of the information we have collected directly through our Services.
                    </p>

                    <b> UNAUTHORIZED ACCOUNTS </b>
                    <p> If an account or profile was created without your knowledge or authorization, please contact customer support at the brand on which the account or profile is located to request removal of the account or profile.
                    </p>

                    <b> RETENTION OF PERSONAL INFORMATION </b>
                    <p> We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will retain your personal information if it’s needed to
                        serve you better. We will initiate your personal data once we receive your delete requests and/or as required by law or regulation to remove your personal information.
                    </p>

                    <b> LEGAL BASIS FOR PROCESSING YOUR INFORMATION </b>
                    <p> Depending on what information we collect from you and how we collect it, we rely on various grounds for processing your information under the GDPR, including the following: In order to administer our contractual relationship, including
                        setting up your requested Services, payments, renewals and processes. Because it is in our legitimate interest to run a successful and efficient business and provide you with the Services and other useful content. To fulfill any
                        legal obligations, we may have to collect this information from you; and/or Because you have provided your consent for us to do so.
                    </p>

                    <b> YOUR RIGHT TO CONTROL HOW YOUR PERSONAL INFORMATION IS USED </b>
                    <p> You have the right to make requests regarding your personal information. You can: Ask us to access, update, remove or correct personal information if it is inaccurate. Request that we transfer some or all of your personal information
                        to you or a third party (whenever it is technically feasible to do so); Make choices about receiving marketing communications. Withdraw your consent to process your personal information in circumstances where we are relying on
                        your consent as our basis for processing. To exercise any of your rights, please send an email to <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b> Users located in Brazil,
                        the European Union and California have additional rights. In addition to the rights above, Users located in Brazil, residents of the European Union and California have further rights which are detailed below. Please note that some
                        rights only apply in certain circumstances or to certain information and some exceptions may apply. We will require you to verify your identity before responding to any requests to exercise your rights.
                    </p>

                    <b> RESIDENTS OF THE EUROPEAN UNION OR UNITED KINGDOM </b>
                    <p> In addition to the rights outlined above, where the GDPR or UK Data Protection Act or related data protection laws apply, you may: Ask whether we process personal information about you, and if we do, to access data we hold about you
                        and certain information about how we use it and who we share it with. Request that we delete the personal information we hold about you in certain limited circumstances. Request that we stop processing the personal information
                        we hold about you. Object to our processing of data about you.
                    </p>

                    <b> RESIDENTS OF CALIFORNIA </b>
                    <p> In addition to the rights outlined above, where the California Consumer Privacy Act 2018 (“CCPA”) or related data protection laws apply, you may: Request to know the categories of personal information we collect about you, as well
                        as the sources from which the personal information is collected; the business or commercial purpose of our collection; the categories of third parties with whom we share your personal information; and the specific pieces of personal
                        information we hold about you. Request certain information regarding, and/or opt-out of, our disclosure of your personal information to third parties. Request that we delete the personal information we hold about you in certain
                        limited circumstances.
                    </p>

                    <b> USERS LOCATED IN BRAZIL </b>
                    <p> In addition to the rights outlined above, where the Lei Geral de Proteção de Dados (“LGPD”) applies, you may: Ask that we provide confirmation of the existence of the processing of your personal data. Access the personal data we hold
                        about you and certain information about how we use it and who we share it with including information about any public and private entities we have shared your personal data with; Request the deletion of personal information we
                        have collected from you, subject to certain exceptions. Ask us to anonymize, block, or delete unnecessary or excessive data or data that is not being processed in compliance with the LGPD; and Ask us to provide information about
                        the possibility of denying consent for the processing of your personal data and the consequences of such denial.
                    </p>

                    <b> THIRD PARTY LINKS AND INFORMATION COLLECTION AND USE BY OUR USERS </b>
                    <p> Some of our Services provide links to other websites. Because we do not control the information policies or practices of these third-party sites, you should review their privacy policies to learn about how they collect and use personal
                        information. You may also log in to some of our Services using sign-in services such as Facebook Connect or an Open ID provider. These services will authenticate your identity and provide you with the option to share certain personal
                        information with us, such as your name and email address to pre-populate our sign up form. For example, if you take advantage of certain of our social media features, such as the Facebook Like button, and widgets, such as the “Share
                        This” button or interactive mini-programs that run on our site, the applicable social networking sites may collect your IP address, which page you are visiting on our websites, and may set a cookie to enable it to function properly.
                    </p>

                    <b> CHILDREN </b>
                    <p> Our Services are not directed to persons under 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian of such a child and become aware that your child has provided personal information
                        to us, please contact us as described in this Privacy Notice and we will take reasonable steps immediately to remove any such information.
                    </p>

                    <b> INTERNATIONAL DATA TRANSFER </b>
                    <p> For us to fulfill your request or provide the Services to you, your personal information will be transferred to, stored at, and processed in jurisdictions other than where you live, including in the United States. For instance, your
                        personal data may be processed by staff in India and other international locations, who work for us or for one of our suppliers. Laws in these countries may differ from the laws applicable to your country of residence. If you are
                        a European Economic Area (EEA) data subject and your personal information is shared with our affiliates, partners, or third-party service providers acting on our behalf outside of the EEA, it is done so pursuant to necessary means
                        to ensure an adequate level of protection. We will take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this notice.
                    </p>

                    <p>
                        For transfers of personal information within the StayHungry StayNatural LLP corporate family, such transfer will be under the Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses). The
                        Covered Entities are responsible for the processing of personal data they receive, under each Privacy Shield Framework, and subsequently transfer to a third party acting as an agent on its behalf. The Covered Entities comply with
                        the Privacy Shield Principles for all onward transfers of personal data from the EU and Switzerland, including the onward transfer liability provisions. In certain situations, we may be required to disclose personal data in response
                        to lawful requests by public authorities, including to meet national security or law enforcement requirements.
                    </p>

                    <p>
                        If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our escalations channel <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>
                    </p>

                    <p>
                        Under certain conditions, more fully described on the Privacy Shield website, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted. You can contact the Data Protection Officer through email <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>                        to obtain a copy of the data transfer agreement or more information regarding the relevant safeguard we put in place.
                    </p>

                    <b> COMPLAINTS </b>
                    <p> If you have any questions about this privacy notice or our data handling practices, or you wish to make a complaint, you may contact our Data Protection Officer at <b><a href="mailto: crm@evpitstop.in">crm@evpitstop.in</a></b>
                    </p>

                    <p>
                        or Sending us a postal mail
                    </p>

                    <p>
                        StayHungry StayNatural LLP,<br/> 715-A , 7th Floor, Suite #971,<br/> Spencer Plaza, No. 768 769,<br/> Anna Salai, Chennai 600002<br/>
                    </p>

                    <p>
                        We will address your request and make our best effort to solve all the issues and concerns that you may have.
                    </p>
                </div>
            </div>
        </div>
    </section>
</section>




