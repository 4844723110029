import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { CommonEnum } from '../enums/common.enum';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  constructor() { }

  showMessage(icon,title) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: icon,
      title: title
    })
  }
  confirmation(message,callback){
    Swal.fire({
      title: message,
      showCancelButton: true,
      confirmButtonText: CommonEnum.YES,
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed);
      }
    })
  }

}
