import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  private stepper: Stepper;
  redirectUrls = RedirectRoutes;
  postForm: FormGroup;
  submitted: boolean;
  currentStep: number;
  stepId: number;

  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private sweetAlert: SweetalertService,
    private commonService: CommonService,
    private readonly elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    const stepperEl = this.elementRef.nativeElement.querySelector('.bs-stepper');

    stepperEl.addEventListener('show.bs-stepper', event => {
      this.currentStep = event.detail.to;
    });

    this.stepper = new Stepper(stepperEl, {
      linear: false,
      animation: true
    });
    this.stepId = 1;
    this.createForm();

  }
  createForm() {
    this.postForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.maxLength(50)]],
      shortName: [''],
      knownAs: [''],
      neighbours: [''],
      locate: ['',Validators.required],
      status: [''],
      default: [''],
      default2: ['']
    });
    this.submitted = false;
  }
  next(id) {
    // this.stepper.next();
    this.stepper.to(id);
    this.stepId = id;
  }
  onSubmit() {
    return false;
  }
}
