<div class="d-flex">
    <aside class="bd-aside sticky-xl-top text-muted align-self-start px-0">
        <app-sidenav></app-sidenav>
    </aside>
    <div class="bd-cheatsheet container-fluid bg-body">
        <section id="content">
            <router-outlet></router-outlet>
        </section>
    </div>

</div>
