<div class="d-flex">
    <aside class="bd-aside sticky-xl-top text-muted align-self-start px-0">
        <app-sidenav></app-sidenav>
    </aside>
    <div class="bd-cheatsheet container-fluid bg-body">
        <section id="content" class="d-flex justify-content-center mt-5 align-items-center flex-column">
            <img src="../../assets/img/page-not-found.svg" alt="">
            <span class="theme-text">
                Sorry!
            </span>
            <span class="d-block">
                Page Not Found
            </span>
        </section>
    </div>

</div>
