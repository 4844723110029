<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">
                    Update {{ redirectUrls.ADMIN_PROFILE_LABEL }} Info
                </h2>
            </div>
            <!-- <div class="col-sm-6 top-right-content text-right d-flex align-items-center justify-content-end">
                <div class="search-box">
                    <input type="text" class="form-control" placeholder="Search here" />
                </div>
                <button class="btn btn-sm btn-add">
                    <i class="fa fa-bell"></i><sup>*</sup>
                </button>
                <button class="btn btn-sm btn-add"><i class="fa fa-cog"></i></button>
                <button class="btn btn-sm btn-add p-0" [routerLink]="'/admin/' + redirectUrls.ADMIN_PROFILE">
                    <img [src]="
              currentUser?.pictures
                ? currentUser?.pictures
                : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            " alt="" />
                </button>
            </div> -->
        </div>
    </div>
</div>
<div class="container-fluid">
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="row row-m">
            <div class="col-md-3 align-items-stretch mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-center">
                            <div class="profile-image-tag">
                                <input type="file" class="form-control cursor-point" (change)="profileImageUpdated($event)">
                                <img class="card-img-top" [src]="
                      currentUser?.pictures && !isImageUploaded
                        ? currentUser?.pictures
                        : profileImage
                    " />
                            </div>
                            <!-- <i class="fa fa-pen"  aria-hidden="true"></i> -->
                        </div>
                        <h4 class="card-title">
                            {{ currentUser?.firstName + currentUser?.lastName }}
                        </h4>
                        <p class="card-text card-view-text">
                            {{ currentUser?.email }}
                        </p>
                        <!-- <p class="card-text card-view-text p-1">
                                      <img src="https://cdn.dribbble.com/users/310943/screenshots/2792692/empty-state-illustrations.gif" alt="" style="width: 79px;height: 52px;">
                                      {{viewItem?.postalAddress?.fullAddress}}</p> -->
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="name" type="text" formControlName="firstName" class="form-control" placeholder=" ">
                            <label for="name">Name<sup>*</sup></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="mobile" type="tel" formControlName="mobileNumber" class="form-control" placeholder=" ">
                            <label for="mobile">Mobile number<sup>*</sup></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="email" type="email" formControlName="email" class="form-control" placeholder=" ">
                            <label for="email">Email<sup>*</sup></label>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <input id="city" type="text" class="form-control" placeholder=" ">
                            <label for="city">City<sup>*</sup></label>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                        <div class="form-group">
                            <input id="" type="text" class="form-control" placeholder=" ">
                            <label for=""></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="" type="text" class="form-control" placeholder=" ">
                            <label for=""></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="" type="text" class="form-control" placeholder=" ">
                            <label for=""></label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input id="" type="text" class="form-control" placeholder=" ">
                            <label for=""></label>
                        </div>
                    </div> -->
                    <div class="col-md-12 action-buttons">
                        <button class="btn btn-danger mr-4" type="button">Cancel</button>
                        <button class="btn btn-success theme-bg" type="submit">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>