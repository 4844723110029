import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LOGIN } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
import { EqualValidator } from '../core/validators/equal.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  postForm: FormGroup;
  redirectUrls = RedirectRoutes;
  resetFormShow: boolean;
  showPassword: boolean;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private sweetAlert: SweetalertService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.createForm();

  }
  createForm() {
    this.postForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: this.fb.group({
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/(?=.*\d+s?)(?=.*[a-z]+s?)(?=.*[A-Z]+s?)(?=.*[\W]+s?).{8,}/),
          ],
        ],
        confirmPassword: [
          "",
          [Validators.required, EqualValidator("newPassword")],
        ],
      }),
    });
  }
  get i() {
    return this.postForm.controls.password as FormGroup;
  }
  onSubmit() {
    let url = LOGIN.forgotPassword;
    this.submitted = true;
    if (this.postForm.controls.email.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      this.postForm.value,
      (result, statusFlag) => {
        console.log("res forgot", result);
        this.submitted = false;
        if (statusFlag) {
          if (result) {
            this.resetFormShow = true;
          }
        }
        // this.spinner.hide();
      }
    );
  }
  showPasswordHandler() {
    this.showPassword = !this.showPassword;
  }
  onResetSubmit() {
    let url = LOGIN.resetPassword;
    this.submitted = true;

    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    let obj ={
      email: this.postForm.value.email,
      password: this.i.value.newPassword
    }
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      obj,
      (result, statusFlag) => {
        console.log("res reset", result);
        this.submitted = false;
        if (statusFlag) {
            this.router.navigate([``]);
        }
        // this.spinner.hide();
      }
    );
  }
}
