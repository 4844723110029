import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VERSION_URL } from 'src/app/core/configurations/url';
import { AlertInfo } from 'src/app/core/enums/alert-info';
import { CommonEnum } from 'src/app/core/enums/common.enum';
import { FormButtons } from 'src/app/core/enums/form-buttons.enum';
import { HttpMethod } from 'src/app/core/enums/http-handler.enum';
import { RedirectRoutes } from 'src/app/core/enums/route.enum';
import { AppService } from 'src/app/core/services/app.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SweetalertService } from 'src/app/core/services/sweetalert.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  id: any;
  tHeaders: any = [
    {
      header: 'Version'
    },
    {
      header: 'Version Description'
    },
    {
      header: 'Status'
    },
    {
      header: 'Action'
    },
  ];
  btnDisabled = 0;

  noDataFound: boolean;
  VersionList: any[] = [];
  formShow: boolean;
  submitted: boolean;
  postForm: FormGroup;
  buttonName: any;
  commonEnum = CommonEnum;
  processing: boolean;
  buttonType = FormButtons;
  currentUser: any;
  url: string | ArrayBuffer;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private sweetAlert: SweetalertService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.loadData();
  }

  // Get Version Data
  loadData(): void {
    let url = VERSION_URL.getAllversion;
    this.commonService.commonApiCall(url, HttpMethod.POST, null, (res) => {
      this.VersionList = res.responseData;
    });
  }

  createForm() {
    this.postForm = this.fb.group({
      version: ['', [Validators.required, Validators.maxLength(5)]],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      status: [false],
    });
    this.buttonName = this.commonEnum.SAVE;
    this.submitted = false;
  }

  get formControls() {
    return this.postForm.controls;
  }

  statusChange(value) {
    this.formControls.status.patchValue(value);
  }

  cancel() {
    this.addTask();
    this.createForm();
    this.btnDisabled = 0;
  }

  addTask() {
    this.formShow = !this.formShow;
    this.btnDisabled = 0;
  }

  update(item) {
    this.btnDisabled = 1;
    this.formShow = !this.formShow;
    this.buttonName = this.commonEnum.UPDATE;
    if (item !== undefined) {
      this.postForm.patchValue(item);
      this.postForm.setControl('versionId', new FormControl(item._id));
    }
  }

  save() {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    let url = VERSION_URL.createupdateversion;
    this.processing = true;
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      this.postForm.value,
      (res, statusFlag) => {
        if (statusFlag) {
          this.submitted = false;
          this.btnDisabled = 0;
          this.cancel();
          this.loadData();
        }
        this.processing = false;
      }
    );
  }

  deleteVersion(row: any) {
    let message = row.status ? CommonEnum.DELETE : CommonEnum.DELETE;
    this.sweetAlert.confirmation(message, (result) => {
      if (result) {
        let url = VERSION_URL.deletever;
        let obj = { 'versionId': row }
        this.commonService.commonApiCall(
          url,
          HttpMethod.POST,
          obj,
          (res, statusFlag) => {
            if (statusFlag) {
              this.loadData();
            }
          }
        );
      }
    });
  }
}
