<nav class="small" id="toc">
    <div class="d-flex flex-column flex-shrink-0 p-2 text-white bg-hex min-vh-100 sidebar" [ngClass]="{'min-sidebar': !isMaxSidebar, 'max-sidebar': isMaxSidebar}">
        <img src="../assets/img/1_logo.jpg" class="img-fluid" />
        <span class="theme-bg" (click)="isMaxSidebar = !isMaxSidebar"><i class="fa" [ngClass]="!isMaxSidebar ? 'fa-chevron-right' : 'fa-chevron-left'"></i></span>
        <!-- <h2 class="theme-text text-center">EV</h2> -->
        <hr>
        <perfect-scrollbar>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_DASHBOARD" routerLinkActive="active" class="nav-link" aria-current="page">
                        <i class="fa fa-window-maximize" aria-hidden="true"></i>
                        <!-- <img src="\src\assets\img\dashboard.svg" alt=""> -->
                        <span> {{redirectUrls.ADMIN_DASHBOARD_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_USERS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-user" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_USERS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_REQUESTS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_REQUESTS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VEHICLES" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-bus" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_VEHICLES_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATIONS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATIONS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_LANGUAGES" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-cog" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_SETTINGS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_TRANSACTIONS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-credit-card" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_TRANSACTIONS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_NOTIFICATIONS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_NOTIFICATIONS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_REVIEW_MANAGEMENT" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_REVIEW_MANAGEMENT_LABEL}}</span>
                    </a>
                </li>

                 <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VERSION_MANAGEMENT" routerLinkActive="active" class="nav-link" aria-current="page">
                        <i class="fa fa-plug" aria-hidden="true"></i>
                       <span> {{redirectUrls.ADMIN_VERSION_MANAGEMENT_LABEL}}</span>
                    </a>
                </li>


               <!-- <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATIONS_CATEGORY" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATIONS_CATEGORY_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATIONS_CHARGECOST" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATIONS_CHARGECOST_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATIONS_PORT" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATIONS_PORT_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATIONS_PROVIDER" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATIONS_PROVIDER_LABEL}}</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_COUNTRY" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_COUNTRY_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_STATE" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_STATE_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_CITY_CATEGORY" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_CITY_CATEGORY_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_CITY" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_CITY_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_DISTRICT" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_DISTRICT_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_POSTAL_ADDRESS" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-plug" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_POSTAL_ADDRESS_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VEHICLE_CHARGE_CAPACITY" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-credit-card" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_VEHICLE_CHARGE_CAPACITY_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VEHICLE_MANUFACTURE" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_VEHICLE_MANUFACTURE_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VEHICLE_MODEL" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_VEHICLE_MODEL_LABEL}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [routerLink]="'/'+ redirectUrls.ADMIN + redirectUrls.ADMIN_VEHICLE_PORT" routerLinkActive="active" class="nav-link" aria-current="page">

                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_VEHICLE_PORT_LABEL}}</span>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a href="javascript:void(0)" (click)="logout()" class="nav-link" aria-current="page">

                        <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
                        <span> {{redirectUrls.ADMIN_LOGOUT_LABEL}}</span>
                    </a>
                </li>
            </ul>
        </perfect-scrollbar>
    </div>
</nav>
