import { Component, OnInit } from '@angular/core';
import { AppService } from './core/services/app.service';
import firebase from 'firebase/app';
// import { getMessaging, getToken } from "firebase/messaging";;
import '@firebase/messaging';
import '@firebase/database';
import { environment } from 'src/environments/environment';
import { SwPush } from '@angular/service-worker';
import { CommonService } from './core/services/common.service';
import { LOGIN } from './core/configurations/url';
import { HttpMethod } from './core/enums/http-handler.enum';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'evAdmin';
  userDetails: any;
  constructor(private appService: AppService, private swPush: SwPush,private commonService: CommonService) {}

  ngOnInit(): void {
    this.appService.loggedInStatus = this.appService.getUserDetails()
      ? true
      : false;
    firebase.initializeApp(environment.firebaseConfig);
    this.appService.notifyObservable$.subscribe((res) => {
      if (res.option === 'logout') {
        this.storeTokenInDB('');
        this.userDetails = null;
      }
    });
    this.initializeFirebase();
    if (this.appService.loggedInStatus) {
      this.userDetails = this.appService.getUserDetails();
    }
  }

  initializeFirebase(): void {
    if (firebase.messaging.isSupported()) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker
          .register('../firebase-messaging-sw.js')
          .then((registration) => {
            this.generateFirebaseToken(registration);
          });
      }
    }
  }

  storeTokenInDB(token: string): void {
    this.commonService.commonApiCall(
      LOGIN.refreshToken,
      HttpMethod.POST,
      {
        userId: this.userDetails._id,
        deviceId: token,
      }, (res) => {
        console.log(res);
        
      }
    )
  }
  generateFirebaseToken(registration?: ServiceWorkerRegistration): void {
    const messaging = firebase.messaging();
    messaging
      .getToken({
        vapidKey: environment.firebasePublicKey,
        serviceWorkerRegistration: registration,
      })
      .then((key) => {
        console.log(key);
        this.appService.firebaseToken = key;
        if (this.appService.loggedInStatus) {
          this.storeTokenInDB(key);
        }
        // need to store firebase key in DB.
        // this.handlePushNotification(
        //   {
        //     payload: {
        //       data: {
        //         title: 'login',
        //         body: '{"successfully login into EvPITSTOP"}',
        //       },
        //       from: '570878735957',
        //       priority: 'normal',
        //       notification: {
        //         title: 'EvPITSTOP',
        //         body: '{"Message from EvPITSTOP"}',
        //       },
        //       fcmMessageId: '1fd3fbb1-227c-4f88-a05e-3a871a57261e',
        //     },
        //   },
        //   registration
        // );
        messaging.onMessage((payload) => {
          this.handlePushNotification(payload, registration);
        });
        messaging.onBackgroundMessage((res) => {
          this.handlePushNotification(res, registration);
        });
      })
      .catch((err) => {
        console.log({ err });
        // this.
      });
    this.swPush.messages.subscribe((payload) => {
      this.handlePushNotification(payload, registration);
    });
    // this.swPush.notificationClicks.subscribe((payload) => {
    //   window.focus();
    //   window.open(`http://localhost:4200/notifications`);

    // })
  }
  handlePushNotification(
    payload: any,
    registration: ServiceWorkerRegistration
  ): void {
    console.log({ payload });
    registration.showNotification(payload?.data?.title, {
      body: payload?.data.body,
      badge: '../assets/img/36_36.png',
      icon: '../assets/img/36_36.png',
      data: {
        onActionClick: {
          default: {
            operation: 'openWindow',
            url: 'http://localhost:4200/notifications',
          },
        },
      },
    });
  }
}
