<section>
    <form action="" autocomplete="off" [formGroup]="postForm">
        <div class="left-content mr-3">
            <img class="fill" src="assets/img/Fill.png" alt="">
            <img class="d-block groups-img" src="assets/img/group.png" alt="">
            <img class="d-block down-fill" src="assets/img/Fill.png" alt="">
        </div>
        <div class="right-content flex-fill">
            <div class="content">
                <div class="login-details">
                    <h4>Reset Password</h4>
                    <span class="lorem">Please enter your existing email</span>
                    <div class="form-controls">
                        <div class="form-group mt-3">
                            <input class="form-control" formControlName="email" placeholder=" " id="email" type="email" name="email" id="" pattern="" />
                            <label for="email">Email address</label>
                        </div>
                        <div class="form-group mt-3">
                            <input class="form-control" type="password" formControlName="password" id="password" name="password" placeholder=" " />
                            <label for="password">Password</label>
                        </div>
                        <div class="actions ">
                            <button type="submit" class="btn btn-primary" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </div>
                <p class="mt-5 text-center">Term and Condition <a href="javascript:void(0)" [routerLink]="'/'+ redirectUrls.ADMIN_TERMS">Click here</a></p>
            </div>
        </div>
    </form>
</section>