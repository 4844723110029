import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Appconfig } from '../enums/app-config.enum';
@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }
  commonLoginHandler(url: string, data: any): Observable<any> {
    return this.http.post(url, data);
  }
  commonPostHandler(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.requestHeaders);
  }
  commonPostFormDataHandler(url: string, data: any): Observable<any> {
    return this.http.post(url, data,this.requestFormHeaders);
  }
  commonGetHandler(url: string): Observable<any> {
    return this.http.get(url, this.requestHeaders);
  }

  commonPutHandler(url: string, data: any): Observable<any> {
    return this.http.put(url, data,this.requestHeaders);
  }
  commonPutFormDataHandler(url: string, data: any): Observable<any> {
    return this.http.put(url, data,this.requestFormHeaders);
  }

  commonDeleteHandler(url: string): Observable<any> {
    return this.http.delete(url, this.requestHeaders);
  }


  protected get requestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    const currentUser = JSON.parse(localStorage.getItem(Appconfig.CURRENTUSER));
    let token = 'Bearer ' + currentUser.token;
    const headers = new HttpHeaders({
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    });
    return { headers };
  }
  protected get requestFormHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    const currentUser = JSON.parse(localStorage.getItem(Appconfig.CURRENTUSER));
    let token = 'Bearer ' + currentUser.token;
    const headers = new HttpHeaders({
      Authorization: token
    });
    return { headers };
  }}
