import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LOGIN } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
declare var $: any;
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  postForm: FormGroup;
  currentUser: any;
  submitted: boolean;
  url: any;
  fileName1: any;
  genderList: any = ['Male', 'Female'];
  processing: boolean;
  formShow: boolean;
  array: any = [1, 2, 3, 4, 5, 6, 7, 8];
  usersList: any = [];
  noDataFound: boolean;
  adminType: any = null;
  stationUsersList: any = [];
  stationUsersListing: any = [];
  profileForm: FormGroup;
  // verifyForm: FormGroup;
  otpSubmitted: boolean;
  activeList: any = [
    {
      val: true,
      label: 'Active',
    },
    {
      val: false,
      label: 'InActive',
    },
  ];
  imgepath = environment.baseUrl + '/';
  editForm: boolean;
  params: HttpParams;
  pageIndex: any = 1;
  pageSize = 12;
  public maxSize: number = 5;
  totalRecords: any;
  search: any = '';
  search2: any = '';
  pageIndex2 = 1;
  totalItems: any;
  pageSize2 = 12;
  totalRecords2: any;
  noDataFound2: boolean;
  isAdmingDataLoaded = false;
  isStationDataLoaded = false;
  stationDataLoaded = false;
  skeletonArray = Array(8);
  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private sweetAlert: SweetalertService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.profileCreateForm();
    // this.verifyOtpCreateForm();
    this.loadData();
    // this.stationUsersData();
    this.allStationData();
  }
  createForm() {
    this.postForm = this.fb.group({
      // userId: [0],
      // fullName: [, [Validators.required, Validators.maxLength(50)]],
      email: [
        '',
        [Validators.required, Validators.email, , Validators.maxLength(100)],
      ], //
      // mobileNumber: [],
      countryCode: ['+91'],
      gender: [''],
      // dob: [],
      // addressLine: [],
      // password: [],
      // userId: [''],
      prefix: ['Mr'],
      fullName: ['', [Validators.required, Validators.maxLength(30)]],
      // middleName: ['', Validators.maxLength(30)],
      // lastName: ['', [Validators.required, Validators.maxLength(30)]],
      userName: ['', [Validators.required, Validators.maxLength(50)]], //max
      mobileNumber: ['', [Validators.required, Validators.maxLength(10)]],
      dateOfBirth: [''],
      // photo: [''],
      status: true,
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/),
        ],
      ], // min 8 special,numeric captial,
    });
    this.submitted = false;
    this.url = null;
    this.editForm = false;
  }

  profileCreateForm() {
    this.profileForm = this.fb.group({
      userId: this.currentUser._id,
      pictures: [''],
    });
  }

  // verifyOtpCreateForm(){
  //   this.verifyForm = this.fb.group({
  //     email: [''],
  //     emailVerificationCode: ['', Validators.required]
  // })
  // }

  pageChanged(event: PageChangedEvent): void {
    const startItem = event.page * event.itemsPerPage;
    this.pageIndex = event.page;
    this.loadData();
  }

  loadData() {
    this.isAdmingDataLoaded = false;
    // this.params = new HttpParams()
    //   .set('name', this.search.toString())
    //   .set('page', this.pageIndex.toString())
    //   .set('size', this.pageSize.toString());
    const url = LOGIN.getAllSuperAdmins;
    this.noDataFound = false;
    let obj = { roleId: '61b2feeb43e21e0304a707ae' };
    this.commonService.commonApiCall(
      url + `?page=${this.pageIndex}`,
      HttpMethod.POST,
      obj,
      (res, statusFlag) => {
        this.isAdmingDataLoaded = true;
        if (res.responseData) {
          this.usersList = res.responseData;
          console.log(this.usersList);
          this.totalRecords = res.count;
          if (!res.responseData.length) {
            this.noDataFound = true;
          }
        }
      }
    );
  }

  pageChanged2(event: PageChangedEvent): void {
    const startItem = event.page * event.itemsPerPage;
    this.pageIndex2 = event.page;
    this.allStationData();
  }

  allStationData() {
    this.stationDataLoaded = false;
    // this.params = new HttpParams()
    //   .set('name', this.search2.toString())
    //   .set('page', this.pageIndex2.toString())
    //   .set('size', this.pageSize2.toString());
    const url = LOGIN.getAllStationAdmins;
    this.noDataFound = false;
    let obj = { roleId: '61b2feeb43e21e0304a707ae' };
    this.commonService.commonApiCall(
      url + `?page=${this.pageIndex2}`,
      HttpMethod.POST,
      obj,
      (res, statusFlag) => {
        if (res.responseData) {
          this.stationUsersListing = res.responseData;
          console.log(this.stationUsersListing);
          this.totalRecords2 = res.count;
          if (!res.responseData.length) {
            this.noDataFound2 = true;
          }
          this.stationDataLoaded = true;
        }
      }
    );
  }

  stationUsersData(searchText) {
    this.isStationDataLoaded = false;
    // this.params = new HttpParams()
    //   .set('name', this.search2.toString())
    //   .set('page', this.pageIndex2.toString())
    //   .set('size', this.pageSize2.toString());
    const url = LOGIN.searchNewEvStations;
    this.noDataFound = false;
    let obj;
    this.commonService.commonApiCall(
      // url + '?' + this.params,
      // url + `?page=${this.pageIndex}`,
      url + `?name=${this.search2}`,
      HttpMethod.POST,obj,(res, statusFlag) => {
        if (res.responseData) {
          this.stationUsersListing = res.responseData;
          console.log(this.stationUsersListing);
          this.totalRecords2 = res.count;
          if (!res.responseData.length) {
            this.noDataFound2 = true;
          }
          this.isStationDataLoaded = true;
        }
      }
    );
  }

  superAdmin(searchText) {
    this.isStationDataLoaded = false;
    // this.params = new HttpParams()
    //   .set('name', this.search2.toString())
    //   .set('page', this.pageIndex2.toString())
    //   .set('size', this.pageSize2.toString());
    const url = LOGIN.getSearchSuperAdmins;
    this.noDataFound = false;
    let obj = { roleId: '61b2feeb43e21e0304a707ae' };
    this.commonService.commonApiCall(
      // url + '?' + this.params,
      // url + `?page=${this.pageIndex}`,
      url + `?name=${this.search}`,
      HttpMethod.POST,obj,(res, statusFlag) => {
        if (res.responseData) {
          this.usersList = res.responseData;
          console.log(this.usersList);
          this.totalRecords2 = res.count;
          if (!res.responseData.length) {
            this.noDataFound2 = true;
          }
          this.isStationDataLoaded = true;
        }
      }
    );
  }

  searchText: any;


  searchData(event: any) {
    console.log(event);
    this.searchText = event.target.value;
    this.stationUsersData(this.searchText);
  }

  superAdminData(event: any) {
    console.log(event);
    this.searchText = event.target.value;
    this.superAdmin(this.searchText);
  }

  onImage1Selected(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      const files = Array.from(event.target.files);
      this.profileForm.get('pictures').patchValue(files[0]);
      this.fileName1 = file.name;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.url = reader.result; // This is valid
      };
      this.profilePhotoSubmit();
    }
  }
  profilePhotoSubmit() {
    let url =
      LOGIN.uploadSuperAdminPictures + '/' + this.profileForm.value.userId;
    this.commonService.commonApiCall(
      url,
      HttpMethod.FORMDATA_POST,
      this.reUsableCode(),
      (res, statusFlag) => {
        if (statusFlag) {
          this.addForm(null);
          this.loadData();
          this.stationUsersData(this.searchText);
        }
      }
    );
  }
  reUsableCode() {
    const formData = new FormData();
    // let data = this.profileForm.getRawValue();
    formData.append('pictures', this.profileForm.get('pictures').value);
    // Object.keys(data.pictures).forEach((res) => {
    //   formData.append('pictures', this.profileForm.get(res).value);
    // });
    return formData;
  }
  addForm(admin) {
    this.formShow = !this.formShow;
    this.adminType = admin;
    if (admin == null) {
      this.createForm();
      this.profileCreateForm();
    }
  }
  edit(admin, type) {
    this.editForm = true;
    this.addForm(type);
    this.postForm.patchValue(admin);
    this.postForm.setControl('userId', new FormControl(admin._id));
    this.profileForm.get('userId').patchValue(admin._id);
    if (admin.pictures !== '') {
      this.url = admin.pictures;
    }

    this.postForm.controls.password.clearValidators();
    this.postForm.controls.password.updateValueAndValidity();
  }
  save(type) {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    let url;
    if (type == 'super') {
      if (this.editForm) {
        url = LOGIN.updateSuperAdminById;
      } else {
        url = LOGIN.superAdminSignup;
      }
    } else {
      if (this.editForm) {
        url = LOGIN.updateStationAdminById;
      } else {
        url = LOGIN.stationAdminSignup;
      }
    }

    this.processing = true;
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      this.postForm.value,
      (res, statusFlag) => {
        if (statusFlag) {
          this.submitted = false;
          // this.addForm(null);
          // if(!res.responseData.isEmailVerified){
          //   this.verifyOtpModel();
          //   this.verifyForm.get('email').patchValue(res.email);
          // }else{
          this.addForm(null);
          // }
          this.loadData();
          // this.stationUsersData();
        }
        this.processing = false;
      }
    );
  }

  // verifyOtpModel(){
  //   $('#exampleModalCenter').modal('show');
  // //   $('#exampleModalCenter').appendTo("body").modal({
  // //     backdrop: 'static',
  // //     show: true,
  // //     keyboard: false
  // // });
  // // $('#exampleModalCenter').modal({backdrop:'static', keyboard:false});

  // //   $('#exampleModalCenter').modal({
  // //     backdrop: 'static',
  // //     keyboard: false
  // // })
  //   // $('#exampleModalCenter').modal({
  //   //   backdrop: 'static',
  //   //   keyboard: false,
  //   //   show: true,
  //   // });
  // }
  // closeModal(){
  //   $('#exampleModalCenter').modal('hide');
  // }
  // onSubmitCode(){
  //   let url = LOGIN.resendOTP;
  //   this.otpSubmitted = true;
  //   if(this.verifyForm.invalid){
  //     this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
  //     return;
  //   }
  //   this.processing = true;
  //   this.commonService.commonApiCall(
  //     url,
  //     HttpMethod.POST,
  //     this.verifyForm.value,
  //     (res, statusFlag) => {
  //       if (statusFlag) {
  //         this.otpSubmitted = false;
  //         this.closeModal();
  //         this.addForm(null);
  //         this.loadData();
  //         this.stationUsersData();
  //       }
  //       this.processing = false;
  //     }
  //   );
  // }
}
