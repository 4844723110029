<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_LOCATIONS_LABEL}}</h2>
            </div>
            <!-- <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" ><span>Save</span></button>
            </div> -->
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
            <div id="stepper1" class="bs-stepper">
                <div class="bs-stepper-header">
                  <div class="step" data-target="#test-l-1">
                    <button class="step-trigger" (click)="next(1)">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 1}">1</span>
                      <!-- <span class="bs-stepper-label">Email</span> -->
                    </button>
                  </div>
                  <div class="line" [ngClass]="{'line-active': stepId > 1}"></div>
                  <div class="step" data-target="#test-l-2">
                    <button class="step-trigger" (click)="next(2)">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 2}">2</span>
                      <!-- <span class="bs-stepper-label">Password</span> -->
                    </button>
                  </div>
                  <div class="line" [ngClass]="{'line-active': stepId > 2}"></div>
                  <div class="step" data-target="#test-l-3" (click)="next(3)">
                    <button class="step-trigger">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 3}">3</span>
                      <!-- <span class="bs-stepper-label">Validate</span> -->
                    </button>
                  </div>
                </div>
                <div class="bs-stepper-content">
                    <div id="test-l-1" class="content">
                        <form (ngSubmit)="onSubmit()" [formGroup]="postForm">
                        <div class="row col-8">
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="fullName" name="fullName" id="fullName"
                                    placeholder="Name" aria-label="fullName" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('fullName').errors }">
                                <div *ngIf="submitted && postForm.get('fullName').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('fullName').errors.required">Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="shortName" name="shortName" id="shortName"
                                    placeholder="Short Name" aria-label="shortName" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('shortName').errors }">
                                <div *ngIf="submitted && postForm.get('shortName').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('shortName').errors.required">Short Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="knownAs" name="knownAs" id="knownAs"
                                    placeholder="KnownAs" aria-label="knownAs" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('knownAs').errors }">
                                <div *ngIf="submitted && postForm.get('knownAs').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('knownAs').errors.required">KnownAs is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="neighbours" name="neighbours" id="neighbours"
                                    placeholder="Neighbours" aria-label="neighbours" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('neighbours').errors }">
                                <div *ngIf="submitted && postForm.get('neighbours').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('neighbours').errors.required">Neighbours is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="locate" name="locate" id="locate"
                                    placeholder="Locate" aria-label="locate" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('locate').errors }">
                                <div *ngIf="submitted && postForm.get('locate').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('locate').errors.required">Locate is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="status" name="status" id="status"
                                    placeholder="Status" aria-label="status" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('status').errors }">
                                <div *ngIf="submitted && postForm.get('status').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('status').errors.required">Status is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="default" name="default" id="default"
                                    placeholder="Default" aria-label="default" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('default').errors }">
                                <div *ngIf="submitted && postForm.get('default').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('default').errors.required">Default is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-6 col-p">
                                <input type="text" class="form-control" formControlName="default2" name="default2" id="default2"
                                    placeholder="Default" aria-label="default2" 
                                    [ngClass]="{ 'is-invalid': submitted && postForm.get('default2').errors }">
                                <div *ngIf="submitted && postForm.get('default2').errors" class="invalid-feedback">
                                    <div *ngIf="postForm.get('default2').errors.required">Default is required.
                                    </div>
                                </div>
                            </div>
                            </div>
                        </form>

                      <button (click)="next(2)" class="btn btn-primary">Next</button>
                    </div>
                    <div id="test-l-2" class="content">
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
                      </div>
                      <button (click)="next(3)" class="btn btn-primary">Next</button>
                    </div>
                    <div id="test-l-3" class="content text-center">
                      <button type="submit" class="btn btn-primary mt-5">Submit</button>
                    </div>
                </div>
              </div>

        
        </div>
    </div>