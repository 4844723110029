import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LANGUAGE_URLS } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { FormButtons } from '../core/enums/form-buttons.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  tHeaders: any = [
    {
      header: "Name",
      key: "name"
    },
    {
      header: "Short Name",
      key: "shortName"
    },
    {
      header: "Code",
      key: "code"
    },
    {
      header: "Description",
      key: "description"
    },
    {
      header: "Status",
      key: "status"
    }];
  noDataFound: boolean;
  languageList: any[] = [];
  formShow: boolean;
  submitted: boolean;
  postForm: FormGroup;
  buttonName: any;
  commonEnum = CommonEnum;
  statusList: any = [
    {
      val: true,
      label: 'Yes'
    },
    {
      val: false,
      label: 'No'
    }
  ];
  fileName1: any;
  processing: boolean;
  buttonType = FormButtons;
  currentUser: any;
  url: string | ArrayBuffer;
  params: HttpParams;
  pageIndex: any = 1;
  pageSize: any = 10;
  public maxSize: number = 5;
  totalRecords: any;
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private sweetAlert: SweetalertService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.loadData();
  }

  //key val return
  getColValue(colValue) {
    if (Array.isArray(colValue)) {
      const arrayElements = [];
      colValue.forEach((ele) => {
        if (typeof ele !== 'object') {
          arrayElements.push(ele);
        }
      });
      return arrayElements.toString();
    }
    return colValue;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page) * event.itemsPerPage;
    this.pageIndex = event.page,
      this.loadData();
  }
  loadData() {
    this.params = new HttpParams()
  .set('page', this.pageIndex.toString())
  .set('size', this.pageSize.toString());
    const url = LANGUAGE_URLS.getAll;
    this.noDataFound = false;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      url + '?' + this.params,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.languageList = res.responseData;
          this.totalRecords = res.count;
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
  createForm() {
    this.postForm = this.fb.group({
      // languageId: [0],
        name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('[-_a-zA-Z]*')]],
        userId: [this.currentUser._id],
        shortName: [''],
        code:[''],
        status: true,
        description: [''],
      });
    this.buttonName = this.commonEnum.SAVE;
    this.submitted = false;
    this.url = null;
  }
  get formControls() { return this.postForm.controls; }
  statusChange(value){
    this.formControls.status.patchValue(value);
  }
  cancel() {
    this.addTask();
    this.createForm();
  }
  addTask() {
    this.formShow = !this.formShow;
  }
  update(item) {
    this.addTask();
    this.buttonName = this.commonEnum.UPDATE;
    if (item !== undefined) {
      this.postForm.patchValue(item);
      this.postForm.setControl('languageId', new FormControl(item.languageId));
    }
  }
  statusUpdate(event,item){
    console.log(event);
    console.log(item);
    this.postForm.patchValue(item);
    this.postForm.setControl('languageId', new FormControl(item.languageId));
    this.postForm.get('status').patchValue(event);
    let url = LANGUAGE_URLS.createOrUpdate;
      this.commonService.commonApiCall(
        url,
        HttpMethod.POST,
        this.postForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.createForm();
            this.loadData();
          }
        }
      );
  }
  save() {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = LANGUAGE_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.postForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.cancel();
            this.loadData();
          }
          this.processing = false;
        }
      );
  }
  deleteConfirm(row) {
    this.sweetAlert.confirmation(CommonEnum.DELETE, (result) => {
      if (result) {
        let url = LANGUAGE_URLS.deleteById;
        let obj ={ 'userId': this.currentUser._id,
        "languageId": row.languageId
         }
        this.commonService.commonApiCall(
          url,
          HttpMethod.POST,
          obj,
          (res, statusFlag) => {
            if (statusFlag) {
              this.loadData();
            }
          }
        );
      }
    });
  }

}
