import { environment } from "src/environments/environment";

let url = environment.baseUrl + '/api/v1/';
// let url = '/api/';
export const LOGIN = {
    TOKEN: url + 'superAdminLogin',
    profile: url + 'getProfile',
    superAdminSignup: url + 'superAdminSignup',
    stationAdminSignup: url + 'stationAdminSignup',
    updateSuperAdminById: url + 'updateSuperAdminById',
    updateStationAdminById: url + 'updateStationAdminById',
    uploadSuperAdminPictures: url + 'uploadSuperAdminPictures',
    getSuperAdminById: url + 'getSuperAdminById',
    deleteSuperAdminById: url + 'deleteSuperAdminById',
    getAllSuperAdmins: url + 'getAllSuperAdmins',
    getAllStationAdmins: url + 'getAllStationAdmins',
    getSearchSuperAdmins: url + 'searchSuperAdmins',
    searchEvStations: url + 'searchEvStations',
    searchNewEvStations: url + 'searchEvStationAdmins',
    forgotPassword: url + 'forgotPassword',
    resetPassword: url + 'resetPassword',
    resendOTP: url + 'resendOTP',
    verifyEmail: url + 'verifyEmail',
    dashboard: url + 'evStationCount',
    paymentHistory: url + 'transactions',
    profitableStations: url + 'transactionsOfEvStations',
    pushNotification: url + 'CreateorUpdateNotification',
    refreshToken: url + 'refreshToken',
    stationReport: url + 'ActiveStationCount'
}

export const STATION_URLS = {
  getAll: url + 'getAllEvStations', //POST
  createOrUpdate: url + 'createOrUpdateEvStation',
  uploadPictures: url + 'uploadStationPictures',
  getById: url + 'getEvStationById',
  getByuserId: url + 'getEvSTationByUserId',
  deleteById: url + 'deleteEvStationById',
  stationApproval: url + 'statusApproval',
}
export const STATION_CATEGORY_URLS = {
  getAll: url + 'getAllEvStationCategorys', //POST
  createOrUpdate: url + 'createOrUpdateEvStationCategory',
  getById: url + 'getEvStationCategoryById',
  deleteById: url + 'deleteEvStationCategoryById'
}
export const STATION_CHARGECOST_URLS = {
  getAll: url + 'getAllEvStationChargecosts', //POST
  createOrUpdate: url + 'createOrUpdateEvstationChargeCost',
  getById: url + 'getEvStationChargeCostById',
  deleteById: url + 'deleteEvStationchargeCostById'
}
export const STATION_PORT_URLS = {
  getAll: url + 'getAllEvStationPorts', //POST
  createOrUpdate: url + 'createOrUpdateEvStationPort',
  getById: url + 'getEvStationPortById',
  deleteById: url + 'deleteEvStationPortById'
}
export const STATION_PROVIDER_URLS = {
  getAll: url + 'getAllEvStationProviders', //POST
  createOrUpdate: url + 'createOrUpdateEvStationProvider',
  getById: url + 'getEvStationProviderById',
  deleteById: url + 'deleteEvStationProviderById',
  getAllEvStations: url + 'getAllEvStations'
}
export const LANGUAGE_URLS = {
  getAll: url + 'getAllLanguages', //POST
  createOrUpdate: url + 'createOrUpdateLanguage',
  getById: url + 'getLanguageById',
  deleteById: url + 'deleteLanguageById'
}
export const COUNTRY_URLS = {
  getAll: url + 'getAllCountries', //POST
  createOrUpdate: url + 'createOrUpdateCountry',
  getById: url + 'getCountryById',
  deleteById: url + 'deleteCountryById'
}
export const STATE_URLS = {
  getAll: url + 'getAllStates', //POST
  createOrUpdate: url + 'createOrUpdateState',
  getById: url + 'getStateById',
  deleteById: url + 'deleteStateById'
}
export const CITY_CATEGORY_URLS = {
  getAll: url + 'getAllCityCategories', //POST
  createOrUpdate: url + 'createOrUpdateCityCategory',
  getById: url + 'getCityCategoryById',
  deleteById: url + 'deleteCityCategoryById'
}
export const CITY_URLS = {
  getAll: url + 'getAllCities', //POST
  createOrUpdate: url + 'createOrUpdateCity',
  getById: url + 'getCityById',
  deleteById: url + 'deleteCityById'
}
export const DISTRICT_URLS = {
  getAll: url + 'getAllDistricts', //POST
  createOrUpdate: url + 'createOrUpdateDistrict',
  getById: url + 'getDistrictById',
  deleteById: url + 'deleteDistrictById'
}
export const POSTAL_ADDRESS_URLS = {
  getAll: url + 'getAllPostalAddress', //POST
  createOrUpdate: url + 'createOrUpdatePostalAddress',
  getById: url + 'getPostalAddressById',
  deleteById: url + 'deletePostalAddressById'
}
export const VEHICLE_CHARGE_CAPACITY_URLS = {
  getAll: url + 'getAllVehicleChargeCapacitys', //POST
  createOrUpdate: url + 'createOrUpdateVehicleChargeCapacity',
  getById: url + 'getVehicleChargeCapacityById',
  deleteById: url + 'deleteVehicleChargeCapacityById'
}
export const VEHICLE_MANUFACTURE_URLS = {
  getAll: url + 'getAllVehicleManufacturers', //POST
  createOrUpdate: url + 'createOrUpdateVehicleManufactur',
  getById: url + 'getVehicleManufacturById',
  deleteById: url + 'deleteVehicleManufacturById'
}
export const VEHICLE_MODEL_URLS = {
  getAll: url + 'getAllVehicleModels', //POST
  createOrUpdate: url + 'createOrUpdateVehicleModel',
  getById: url + 'getVehicleModelById',
  deleteById: url + 'deleteVehicleModelById'
}
export const STATION_MODEL_URLS = {
  createOrUpdate: url + 'updateStationPricingPlan',
}
export const VEHICLE_PORT_URLS = {
  getAll: url + 'getAllVehiclePorts', //POST
  createOrUpdate: url + 'createOrUpdateVehiclePort',
  getById: url + 'getVehiclePortById',
  deleteById: url + 'deleteVehiclePortById'
}

export const TRANSACTIONS_URL = {
  orderDetails: url + 'ordersDetails',
}
export const FEDDBACK_URL = {
  getAllFeedback: url + 'getAllFeedback',
  createOrUpdateFeedback: url + 'createOrUpdateFeedback'
}

export const VERSION_URL = {
  getAllversion: url + 'getAllVersions',
  deletever: url + 'deleteVersionsById',
  createupdateversion: url + 'createOrUpdateVersion'
}
