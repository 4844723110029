import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
import Stepper from 'bs-stepper';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { VEHICLE_CHARGE_CAPACITY_URLS, VEHICLE_MANUFACTURE_URLS, VEHICLE_MODEL_URLS, VEHICLE_PORT_URLS } from '../core/configurations/url';
import { HttpMethod } from '../core/enums/http-handler.enum';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {
  private stepper: Stepper;
  redirectUrls = RedirectRoutes;
  vehicleManfactureForm: FormGroup;
  submitted: boolean;
  currentStep: number;
  stepId: number = 1;
  currentUser: any;
  processing: boolean;
  vehicleCapacityForm: FormGroup;
  vehiclePortForm: FormGroup;
  vehicleModelForm: FormGroup;
  vehicleManufacturList: any = [];
  vehiclePortList: any = [];
  chargeCapacityList: any = [];
  formShow: boolean;
  noDataFound: boolean;
  vehicleList: any;
  @Output() cancel = new EventEmitter<string>();
  @Input() parentData = null;
  elecityList: any = ['Ac','Dc']
  constructor(
    private fb: FormBuilder,
    private appService: AppService,
    private sweetAlert: SweetalertService,
    private commonService: CommonService,
    private readonly elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    const stepperEl = this.elementRef.nativeElement.querySelector('.bs-stepper');

    stepperEl.addEventListener('show.bs-stepper', event => {
      this.currentStep = event.detail.to;
    });

    this.stepper = new Stepper(stepperEl, {
      linear: false,
      animation: true
    });
    this.vehicleManfactureCreateForm();
    this.vehicleCapacityCreateForm();
    this.vehiclePortCreateForm();
    this.vehicleModelCreateForm();
    if(this.parentData != null){
      console.log(this.parentData);
      this.vehicleManfactureForm.patchValue(this.parentData.vehicleManufactureData);
      this.vehicleManfactureForm.setControl('vehicleManufacturId', new FormControl(this.parentData.vehicleManufactureData.vehicleManufacturId));
      this.vehicleCapacityForm.patchValue(this.parentData.vehicleChargeCapacityData);
      this.vehicleCapacityForm.setControl('vehicleChargeCapacityId', new FormControl(this.parentData.vehicleChargeCapacityData.vehicleChargeCapacityId));
      this.vehiclePortForm.patchValue(this.parentData.vehiclePortData);
      this.vehiclePortForm.setControl('vehiclePortId', new FormControl(this.parentData.vehiclePortData.vehiclePortId));
      this.vehicleModelForm.patchValue(this.parentData);
      this.vehicleModelForm.setControl('vehicleModelId', new FormControl(this.parentData.vehicleModelId));

    }
  }
  // cancel() {
  //   // this.addTask();
  //   this.vehicleManfactureCreateForm();
  //   this.vehicleCapacityCreateForm();
  //   this.vehiclePortCreateForm();
  //   this.vehicleModelCreateForm();
  // }
  // addTask() {
  //   this.formShow = !this.formShow;
  // }
  // loadData() {
  //   const url = '';
  //   this.noDataFound = false;
  //   let obj ={ 'userId': this.currentUser._id}
  //   this.commonService.commonApiCall(
  //     url ,
  //     HttpMethod.POST,
  //     null,
  //     (res, statusFlag) => {
  //       if (statusFlag) {
  //         this.vehicleList = res.responseData;
  //         if(!res.responseData.length){
  //           this.noDataFound = true;
  //         }
  //       }
  //     }
  //   );
  // }
  vehicleManfactureCreateForm() {
    this.vehicleManfactureForm = this.fb.group({
      // vehicleManufacturId: [0],
      name: ['', [Validators.required, Validators.maxLength(60)]],
      userId: [this.currentUser._id],
      organizationName: ['',Validators.required],
      status: true,
      description: [''],
    });
    this.submitted = false;
  }
  vehicleCapacityCreateForm() {
    this.vehicleCapacityForm = this.fb.group({
      // vehicleChargeCapacityId: [0],
      _id: [this.currentUser._id],
        chargeCapacityInKWH: ['',Validators.required],
        status: true,
        description: [''],
        chargingType: ['',Validators.required]
      });
    this.submitted = false;
  }
  vehiclePortCreateForm() {
    this.vehiclePortForm = this.fb.group({
      // vehiclePortId: [0],
        name: ['', [Validators.required, Validators.maxLength(50)]],
        _id: [this.currentUser._id],
        maxPower: ['',Validators.required],
        maxVolts: ['',Validators.required],
        maxAmps: ['',Validators.required],
        level: ['',Validators.required],
        pins: ['',Validators.required],
        lockSupport: false,
        status: true,
        description: [''],
      });
    this.submitted = false;
  }
  vehicleModelCreateForm() {
    this.vehicleModelForm = this.fb.group({
      // vehicleModelId: [0],
        name: ['', [Validators.required, Validators.maxLength(50)]],
        modalNumber: ['',Validators.required],
        _id: [this.currentUser._id],
        batteryCapacityInKWH: ['',Validators.required],
        vehicleManufacturId: [''],
        vehiclePortId: [''],
        vehicleChargeCapacityId: [''],
        status: true,
        description: [''],
        modelYear: ['',Validators.required],
        variant: ['',Validators.required],
      });
    this.submitted = false;
  }
  next(id) {
    // this.stepper.next();
    switch(id){
      case 2:
        if (this.vehicleManfactureForm.invalid) {
          this.sweetAlert.showMessage(
            AlertInfo.ERROR,
            CommonEnum.MANDOTORY_FIELDS
          );
          return;
        }else{
          this.stepper.to(id);
          this.stepId = id;
        }

        break;
        case 3:
          if (this.vehicleCapacityForm.invalid) {
            this.sweetAlert.showMessage(
              AlertInfo.ERROR,
              CommonEnum.MANDOTORY_FIELDS
            );
            return;
          }else{
            this.stepper.to(id);
            this.stepId = id;
          }
          break;
          case 4:
            if (this.vehiclePortForm.invalid) {
              this.sweetAlert.showMessage(
                AlertInfo.ERROR,
                CommonEnum.MANDOTORY_FIELDS
              );
              return;
            }else{
              this.stepper.to(id);
              this.stepId = id;
            }
            break;
            // case 4:
            //   if (this.vehicleModelForm.invalid) {
            //     this.sweetAlert.showMessage(
            //       AlertInfo.ERROR,
            //       CommonEnum.MANDOTORY_FIELDS
            //     );
            //     return;
            //   }else{
            //     this.stepper.to(id);
            //     this.stepId = id;
            //   }
            //   break;
    }

  }
  onSubmit() {
    return false;
  }
  onSubmitManufacture() {
    this.submitted = true;
    if (this.vehicleManfactureForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = VEHICLE_MANUFACTURE_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.vehicleManfactureForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.next(2);
            this.vehicleModelForm.get('vehicleManufacturId').patchValue(res.responseData._id);
            console.log(res.responseData);
          }
          this.processing = false;
        }
      );
  }
  onSubmitCapacity() {
    this.submitted = true;
    if (this.vehicleCapacityForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = VEHICLE_CHARGE_CAPACITY_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.vehicleCapacityForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.next(3);
            this.vehicleModelForm.get('vehicleChargeCapacityId').patchValue(res.responseData._id);
          }
          this.processing = false;
        }
      );
  }
  onSubmitPort() {
    this.submitted = true;
    if (this.vehiclePortForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = VEHICLE_PORT_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.vehiclePortForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.next(4);
            this.vehicleModelForm.get('vehiclePortId').patchValue(res.responseData._id);
          }
          this.processing = false;
        }
      );
  }
  // onCancel(){
  //   this.cancel.emit();
  // }
  onSubmitModel() {
    this.submitted = true;
    if (this.vehicleModelForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = VEHICLE_MODEL_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.vehicleModelForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.cancel.emit();
          }
          this.processing = false;
        }
      );
  }
}
