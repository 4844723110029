<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_VEHICLE_PORT_LABEL}}</h2>
            </div>
            <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="addTask()"
                    *ngIf="!formShow"><span>Add</span></button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <div class="col-sm-12 col-p  main-content">
            <div class="card p-10 post-inner" *ngIf="!formShow">
                <div class="py-10 text-right">
                </div>
                <div class="post-body table-post">
                    <div class="table-responsive">
                        <table
                            class="table table-borderless table-striped table-thead-bordered table-nowrap table-align-middle">
                            <thead class="table-header">
                                <tr>
                                    <th *ngFor="let item of tHeaders;let i=index;">
                                        <div class="p-d-block p-jc-between p-ai-center">
                                            {{item?.header}}
                                        </div>
                                    </th>
                                    <th style="width: 5%;">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="noDataFound">
                                    <td class="post_details_inner text-center" colspan="100">
                                        <div class="post-inner">
                                            <div class="post-body no-data">
                                                <p class="mb-0 font-weight-bold">No Records Found</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of categoryList">
                                    <td *ngFor="let h of tHeaders" [ngClass]="{'text-center': h?.key == 'isLive'}">
                                        <ng-container>
                                            <span
                                                *ngIf="h?.key !== 'lockSupport' && h?.key != 'status' && h?.key != 'icon'">{{getColValue(item[h?.key])}}</span>
                                            <span *ngIf="h?.key == 'status'">{{item?.status ? 'Yes':'No'}}</span>
                                            <i class="fs-20 fa" *ngIf="h?.key == 'lockSupport'"
                                            [ngClass]="{'fa-check-circle text-success': item?.lockSupport, 'fa-times-circle text-danger': !item?.lockSupport}"></i>
                                  
                                            </ng-container>
                                    </td>
                                    <td class="d-flex">
                                        <button class="btn btn-sm btn-success btn-color mr-2" href="javascript:void(0);"
                                            title="Edit" (click)="update(item)"><i class="fa fa-edit mr-1"></i>
                                        </button>
                                        <button class="btn btn-sm btn-danger btn-color" 
                                            href="javascript:void(0);" title="Delete" (click)="deleteConfirm(item)"><i
                                                class="fa fa-trash mr-1"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="container-fluid" *ngIf="formShow">
                <div class="modal-content border-0">
                    <div class="modal-header  bg-danger py-10">
                        <h4 class="modal-title float-left ">
                            {{buttonName==commonEnum.SAVE ? 'Create' : 'Update'}}
                             <!-- {{redirectUrls.ADMIN_VEHICLE_PORT_LABEL}} -->
                        </h4>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="postForm">
                            <div class="row">
                                <form [formGroup]="postForm">
                                    <div class="row">
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="name">Name<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="name" name="name"
                                                id="name" placeholder="Enter Name" aria-label="name"
                                                
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('name').errors }">
                                            <div *ngIf="submitted && postForm.get('name').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('name').errors.required">Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="maxPower">Max Power<sup>*</sup></label>
                                            <input type="number" class="form-control" formControlName="maxPower" name="maxPower"
                                                id="maxPower" placeholder="Enter Max Power" aria-label="maxPower"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('maxPower').errors }">
                                            <div *ngIf="submitted && postForm.get('maxPower').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('maxPower').errors.required">Max Power is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="maxVolts">Max Volts<sup>*</sup></label>
                                            <input type="number" class="form-control" formControlName="maxVolts" name="maxVolts"
                                                id="maxVolts" placeholder="Enter Max Volts" aria-label="maxVolts"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('maxVolts').errors }">
                                            <div *ngIf="submitted && postForm.get('maxVolts').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('maxVolts').errors.required">Max Volts is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="maxAmps">Max Amps<sup>*</sup></label>
                                            <input type="number" class="form-control" formControlName="maxAmps" name="maxAmps"
                                                id="maxAmps" placeholder="Enter Max Amps" aria-label="maxAmps"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('maxAmps').errors }">
                                            <div *ngIf="submitted && postForm.get('maxAmps').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('maxAmps').errors.required">Max Amps is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="level">Level<sup>*</sup></label>
                                            <input type="number" class="form-control" formControlName="level" name="level"
                                                id="level" placeholder="Enter Level" aria-label="level"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('level').errors }">
                                            <div *ngIf="submitted && postForm.get('level').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('level').errors.required">Level is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="pins">Pins<sup>*</sup></label>
                                            <input type="number" class="form-control" formControlName="pins" name="pins"
                                                id="pins" placeholder="Enter Pins" aria-label="pins"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('pins').errors }">
                                            <div *ngIf="submitted && postForm.get('pins').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('pins').errors.required">Pins is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="lockSupport">Lock Support<sup>*</sup></label>
                                            <input type="text" class="form-control" formControlName="lockSupport" name="lockSupport"
                                                id="lockSupport" placeholder="Enter Lock Support" aria-label="lockSupport"
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('lockSupport').errors }">
                                            <div *ngIf="submitted && postForm.get('lockSupport').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('lockSupport').errors.required">Lock Support is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="level">&nbsp;Lock Support</label>
                                            <div class="form-control">
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="lockSupport" name="lockSupport" formControlName="lockSupport">
                                                    <label class="form-check-label pl-4" for="lockSupport">Lock Support</label>
                                                </div>
                                            </div>
        
                                        </div>
                                        <div class="form-group col-lg-4 col-p">
                                            <label for="stat">Status<sup>*</sup></label>
                                            <div class="form-check form-switch form-control">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" formControlName="status">
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    Yes/No</label>
                                            </div>
                                        </div>
                                        <div class="form-group col-lg-8 col-p">
                                            <label for="description">Description<sup>*</sup></label>
                                            <textarea type="text" class="form-control" formControlName="description"
                                                name="description" id="description" placeholder="Enter Description"
                                                aria-label="description" maxlength="50" #description
                                                [ngClass]="{ 'is-invalid': submitted && postForm.get('description').errors }"></textarea>
                                            <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                                {{description.value.length}}/50
                                            </small>
                                            <div *ngIf="submitted && postForm.get('description').errors"
                                                class="invalid-feedback">
                                                <div *ngIf="postForm.get('description').errors.required">
                                                    Description is required.
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <hr class="edit-last-separator-hr" />
                                    <!-- {{postForm.value | json}} -->
                                </div>
                            </div>

                            <div class="form-group actionBtn-form-group">
                                <div class="float-right">
                                    <button type="button" class="btn btn-danger btn-md mr-1"
                                        (click)="cancel()">Cancel</button>
                                    <button type="button" class="btn btn-success btn-md"
                                        (click)="save(buttonType.SUBMIT_CLOSE)"
                                        *ngIf="buttonName==commonEnum.SAVE && !processing">Save</button>
                                    <button type="button" class="btn btn-success btn-md "
                                        (click)="save(buttonType.SUBMIT_UPDATE)"
                                        *ngIf="buttonName==commonEnum.UPDATE && !processing">Update</button>
                                    <button class="btn btn-success btn-md" type="button" *ngIf="processing"
                                        disabled>{{commonEnum.PROCESSING}}</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>