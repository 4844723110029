import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TermsComponent } from './terms/terms.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllVehiclesComponent } from './all-vehicles/all-vehicles.component';
import { CityCategoryComponent } from './city-category/city-category.component';
import { CityComponent } from './city/city.component';
import { RedirectRoutes } from './core/enums/route.enum';
import { CountryComponent } from './country/country.component';
import { CreateComponent } from './create/create.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DistictComponent } from './distict/distict.component';
import { LanguagesComponent } from './languages/languages.component';
import { LocationsComponent } from './locations/locations.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { PaymentsComponent } from './payments/payments.component';
import { PostalAddressComponent } from './postal-address/postal-address.component';
import { ProfileComponent } from './profile/profile.component';
import { RequestsComponent } from './requests/requests.component';
import { StateComponent } from './state/state.component';
import { StationCategoryComponent } from './station-category/station-category.component';
import { StationChargeCostComponent } from './station-charge-cost/station-charge-cost.component';
import { StationPortComponent } from './station-port/station-port.component';
import { StationProviderComponent } from './station-provider/station-provider.component';
import { StationsComponent } from './stations/stations.component';
import { UsersComponent } from './users/users.component';
import { VehicleChargeCapacityComponent } from './vehicle-charge-capacity/vehicle-charge-capacity.component';
import { VehicleManufactureComponent } from './vehicle-manufacture/vehicle-manufacture.component';
import { VehicleModelComponent } from './vehicle-model/vehicle-model.component';
import { VehiclePortComponent } from './vehicle-port/vehicle-port.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginAuthGuard } from './auth/guards/login.auth';
import { AdminRoutesAuthGuard } from './auth/guards/admin-routes.auth';
import { NotificationsComponent } from './notifications/notifications.component';
import { VersionComponent } from './version/version.component';

const routes: Routes = [
  { path: '', component: LoginComponent, data: { title: RedirectRoutes.LOGINLABEL}, canActivate: [LoginAuthGuard]},
  { path: 'admin', component: MainComponent, canActivate: [AdminRoutesAuthGuard],  children: [
  { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard'}},
  { path: RedirectRoutes.ADMIN_USERS, component: UsersComponent, data: { title: RedirectRoutes.ADMIN_USERS_LABEL}},
  { path: RedirectRoutes.ADMIN_LOCATIONS, component: LocationsComponent, data: { title: RedirectRoutes.ADMIN_LOCATIONS_LABEL}},
  { path: RedirectRoutes.ADMIN_STATIONS_CATEGORY, component: StationCategoryComponent, data: { title: RedirectRoutes.ADMIN_STATIONS_CATEGORY_LABEL}},
  { path: RedirectRoutes.ADMIN_STATIONS_CHARGECOST, component: StationChargeCostComponent, data: { title: RedirectRoutes.ADMIN_STATIONS_CHARGECOST_LABEL}},
  { path: RedirectRoutes.ADMIN_STATIONS_PORT, component: StationPortComponent, data: { title: RedirectRoutes.ADMIN_STATIONS_PORT_LABEL}},
  { path: RedirectRoutes.ADMIN_STATIONS_PROVIDER, component: StationProviderComponent, data: { title: RedirectRoutes.ADMIN_STATIONS_PROVIDER_LABEL}},
  { path: RedirectRoutes.ADMIN_COUNTRY, component: CountryComponent, data: { title: RedirectRoutes.ADMIN_COUNTRY_LABEL}},
  { path: RedirectRoutes.ADMIN_STATE, component: StateComponent, data: { title: RedirectRoutes.ADMIN_STATE_LABEL}},
  { path: RedirectRoutes.ADMIN_CITY_CATEGORY, component: CityCategoryComponent, data: { title: RedirectRoutes.ADMIN_CITY_CATEGORY_LABEL}},
  { path: RedirectRoutes.ADMIN_CITY, component: CityComponent, data: { title: RedirectRoutes.ADMIN_CITY_LABEL}},
  { path: RedirectRoutes.ADMIN_DISTRICT, component: DistictComponent, data: { title: RedirectRoutes.ADMIN_DISTRICT_LABEL}},
  { path: RedirectRoutes.ADMIN_POSTAL_ADDRESS, component: PostalAddressComponent, data: { title: RedirectRoutes.ADMIN_POSTAL_ADDRESS_LABEL}},
  { path: RedirectRoutes.ADMIN_VEHICLE_CHARGE_CAPACITY, component: VehicleChargeCapacityComponent, data: { title: RedirectRoutes.ADMIN_VEHICLE_CHARGE_CAPACITY_LABEL}},
  { path: RedirectRoutes.ADMIN_VEHICLE_MANUFACTURE, component: VehicleManufactureComponent, data: { title: RedirectRoutes.ADMIN_VEHICLE_MANUFACTURE_LABEL}},
  { path: RedirectRoutes.ADMIN_VEHICLE_MODEL, component: VehicleModelComponent, data: { title: RedirectRoutes.ADMIN_VEHICLE_MODEL_LABEL}},
  { path: RedirectRoutes.ADMIN_VEHICLE_PORT, component: VehiclePortComponent, data: { title: RedirectRoutes.ADMIN_VEHICLE_PORT_LABEL}},
  { path: RedirectRoutes.ADMIN_LANGUAGES, component: LanguagesComponent, data: { title: RedirectRoutes.ADMIN_LANGUAGES_LABEL}},
  { path: RedirectRoutes.ADMIN_VEHICLES, component: AllVehiclesComponent, data: { title: RedirectRoutes.ADMIN_VEHICLES_LABEL}},
  { path: RedirectRoutes.ADMIN_PROFILE, component: ProfileComponent, data: { title: RedirectRoutes.ADMIN_PROFILE_LABEL}},
  { path: RedirectRoutes.ADMIN_REVIEW_MANAGEMENT, component: ReviewsComponent, data: { title: RedirectRoutes.ADMIN_REVIEW_MANAGEMENT_LABEL}},
  { path: RedirectRoutes.ADMIN_VERSION_MANAGEMENT, component: VersionComponent, data: { title: RedirectRoutes.ADMIN_VERSION_MANAGEMENT_LABEL}},
  { path: RedirectRoutes.ADMIN_TRANSACTIONS, component: TransactionsComponent, data: { title: RedirectRoutes.ADMIN_TRANSACTIONS_LABEL}},

  { path: 'create', component: CreateComponent, data: { title: 'Create'}},
  { path: 'payments', component: PaymentsComponent, data: { title: 'Payments'}},
  { path: 'requests', component: RequestsComponent, data: { title: 'Requests'}},
  { path: 'stations', loadChildren: () => import('./stations/stations.module').then((mod) => mod.StationsModule)},
  { path: 'notifications', component: NotificationsComponent, data: { title: 'Stations'}},
  // { path: '', redirectTo: 'login', pathMatch: 'full'}
  ]},
  { path: RedirectRoutes.ADMIN_FORGOT, component: ForgotPasswordComponent, data: { title: RedirectRoutes.ADMIN_FORGOT_LABEL}},
  { path: RedirectRoutes.ADMIN_TERMS, component: TermsComponent, data: { title: RedirectRoutes.ADMIN_TERMS_LABEL}},
  { path: '**', component: NotFoundComponent, data: { title: RedirectRoutes.ADMIN_NOTFOUND_LABEL}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
