import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { CreateComponent } from './create/create.component';
import { PaymentsComponent } from './payments/payments.component';
import { RequestsComponent } from './requests/requests.component';
// import { StationsComponent } from './stations/stations.component';
import { MainComponent } from './main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LocationsComponent } from './locations/locations.component';
import { StationCategoryComponent } from './station-category/station-category.component';
import { StationChargeCostComponent } from './station-charge-cost/station-charge-cost.component';
import { StationPortComponent } from './station-port/station-port.component';
import { StationProviderComponent } from './station-provider/station-provider.component';
import { LanguagesComponent } from './languages/languages.component';
import { CountryComponent } from './country/country.component';
import { StateComponent } from './state/state.component';
import { CityCategoryComponent } from './city-category/city-category.component';
import { CityComponent } from './city/city.component';
import { DistictComponent } from './distict/distict.component';
import { PostalAddressComponent } from './postal-address/postal-address.component';
import { VehicleChargeCapacityComponent } from './vehicle-charge-capacity/vehicle-charge-capacity.component';
import { VehicleManufactureComponent } from './vehicle-manufacture/vehicle-manufacture.component';
import { VehicleModelComponent } from './vehicle-model/vehicle-model.component';
import { VehiclePortComponent } from './vehicle-port/vehicle-port.component';
import { NumberOnlyDirective } from './core/directives/number.directive';
import { PercentageOnlyDirective } from './core/directives/percentage.directive';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { ProfileComponent } from './profile/profile.component';
import { AllVehiclesComponent } from './all-vehicles/all-vehicles.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ReviewsComponent } from './reviews/reviews.component';
import { AlphabetOnlyDirective } from './core/directives/alphabet.directive';
import { TransactionsComponent } from './transactions/transactions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TermsComponent } from './terms/terms.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { OverlayModule} from '@angular/cdk/overlay';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VersionComponent } from './version/version.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    UsersComponent,
    CreateComponent,
    PaymentsComponent,
    RequestsComponent,
    // StationsComponent,
    MainComponent,
    SidenavComponent,
    LocationsComponent,
    StationCategoryComponent,
    StationChargeCostComponent,
    StationPortComponent,
    StationProviderComponent,
    LanguagesComponent,
    CountryComponent,
    StateComponent,
    CityCategoryComponent,
    CityComponent,
    DistictComponent,
    PostalAddressComponent,
    VehicleChargeCapacityComponent,
    VehicleManufactureComponent,
    VehicleModelComponent,
    VehiclePortComponent,
    NumberOnlyDirective,
    AlphabetOnlyDirective,
    PercentageOnlyDirective,
    VehiclesComponent,
    ProfileComponent,
    AllVehiclesComponent,
    ReviewsComponent,
    VersionComponent,
    TransactionsComponent,
    NotFoundComponent,
    TermsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    PaginationModule,
    PerfectScrollbarModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    NgSelectModule,
    NgApexchartsModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    OverlayModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgbModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
