<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-5">
            <div class="col-sm-6">
                <h2 class="text-white">{{redirectUrls.ADMIN_VERSION_MANAGEMENT_LABEL}}</h2>
                <p class="fonts-weight-700 text-danger"><span class="small">*</span>Note : Atleast One version should be in Live State.</p>

            </div>
            <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" (click)="addTask()" *ngIf="!formShow">Add
                    {{redirectUrls.ADMIN_VERSION_MANAGEMENT_LABEL}}</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <div class="col-sm-12 col-p  main-content">
            <div class="p-10 post-inner" *ngIf="!formShow">
                <div class="post-body table-post">
                    <div class="table-responsive">
                            <table class="table  table-nowrap table-align-middle">
                                <thead class="table-header">
                                    <tr>
                                        <th *ngFor="let item of tHeaders;let i=index;">
                                            <div class="p-d-block p-jc-between p-ai-center">
                                                {{item?.header}}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="noDataFound">
                                        <td class="post_details_inner text-center" colspan="100">
                                            <div class="post-inner">
                                                <div class="post-body no-data">
                                                    <p class="mb-0 font-weight-bold text-secondary">No Data Found..!!</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of VersionList;let i=index;">
                                        <td>{{item?.version}}</td>
                                        <td>{{item?.description}}</td>
                                        <td *ngIf="item.status == true">Active</td>
                                        <td *ngIf="item.status == false">InActive</td>
                                        <td>
                                            <span class="p-1 cusor-pointer" (click)="update(item)"><i class="fa fa-edit"></i></span>
                                            <span class="p-1 cusor-pointer" (click)="deleteVersion(item._id)"><i class="fa fa-trash"></i></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid p-1" *ngIf="formShow">
                <div class="modal-content border-0 bg-transparent">
                    <div class="modal-header border-0">
                        <h4 class="text-white float-left mb-0">
                            {{buttonName==commonEnum.SAVE ? 'Create' : 'Update'}} {{redirectUrls.ADMIN_VERSION_MANAGEMENT_LABEL}}
                        </h4>
                    </div>
                    <div class="modal-body pt-0 pb-0">
                        <form [formGroup]="postForm">
                            <div class="row">
                                <div class="form-group col-lg-4 col-p">
                                    <label class="text-white mb-2" for="version">Version<sup>*</sup></label>
                                    <input type="text" class="form-control" formControlName="version" name="version" id="version" placeholder="Enter Version Number" aria-label="name" maxlength="5" #amount [ngClass]="{ 'is-invalid': submitted && postForm.get('version').errors }">
                                    <div *ngIf="submitted && postForm.get('version').errors" class="invalid-feedback">
                                        <div *ngIf="postForm.get('version').errors.required">Version is required.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-lg-4 col-p">
                                    <label class="text-white mb-2" for="status">&nbsp;Status</label>
                                    <div class="form-control">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="status" name="status" formControlName="status">
                                            <label class="form-check-label pl-4" for="status">Is Active</label>
                                        </div>
                                    </div>

                                </div>

                                <div class="form-group  col-p">
                                    <label class="text-white mb-2" for="description">Description<sup>*</sup></label>
                                    <textarea type="text" class="form-control" formControlName="description" name="description" id="description" placeholder="Enter Version Description" aria-label="description" maxlength="500" #description [ngClass]="{ 'is-invalid': submitted && postForm.get('description').errors }"></textarea>
                                    <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                    {{description.value.length}}/500
                                </small>
                                    <div *ngIf="submitted && postForm.get('description').errors" class="invalid-feedback">
                                        <div *ngIf="postForm.get('description').errors.required">
                                            Version Description is required.
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <hr class="edit-last-separator-hr" /> </div>
                            </div>

                            <div class="form-group actionBtn-form-group">
                                <div class="float-right">
                                    <button type="button" class="btn btn-danger btn-sm border add-btn mr-1" (click)="cancel()">Cancel</button>
                                    <button type="button" class="btn btn-danger btn-sm border add-btn" (click)="save()" *ngIf="buttonName==commonEnum.SAVE && !processing">Save</button>
                                    <button type="button" class="btn btn-danger btn-sm border add-btn" (click)="save()" *ngIf="buttonName==commonEnum.UPDATE && !processing">Update</button>
                                    <button class="btn btn-success btn-md" type="button" *ngIf="processing" disabled>{{commonEnum.PROCESSING}}</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
