import { CommonService } from './../core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { FEDDBACK_URL } from '../core/configurations/url';
import { RedirectRoutes } from '../core/enums/route.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  formShow: boolean;
  showCarousel = true;
  ImageArey = [];
  redirectUrls = RedirectRoutes;
  items: any = [];
  imgepath = environment.baseUrl + '';
  params: HttpParams;
  pageIndex: any = 1;
  pageSize: any = 10;
  public maxSize: number = 5;
  totalRecords: any;
  noDataFound: boolean;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page) * event.itemsPerPage;
    this.pageIndex = event.page,
      this.loadData();
  }
  loadData() {
    this.params = new HttpParams()
  .set('page', this.pageIndex.toString())
  .set('size', this.pageSize.toString());
    let url = FEDDBACK_URL.getAllFeedback;
    this.noDataFound = false;

    this.commonService.commonApiCall(
      url + '?' + this.params,
      HttpMethod.POST,
      null,
      (res,statusFlag) => {
        if(statusFlag){
          this.items = res.responseData;
          console.log(this.items);

          this.ImageArey = res.responseData[0].feedbackPictures;
          console.log(this.ImageArey);
          this.totalRecords = res.count;

          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }

      }
    )
  }

  addModal() {
    this.formShow = !this.formShow;
  }

  approveOrDecline(item,status){
    // console.log(item);

    let obj ={
      // "userId": item.,
      // "adminId":"",
      "feedbackId":item.feedbackId,
      // "stationId":"",
      // "feedback":"this feedback",
      "status": status
  };
    let url = FEDDBACK_URL.createOrUpdateFeedback;
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      obj,
      (res,statusFlag) => {
        if(statusFlag){
          // console.log(res);
          this.loadData();
        }

      }
    )
  }
}
