import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { VEHICLE_MODEL_URLS } from '../core/configurations/url';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-all-vehicles',
  templateUrl: './all-vehicles.component.html',
  styleUrls: ['./all-vehicles.component.scss']
})
export class AllVehiclesComponent implements OnInit {
  formShow: boolean;
  noDataFound: boolean;
  vehicleList: any = [];
  currentUser: any;
  redirectUrls = RedirectRoutes;
  parentObj: any;
  params: HttpParams;
  pageIndex: any = 1;
  pageSize: any = 12;
  public maxSize: number = 5;
  totalRecords: any;
  constructor(
    private commonService: CommonService,
    private appService: AppService,

  ) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.loadData();
  }
  addTask(load?:boolean) {
    this.formShow = !this.formShow;
    if(load){
      this.loadData();
    }

  }
  cancel(){
    this.parentObj = null;
    this.addTask(true);
    // this.formShow = !this.formShow;
  }
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page) * event.itemsPerPage;
    this.pageIndex = event.page,
      this.loadData();
  }
  loadData() {
  //   this.params = new HttpParams()
  // .set('page', this.pageIndex.toString())
  // .set('size', this.pageSize.toString());
    const url = VEHICLE_MODEL_URLS.getAll;
    this.noDataFound = false;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      // url + '?' + this.params,
      url + `?page=${this.pageIndex}`,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.vehicleList = res.responseData;
          this.totalRecords = res.count;
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
  edit(item){
    this.parentObj = item;
    this.addTask();

  }
}
