import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LOGIN } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  submitted = false;

  notificationsForm: FormGroup;
  redirectUrls = RedirectRoutes;
  userDetails: any;
  constructor(private fb: FormBuilder, private appService: AppService, private commonService: CommonService, private sweetAlert: SweetalertService) { }
  ngOnInit(): void {
    this.notificationsForm = this.fb.group({
      roleName: ['StationAdmin', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required]
    });
    // this.sweetAlert.showMessage(AlertInfo.SUCCESS, 'Notified successfully.');

    console.log(this.appService.getUserDetails());
    this.userDetails = this.appService.getUserDetails();
  }

  sendNotification(): void {
    if (this.notificationsForm.valid) {
      this.commonService.commonApiCall(
        LOGIN.pushNotification ,
        HttpMethod.POST,
        {
          userId: this.userDetails._id,
          ...this.notificationsForm.value
        },
        (res) => {
          if (res.responseData) {
            this.notificationsForm.reset();
            // this.sweetAlert.showMessage(AlertInfo.SUCCESS, 'Notified successfully.');
          } else {
            // this.sweetAlert.showMessage(AlertInfo.SUCCESS, 'Something went wrong!');
          }
        }
      );
    } else if(this.notificationsForm.controls.roleName.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, 'User type is required');
    } else if(this.notificationsForm.controls.title.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, 'Title is required');
    } else if(this.notificationsForm.controls.description.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, 'Message  is required');
    }
  }
}
