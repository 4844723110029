<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_REVIEW_MANAGEMENT_LABEL}}{{totalRecords ? '(' + totalRecords + ')' : ''}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row ">
        <div class="list-view" *ngFor="let item of items">
            <div class="list-header">
                <div class="list-img">
                    <!-- <img src="{{imgepath}}{{item?.evStationData?.stationPictures[0]}}" alt="" srcset="" class="img-circle" onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';"> -->
                    <img src="{{item?.userPicture}}" class="img-circle" alt="">
                </div>
                <div class="list-title">
                    <!-- <h5> {{item?.userName}} - {{item?.evStationData?.postalAddress?.fullAddress}}</h5> -->
                    <h5 class="text-capitalize"> {{item?.userName}} </h5>
                    <p>{{item?.rating}}
                        <span>
                        <i class="fa fa-star " [ngClass]="{'fa-active': item?.rating > 0}"></i>
                        <i class="fa fa-star " [ngClass]="{'fa-active': item?.rating > 1}"></i>
                        <i class="fa fa-star " [ngClass]="{'fa-active': item?.rating > 2}"></i>
                        <i class="fa fa-star " [ngClass]="{'fa-active': item?.rating > 3}"></i>
                        <i class="fa fa-star" [ngClass]="{'fa-active': item?.rating > 4}"></i>
                      </span>
                        <span class="list-date">
                          {{item?.createdAt | date: 'd MMM y, h:mm a'}}
                      </span>
                    </p>
                </div>
                <div class="list-actions d-flex align-self-center">
                    <button class="btn btn-success btn-sm theme-bg decline-btn " (click)="approveOrDecline(item,true)">Approve <i class="fa fa-check ml-1"></i></button>
                    <button class="btn btn-danger btn-sm decline-btn" *ngIf="item?.status" (click)="approveOrDecline(item,false)">Decline <i class="fa fa-times ml-1"></i></button>
                </div>
            </div>
            <div class="list-body">
                <p>{{item?.feedback}}</p>
                <p class="list-images">
                    <img *ngFor="let imgs of item?.feedbackPictures" (click)="addModal()" src="{{imgs}}" alt="" srcset="">
                </p>


                <div *ngIf="formShow" class="modal-content border-0">
                    <div class="modal-header bg-black py-10">
                        <h4 class="modal-title text-white float-left ">
                            Feedback Picture
                        </h4>
                        <button type="button" class="bg-transparent border-0 text-white font-20 close" (click)="addModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                    </div>
                    <div class="modal-body bg-black">
                        <div class="row">
                            <ngb-carousel>
                                <ng-template ngbSlide *ngFor="let im of ImageArey">
                                    <img class="cutom--set" src="{{im}}" alt="Random slide">
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="row text-center" *ngIf="!items.length">
        <p class="p-5 text-secondary">No Data Found..!!</p>
    </div>
    <pagination [ngClass]="{'d-none': noDataFound,'d-flex': !noDataFound}" class=" justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords"
        (pageChanged)="pageChanged($event)">
    </pagination>
</div>
