<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_REQUEST_COMPONENT_LABEL}}</h2>
                <!-- <h2 class="m-0 text-light">{{redirectUrls.ADMIN_REQUEST_COMPONENT_POST_LABEL}}</h2> -->
            </div>
            <div class="col-sm-6 text-right">
                <button class="btn btn-danger btn-sm border float-right add-btn" *ngIf="formShow" (click)="formShow = false"><span>Back</span></button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m" *ngIf="!formShow">
        <div class="cards">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-lg-3 d-flex align-items-stretch mb-3" *ngFor="let item of requestsList">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex">
                                <!-- <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">&nbsp;</label>
                                  </div> -->
                                <div class="img-circle">
                                    <img class="card-img-top" src="{{imgepath}}{{item?.stationPictures[0]}}" onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';">
                                </div>
                                <!-- <i class="fa fa-pen"  aria-hidden="true"></i> -->
                            </div>
                            <h4 class="card-title">{{item?.name}}</h4>
                            <p class="card-text mb-1">{{item?.contact[0]?.name}}</p>
                            <!-- <p class="card-text mb-1">{{item?.contact[0]?.mobileNumber}}</p>
                            <p class="card-text mb-1">{{item?.postalAddress?.fullAddress}}</p> -->
                            <div class="d-flex action-buttons">
                                <a class="btn btn-outline-info mr-1" (click)="viewDetails(item)">View</a>
                                <a class="btn btn-outline-success mr-1" (click)="approvalSubmit(item,'2')">Accept</a>
                                <a class="btn btn-outline-danger" (click)="approvalSubmit(item,'3')">Decline</a>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="row text-center" *ngIf="!requestsList.length">
                    <p class="p-5 text-secondary">No Data Found..!!</p>
                </div>

                <pagination [ngClass]="{'d-none': noDataFound,'d-flex': !noDataFound}" class=" justify-content-end" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" responsive="true" [maxSize]="maxSize" [boundaryLinks]="true" [totalItems]="totalRecords"
                    (pageChanged)="pageChanged($event)">
                </pagination>

            </div>
        </div>
    </div>
    <div class="row row-m" *ngIf="formShow">
        <div class="row">
            <div class="col-md-3 align-items-stretch mb-3">
                <div class="card ">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="img-circle">
                                <img class="card-img-top" src="{{imgepath}}api{{viewItem?.stationPictures[0]}}" onerror="this.onerror=null;this.src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';">
                                <!-- <img class="card-img-top" src="{{imgepath}}api{{viewItem?.stationPictures[0]}}"> -->
                            </div>
                            <!-- <i class="fa fa-pen"  aria-hidden="true"></i> -->
                        </div>
                        <h4 class="card-title">{{viewItem?.name}}</h4>
                        <p class="card-text card-view-text"> {{viewItem?.contact[0]?.email}}</p>
                        <p class="card-text card-view-text p-1">
                            <!-- <img src="https://cdn.dribbble.com/users/310943/screenshots/2792692/empty-state-illustrations.gif" alt="" style="width: 79px;height: 52px;">  -->
                            {{viewItem?.postalAddress?.fullAddress}}
                        </p>
                    </div>
                </div>
                <div class="tabs mt-4 ">
                    <h4>Ports</h4>
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <button class="nav-link" [ngClass]="{'active nav-active': tabId == 1}" id="pills-home-tab" data-toggle="pill" (click)="tab(1)" role="tab" aria-controls="pills-home" aria-selected="true">
                                  <span class="nav-pill-text">AC</span>
                              </button>
                        </li>
                        <li class="nav-item">
                            <button class="nav-link" [ngClass]="{'active nav-active': tabId == 2}" id="pills-profile-tab" data-toggle="pill" (click)="tab(2)" role="tab" aria-controls="pills-profile" aria-selected="false">
                                <span class="nav-pill-text">DC</span>

                              </button>
                        </li>
                    </ul>
                    <div class="tab-content mb-3" id="pills-tabContent">
                        <div class="tab-pane fade" [ngClass]="{'show active': tabId == 1}" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="row">
                                <div class=" col-md-4 col-sm-6 p-1" *ngFor="let item of viewItem?.ports;let i = index">
                                    <div class="theme-border">
                                        <div class="tab-card text-center">
                                            <i class="fa fa-circle" style="font-size: 12px; margin-left: auto;" [ngClass]="item?.status == true ? 'text-success':'text-danger'" aria-hidden="true"></i>
                                            <img src="../../assets/img/vector.svg" alt="" style="Width:27.5px;Height:30px;margin: auto;">
                                            <p class="text-white" style="line-height: 20px;margin-bottom: 10px; margin-top: 10px;">Port {{i+1}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="tab-pane fade" [ngClass]="{'show active': tabId == 2}" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="row">
                                <div class=" col-md-4 col-sm-6 p-1" *ngFor="let item of viewItem?.ports;let i = index">
                                    <div class="theme-border">
                                        <div class="tab-card text-center">
                                            <i class="fa fa-circle" style="font-size: 12px; margin-left: auto;" [ngClass]="item?.status == true ? 'text-success':'text-danger'" aria-hidden="true"></i>
                                            <img src="../../assets/img/vector.svg" alt="" style="Width:27.5px;Height:30px;margin: auto;">
                                            <p style="line-height: 20px;margin-bottom: 10px; margin-top: 10px;">Port {{i+1}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <button class="btn btn-success theme-bg mr-1" [disabled]="" (click)="approvalSubmit(viewItem,'2')">Approve</button>
                    <button class="btn btn-danger" (click)="approvalSubmit(viewItem,'3')">Decline</button>
                </div>

            </div>
            <div class="col-md-9 mb-3">
                <div class="row align-self-start">
                    <div class="img-card b-img" *ngFor="let item of viewItem?.stationPictures">
                        <img src="{{item}}" style="object-fit: contain; width: 120px; height: 100px; " alt="" class="img-card">
                    </div>
                </div>
                <div class="row mt-10 align-self-center">
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.contact[0]?.name" id="name" placeholder="Station Name" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.contact[0]?.mobileNumber" id="name" placeholder="Mobile Number" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.contact[0]?.email" id="name" placeholder="Email Id" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.postalAddress?.fullAddress" id="name" placeholder="Location" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.slug" id="name" placeholder="Default" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.contact[0]?.landlineNumber" id="name" placeholder="Landline Number" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.notes" id="name" placeholder="Default" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.evStationProvider[0]?.brandName" id="name" placeholder="Default" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.evStationProvider[0]?.origanizationName" id="name" placeholder="Electric Charging Providers" aria-label="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" name="name" readonly [value]="viewItem?.postalAddress?.postalCode" id="name" placeholder="Pin Code" aria-label="name">
                        </div>
                    </div>
                </div>
                <div class="row mt-1 align-self-end">
                    <div class="col-md-4" *ngFor="let item of viewItem?.pricingPlan">
                        <div class="price-card">
                            <div class="price-header">
                                <img src="assets/img/flash.png" alt="" width="20px" height="20px">
                                <span>{{item?.name}}</span>
                            </div>
                            <div class="price-body color-white">
                                <div class="top-content">
                                    <p class="m-0">{{viewItem?.name}}</p>
                                    <div class="pricing-details mb-3">
                                        <strong class="col-12">${{item?.price}} &nbsp;/{{item?.chargingTime}}min</strong>
                                        <small class="text-muted d-block">inclusive GST tax</small>
                                    </div>
                                </div>
                                <!-- <div class="text-center">
                                    <span class="plans-box theme-bg">
                                        Edit Plans
                                    </span>
                                </div> -->
                                <p class="row">
                                    <!-- <strong class="col-6">/{{item?.chargingTime}}min</strong></p> -->
                                    <!-- <p>
                  <button class="btn btn-danger btn-sm border float-right add-btn"   style="width: 100px;height: 40px;" ><span>Edit Plans</span></button>

                                      </p> -->
                                    <ul class="p-0">
                                        <li> <i class="fa fa-check mr-3"></i>{{item?.amenities}}</li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>