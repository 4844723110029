import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LOGIN } from '../core/configurations/url';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentUser: any;
  postForm: any;
  submitted: boolean;
  noDataFound: boolean;
  allSuperAdminList: any;
  redirectUrls = RedirectRoutes;
  profileForm: FormGroup;
  profileImage: string;
  isImageUploaded = false;
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private sweetAlert: SweetalertService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.profileImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png';
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.loadData();
  }
  createForm() {
    this.profileForm = this.fb.group({
      // evStationPortId: [0],
        pictures: [''],
        firstName: ['', [Validators.required, Validators.maxLength(50)]],
        userId: [this.currentUser._id],
        mobileNumber: ['', [Validators.required]],
        email: ['', [Validators.required]],
        // city: [''],
        // isActive: true,
        // isBusy: false,
        // isDisplay: false,
        // isEmergency: false,
        // locale: [''],
        // status: true,
      });
      this.profileForm.patchValue(this.currentUser);
    this.submitted = false;
  }
  handleReaderLoaded(readerEvt): any {
    const binaryString = readerEvt.target.result;
    this.isImageUploaded = true;
    this.profileImage = 'data:image/png;base64,' + btoa(binaryString);
  }
  profileImageUpdated(event: any): void {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(event.target.files[0]);
    this.profileForm.controls.pictures.patchValue(event.target.files[0]);
  }
  loadData() {
    const url = LOGIN.getSuperAdminById;
    this.noDataFound = false;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      url ,
      HttpMethod.POST,
      obj,
      (res, statusFlag) => {
        if (statusFlag) {
          this.allSuperAdminList = res.responseData;
          console.log(this.allSuperAdminList);
          
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
  onSubmit(): void {

  }
}
