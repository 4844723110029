import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppService } from "src/app/core/services/app.service";
import {RedirectRoutes } from "src/app/core/enums/route.enum"; 
import { Appconfig } from "src/app/core/enums/app-config.enum";
@Injectable({providedIn: 'root'})
export class LoginAuthGuard implements CanActivate {
    constructor(private appService: AppService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (localStorage.getItem(Appconfig.CURRENTUSER)) {
            this.router.navigateByUrl(`/${RedirectRoutes.ADMIN}${RedirectRoutes.ADMIN_DASHBOARD}`);
            return false;
        }
        return true;
    }
}