import { Component, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { environment } from 'src/environments/environment';
import { STATION_PROVIDER_URLS, STATION_URLS } from '../core/configurations/url';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { CommonService } from '../core/services/common.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  formShow: boolean;
  requestsList: any = [
    // {
    //   userName: 'userName',
    //   email: 'email@gmail.com',
    //   mobileNumber: 6827464285745
    // },
    // {
    //   userName: 'userName2',
    //   email: 'email2@gmail.com',
    //   mobileNumber: 6827464285745
    // }
  ];

  pageIndex: any = 1;
  public maxSize: number = 10;
  totalRecords: any;

  noDataFound: boolean;
  viewItem: any;
  tabId: any = 1;
  tabcards: any = [1,2,3,4,5,6];
  priceCards: any = [1,2,3,4];
  imgepath = environment.baseUrl + '/';

  constructor(
    private commonService: CommonService

  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page) * event.itemsPerPage;
    this.pageIndex = event.page,
      this.loadData();
  }

  loadData() {
    // const url = STATION_PROVIDER_URLS.getAllEvStations;
    // this.noDataFound = false;
    // let obj = { status: 1 };
    // this.commonService.commonApiCall(
    //   url,
    //   HttpMethod.POST,
    const url = STATION_PROVIDER_URLS.getAllEvStations;
    this.noDataFound = false;
    let obj ={ status: 1}
    this.commonService.commonApiCall(
      // url + '?' + this.params,
      url + `?page=${this.pageIndex}`,
      HttpMethod.POST,
      obj,
      (res, statusFlag) => {
        if (statusFlag) {
          this.requestsList = res.responseData;
          this.totalRecords = res.count;
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
  approvalSubmit(item,id) {
    const url = STATION_URLS.stationApproval;
    this.noDataFound = false;
    let obj = {
      evStationId: item._id,
      status: id };
    this.commonService.commonApiCall(
      url,
      HttpMethod.POST,
      obj,
      (res, statusFlag) => {
        if (statusFlag) {
          this.formShow = false;
          this.loadData();
        }
      }
    );
  }
  viewDetails(item){
    this.formShow = true;
    console.log(item);
    this.viewItem = item;
    console.log(this.viewItem);
  }
  tab(id){
    this.tabId = id;
  }

}
