<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_TRANSACTIONS_LABEL}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <div class="col-sm-12 col-p  main-content">
            <div class=" p-10 post-inner">
                <div class="py-10 text-right">
                </div>
                <div class="post-body table-post">

                    <div class="tabs mt-2">
                        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                          <li class="nav-item" *ngFor="let trancTab of transactionTabs;let i = index">
                            <button
                              class="nav-link"
                              [ngClass]="{ 'active nav-active': tabId == i +1 }"
                              id="pills-{{trancTab | lowercase}}-tab"
                              data-toggle="pill"
                              (click)="tab(i + 1)"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              <span class="nav-pill-text">{{trancTab}}</span>
                            </button>
                          </li>
                          <!-- <li class="nav-item">
                            <button
                              class="nav-link"
                              [ngClass]="{ 'active nav-active': tabId == 2 }"
                              id="pills-profile-tab"
                              data-toggle="pill"
                              (click)="tab(2)"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <span class="nav-pill-text">DC</span>
                            </button>
                          </li>
                          <li class="nav-item">
                            <button
                              class="nav-link"
                              [ngClass]="{ 'active nav-active': tabId == 3 }"
                              id="pills-profile-tab"
                              data-toggle="pill"
                              (click)="tab(3)"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              <span class="nav-pill-text">Yearly</span>
                            </button>
                          </li> -->
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                          <div
                            class="tab-pane fade"
                            [ngClass]="{ 'show active': tabId == 1 }"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          ></div>
                        </div>
                    </div>


                    <div class="table-responsive">
                        <table class="table  table-nowrap table-align-middle">
                            <thead class="table-header">
                                <tr>
                                    <th *ngFor="let item of tHeaders;let i=index;">
                                        <div class="p-d-block p-jc-between p-ai-center">
                                            {{item?.header}}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="noDataFound">
                                    <td class="post_details_inner text-center" colspan="100">
                                        <div class="post-inner">
                                            <div class="post-body no-data">
                                                <p class="mb-0 font-weight-bold text-secondary">No Data Found..!!</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let item of transactionsList;let i=index;">
                                    <td *ngFor="let h of tHeaders" [ngClass]="{'text-center': h?.key == 'isLive'}">
                                         <ng-container>
                                            <span *ngIf="h.key != 'sNo' && h.key != 'stationName' && h.key != 'city'">{{getColValue(item[h?.key])}}</span>
                                            <span *ngIf="h.key == 'sNo'">{{i+1}}</span>
                                            <span *ngIf="h.key == 'stationName'">{{item?.evStationData?.name}}</span>
                                            <span *ngIf="h.key == 'city'">{{item?.evStationData?.postalAddress?.fullAddress}}</span>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>