import { Injectable } from '@angular/core';
import { AlertInfo } from '../enums/alert-info';
import { HttpMethod } from '../enums/http-handler.enum';
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { SweetalertService } from './sweetalert.service';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiService: ApiService, private appService: AppService,
     private alertService: SweetalertService,
    private spinner: NgxSpinnerService
  ) { }

  apiHandler(methodType: any, url: string, requestObj: any) {
    switch (methodType) {
      case HttpMethod.POST:
        return this.apiService.commonPostHandler(url, requestObj);
      case HttpMethod.LOGIN_POST:
        return this.apiService.commonLoginHandler(url, requestObj);
      case HttpMethod.PUT:
        return this.apiService.commonPutHandler(url, requestObj);
      case HttpMethod.DELETE:
        return this.apiService.commonDeleteHandler(url);
      case HttpMethod.GET:
        return this.apiService.commonGetHandler(url);
      case HttpMethod.FORMDATA_POST:
        return this.apiService.commonPostFormDataHandler(url,requestObj);
      case HttpMethod.FORMDATA_PUT:
        return this.apiService.commonPutFormDataHandler(url,requestObj);
    }
  }
  // common Post Api need to use in all screens
  commonApiCall(url, methodType, requestObj, callBack) {
    this.spinner.show();
    // console.log(url);
    this.apiHandler(methodType, url, requestObj).subscribe(
      (res) => {
        // if (
        //   !this.appService.checkNullOrUndefined(res) &&
        //   res.hasOwnProperty('response')
        // ) {
          if (res.hasOwnProperty('status') && res.status == 200) {
        //     if (methodType != HttpMethod.GET) {
              this.getMessages(res, AlertInfo.SUCCESS);
        //     }
        this.spinner.hide();
        callBack(res, true);
          } else if (res.hasOwnProperty('status') && res.status != 200) {
            this.getMessages(res, AlertInfo.WARNING);
            this.spinner.hide();
            callBack(res, false);
          }
        // }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        // console.log(error);

        callBack(error, false);
        this.alertService.showMessage(AlertInfo.ERROR, error.error.message);
      }
    );
  }

  private getMessages(res: any, type) {
    if (
      !this.appService.checkNullOrUndefined(res.message) &&
      res.message != ''
    ) {
      this.alertService.showMessage(type, res.message);
    }
  }
}
