<!-- <section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <h1 class="text-left1">EV</h1>
                <h2 class="text-left2">EvPitstop Admin</h2>
                <div class="right-arrow"></div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 ">
                <div class="card login-card">
                    <div class="card-body">
                        <h1 class="fw-bold card-title text-center">Login</h1>
                        <p class="fw-bold card-text text-center">Lorem Ipsum is simply dummy text of the</p>
                        <form action="" class="row mt-3" [formGroup]="postForm">
                            <div class="form-group mb-4">
                                <label class="form-control-label text-light">Email <sup>*</sup></label>
                                <input class="form-control" type="email" placeholder="Enter Email" maxlength=50 #email formControlName="email" data-rule="minlen:4" data-msg="Please enter at least 4 chars" autocomplete="off">

                            </div>
                            <div div class="form-group">
                                <label class="form-control-label text-light">Password <sup>*</sup></label>
                                <div class="position-relative">
                                    <input type="password" maxlength="20" class="form-control" id="userpassword" formControlName="password" name="password" placeholder="Enter Password">
                                </div>
                            </div>
                            <div class="mt-3">
                                <button type="submit" class="btn btn-block btn-light login-btn text-light btn-md font-weight-medium auth-form-btn" (click)="onSubmit()" href="javascript:void(0)">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

 -->

<section>
    <form action="" autocomplete="off" [formGroup]="postForm">
        <div class="left-content mr-3">
            <img class="fill" src="assets/img/Fill.png" alt="">
            <img class="d-block groups-img" src="assets/img/group.png" alt="">
            <img class="d-block down-fill" src="assets/img/Fill.png" alt="">
        </div>
        <div class="right-content flex-fill">
            <div class="content">
                <div class="login-details">
                    <h4>Sign-In</h4>
                    <span class="lorem">Lorem ipsum dolor sit, amet consectetur adipisicing</span>
                    <div class="form-controls">
                        <div class="form-group mt-3">
                            <input class="form-control" formControlName="email" placeholder=" " id="email" type="email" name="email" [ngClass]="{ 'is-invalid': submitted && postForm.get('email').errors }" />
                            <label for="email">Email<sup>*</sup></label>
                            <div *ngIf="submitted && postForm.get('email').errors" class="invalid-feedback">
                                <div *ngIf="postForm.get('email').errors.required">Email is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <input class="form-control" autocomplete="off" type="password" formControlName="password" id="password" name="password" placeholder=" " />
                            <label for="password">Password<sup>*</sup></label>
                            <div *ngIf="submitted && postForm.get('password').errors" class="invalid-feedback">
                                <div *ngIf="postForm.get('password').errors.required">Password is required.
                                </div>
                                <div *ngIf="postForm.get('password').errors.pattern">Password must contain at least: one uppercase letter, one lowercase letter, one digit, and any special character.</div>
                            </div>
                        </div>
                        <div class="block w-100 mt-2 mb-3">
                            <a class="text-right forgot-password" href="javascript:void(0)" [routerLink]="'/'+ redirectUrls.ADMIN_FORGOT">
                          Forgot password?
                      </a>
                        </div>
                        <div class="actions ">
                            <button type="submit" class="btn btn-primary" (click)="onSubmit()">Sign In</button>
                        </div>
                    </div>
                </div>
                <p class="mt-5 text-center">Term and Condition <a href="javascript:void(0)" [routerLink]="'/'+ redirectUrls.ADMIN_TERMS">Click here</a></p>
            </div>
        </div>
    </form>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-light" id="exampleModalLongTitle">Email Verification</h5>
                    <button type="button" class="close btn btn-danger btn-icon btn-sm" data-dismiss="modal" (click)="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <form action="" [formGroup]="verifyForm">
                        <div class="form-group col-p">
                            <input type="text" class="form-control" formControlName="emailVerificationCode" name="emailVerificationCode" id="emailVerificationCode" placeholder="Enter OTP" aria-label="emailVerificationCode" #otp [ngClass]="{ 'is-invalid': otpSubmitted && verifyForm.get('emailVerificationCode').errors }">
                            <div *ngIf="otpSubmitted && verifyForm.get('emailVerificationCode').errors" class="invalid-feedback">
                                <div *ngIf="verifyForm.get('emailVerificationCode').errors.required">Otp is required.
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary add-btn" (click)="onSubmitCode()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</section>
