<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light">{{redirectUrls.ADMIN_DASHBOARD_LABEL}}</h2>
            </div>
            <div class="col-sm-6 top-right-content text-right d-flex align-items-center justify-content-end">
                <div class="search-box">
                    <input type="text" class="form-control" [formControl]="searchKeyword" placeholder="Search EV Stations" (click)="toggleOverlay()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayScrollStrategy]="overlay.scrollStrategies.reposition()" (backdropClick)="toggleOverlay()">
                        <ul class="example-list list-unstyled profile-redirections p-0">
                            <li class="pl-3 pr-3 pb-3" *ngIf="!searchedStationsList">Search for stations</li>
                            <li class="pl-3 link pr-3 pb-3" *ngFor="let station of searchedStationsList">
                                <!-- <a routerLink="/admin/stations/{{station.id}}"> -->
                                <!-- <a routerLink="/admin/stations"> -->
                                <a>
                                    <span>
                                        <img [src]="station.picture" />
                                        {{station.name}}
                                    </span>
                                </a>
                            </li>
                            <li class="pl-3" *ngIf="searchedStationsList?.length === 0">
                                <span>No stations found.</span>
                            </li>
                            <!-- <li>Item 3</li> -->
                        </ul>
                    </ng-template>
                </div>
                <button class="btn btn-sm btn-add" [routerLink]="'/' + redirectUrls.ADMIN + '/' + redirectUrls.ADMIN_NOTIFICATIONS"><i class="fa fa-bell"></i><sup>*</sup></button>
                <button class="btn btn-sm btn-add" [routerLink]="'/' +redirectUrls.ADMIN + '/' + redirectUrls.ADMIN_LANGUAGES"><i class="fa fa-cog"></i></button>
                <button class="btn btn-sm btn-add p-0" [popover]="profileTemplate" [placement]="'bottom'" container="body" outsideClick="true" [containerClass]="'profile-popover'">
                    <img [src]="currentUser?.pictures ? currentUser?.pictures : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'" alt="">
                </button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row row-m">
        <div class="col-md-8 left-panel">
            <div class="row">
                <div class="col-md-4">
                    <div class="count-card">
                        <div class="col-md-12 count-card-body active-stations">
                            <label for="">Active Stations</label>
                            <!-- <img class="col-md-6" src="assets/img/Rectangle 874.png"> -->
                            <h2>
                                {{countData[1]?.approvedEvStationCount}}
                            </h2>
                            <div class="main-design-box"></div>
                            <div class="sub-design-box"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="count-card">
                        <div class="col-md-12 count-card-body inactive-stations">
                            <label for="">InActive Stations</label>
                            <!-- <img class="col-md-6 " src="assets/img/Rectangle 876.png"> -->
                            <h2>
                                {{countData[2]?.rejectedEvStationCount}}
                            </h2>
                            <div class="main-design-box"></div>
                            <div class="sub-design-box"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="count-card">
                        <div class="col-md-12 count-card-body requests-box">
                            <label for="">Requests</label>
                            <!-- <img class="col-md-6 " src="assets/img/Rectangle 876 (1).png"> -->
                            <h2>
                                {{countData[0]?.pendingEvStationCount}}
                            </h2>
                            <div class="main-design-box"></div>
                            <div class="sub-design-box"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div class="map-card line-card">
                    <!-- <apx-chart *ngIf="showStationsGraph" [series]="stationsStausChart.series" [chart]="stationsStausChart.chart" [dataLabels]="stationsStausChart.dataLabels" [grid]="stationsStausChart.grid" [legend]="stationsStausChart.legent" [stroke]="stationsStausChart.stroke"
                        [title]="stationsStausChart.title" [xaxis]="stationsStausChart.xaxis" [yaxis]="stationsStausChart.yaxis" [colors]="stationsStausChart.fill.colors"></apx-chart> -->
                    <apx-chart *ngIf="showStationsGraph" [series]="stationsStausChart.series" [chart]="stationsStausChart.chart" [dataLabels]="stationsStausChart.dataLabels" [grid]="stationsStausChart.grid" [legend]="stationsStausChart.legent" [stroke]="stationsStausChart.stroke"
                        [title]="stationsStausChart.title" [xaxis]="stationsStausChart.xaxis" [yaxis]="stationsStausChart.yaxis" [colors]="stationsStausChart.fill.colors"></apx-chart>
                </div>
            </div>
        </div>
        <div class="col-md-4 right-panel">
            <div class="mt-3 count-card">
                <div class="col-md-12 count-card-body">
                    <label class="" for="">2022 Year Payments</label>
                    <h2>
                        &#x20B9;{{total}}/-
                    </h2>
                </div>
            </div>
            <div class="mt-3 mb-3 payments-card">
                <ng-container *ngIf="showPaymentsGraph">
                    <apx-chart [fill]="chartOptions.fill" [xaxis]="chartOptions.xaxis" [yaxis]="chartOptions.yaxis" [series]="chartOptions.series" [plotOptions]="{bar: { borderRadius: 8}}" [chart]="chartOptions.chart" [labels]="['Jan', 'Feb',  'Mar',  'Apr',  'May']" [title]="chartOptions.title"></apx-chart>
                </ng-container>
                <div class="best-profit-stations-list p-3">
                    <div class="title">
                        <h4>Best Profit Gaining Stations</h4>
                    </div>
                    <div class="best-stations-list list mt-4">
                        <perfect-scrollbar>
                            <div class="profit-station d-flex justify-content-between align-items-center" *ngFor="let station of profitableStationsList">
                                <span class="theme-bg circle"></span>
                                <h6 class="flex-fill ml-3 me-1">{{station.evStationName}}</h6>
                                <span>&#x20B9;{{station.total}}</span>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<ng-template #profileTemplate>
    <div class="d-flex justify-content-center flex-column profile-info">
        <img class="theme-border" [src]="currentUser?.pictures ? currentUser?.pictures : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'" alt="">
        <span>{{currentUser?.email}}</span>
    </div>
    <hr />
    <ul class="list-unstyled profile-redirections p-0">
        <li>
            <a [routerLink]="'/' + redirectUrls.ADMIN + redirectUrls.ADMIN_PROFILE">
                <span>
                    <i class="fa fa-user-alt"></i> Profile
                </span>
                <i class="fa fa-chevron-right"></i></a>
        </li>
        <li>
            <a [routerLink]="'/' + redirectUrls.ADMIN + redirectUrls.ADMIN_TRANSACTIONS">
                <span>
                    <i class="fa fa-receipt"></i> {{redirectUrls.ADMIN_TRANSACTIONS}}
                </span>
                <i class="fa fa-chevron-right"></i></a>
        </li>
        <li>
            <a [routerLink]="'/' + redirectUrls.ADMIN + redirectUrls.ADMIN_NOTIFICATIONS">
                <span>
                    <i class="fa fa-bell"></i> {{redirectUrls.ADMIN_NOTIFICATIONS_LABEL}}
                </span>
                <i class="fa fa-chevron-right"></i></a>
        </li>
        <li>
            <a href="https://evpitstop.in/Terms" target="_blank">
                <span>
                    <i class="fa fa-exclamation-circle"></i> {{redirectUrls.ADMIN_TERMS_LABEL}}
                </span>
                <i class="fa fa-chevron-right"></i></a>
        </li>
    </ul>
</ng-template>