import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LOGIN } from "../core/configurations/url";
import { AlertInfo } from "../core/enums/alert-info";
import { CommonEnum } from "../core/enums/common.enum";
import { HttpMethod } from "../core/enums/http-handler.enum";
import { RedirectRoutes } from "../core/enums/route.enum";
import { AppService } from "../core/services/app.service";
import { CommonService } from "../core/services/common.service";
import { SweetalertService } from "../core/services/sweetalert.service";
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  postForm: FormGroup;
  verifyForm: FormGroup;
  otpSubmitted: boolean;
  processing: boolean;
  redirectUrls = RedirectRoutes;
  submitted: boolean;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private appService: AppService,
    private sweetAlert: SweetalertService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.verifyOtpCreateForm();
  }
  createForm() {
    this.postForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  onSubmit() {
    let url = LOGIN.TOKEN;
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      {...this.postForm.value, deviceId: this.appService.firebaseToken},
      (result, statusFlag) => {
        // console.log("res", result);
        if (statusFlag) {
    this.submitted = false;
          if (result) {
              let obj ={
                ...result.responseData,
                token: result.token
              }
              this.appService.setUserDetails(obj);
              this.router.navigate([`/admin/dashboard`]);
          }
        } else{
          // console.log("falae", result);
          if(result.error.message == 'Please verify your email'){
            this.requestOtp();
          }
        }
        // this.spinner.hide();
      }
    );
  }
  requestOtp(){
    let url = LOGIN.forgotPassword;
    let obj = {
      "email": this.postForm.value.email
  }
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      obj,
      (res, statusFlag) => {
        if (statusFlag) {
          this.verifyOtpModel();
        }
      }
    );
  }
  verifyOtpCreateForm(){
    this.verifyForm = this.fb.group({
      email: [''],
      emailVerificationCode: ['', Validators.required]
  });
  this.otpSubmitted = false;
  }

  verifyOtpModel(){
    $('#exampleModalCenter').modal('show');
    this.verifyForm.get('email').patchValue(this.postForm.value.email);
  }
  closeModal(){
    $('#exampleModalCenter').modal('hide');
    this.verifyOtpCreateForm();
  }
  onSubmitCode(){
    let url = LOGIN.verifyEmail;
    this.otpSubmitted = true;
    if(this.verifyForm.invalid){
      this.sweetAlert.showMessage(AlertInfo.ERROR, CommonEnum.MANDOTORY_FIELDS);
      return;
    }
    this.processing = true;
    this.commonService.commonApiCall(
      url,
      HttpMethod.LOGIN_POST,
      this.verifyForm.value,
      (res, statusFlag) => {
        if (statusFlag) {
          this.otpSubmitted = false;
        }
        this.closeModal();
        this.processing = false;
      }
    );
  }
}
