export enum RedirectRoutes {
  HOME = '',
  HOMELABEL = 'EV',
  LOGIN = 'login',
  LOGINLABEL = 'Login',
  ADMIN = 'admin/',
  ADMIN_DASHBOARD = 'dashboard',
  ADMIN_DASHBOARD_LABEL = 'Dashboard',
  ADMIN_NOTFOUND = 'not-found',
  ADMIN_NOTFOUND_LABEL = 'Not Found',
  ADMIN_TERMS = 'terms-and-conditions',
  ADMIN_TERMS_LABEL = 'Terms And Conditions',
  ADMIN_FORGOT = 'forgot-password',
  ADMIN_FORGOT_LABEL = 'Forgot Password',
  ADMIN_RESET = 'reset-password',
  ADMIN_RESET_LABEL = 'Reset Password',
  ADMIN_PROFILE = 'profile',
  ADMIN_PROFILE_LABEL = 'Profile',
  ADMIN_USERS = 'users',
  ADMIN_USERS_LABEL = 'Super Admin Users',
  ADMIN_STATION_USERS_LABEL = 'Station Admin Users',
  ADMIN_REQUESTS = 'requests',
  ADMIN_REQUESTS_LABEL = 'Requests',
  ADMIN_REQUEST_COMPONENT_LABEL = 'EV station admin requests',
  ADMIN_REQUEST_COMPONENT_POST_LABEL = 'EV station admin requests',
  ADMIN_LOCATIONS = 'locations',
  ADMIN_LOCATIONS_LABEL = 'Locations',
  ADMIN_VEHICLES = 'vehicles',
  ADMIN_VEHICLES_LABEL = 'Vehicles',
  ADMIN_STATIONS = 'stations',
  ADMIN_STATIONS_LABEL = 'Stations',
  ADMIN_STATIONS_ADMIN_LABEL = 'Station Admins',
  ADMIN_STATIONS_CATEGORY = 'station-category',
  ADMIN_STATIONS_CATEGORY_LABEL = 'Station Category',
  ADMIN_STATIONS_CHARGECOST = 'station-chargecost',
  ADMIN_STATIONS_CHARGECOST_LABEL = 'Station Chargecost',
  ADMIN_LANGUAGES = 'settings',
  ADMIN_LANGUAGES_LABEL = 'List Of Languages',
  ADMIN_SETTINGS_LABEL = 'Settings',
  ADMIN_TRANSACTIONS = 'Transactions',
  ADMIN_TRANSACTIONS_LABEL = 'Transactions',
  ADMIN_NOTIFICATIONS = 'notifications',
  ADMIN_NOTIFICATIONS_LABEL = 'Notifications',

  ADMIN_STATIONS_PORT = 'station-port',
  ADMIN_STATIONS_PORT_LABEL = 'Station Port',
  ADMIN_STATIONS_PROVIDER = 'station-provider',
  ADMIN_STATIONS_PROVIDER_LABEL = 'Station Provider',
  ADMIN_COUNTRY = 'country',
  ADMIN_COUNTRY_LABEL = 'Country',
  ADMIN_STATE = 'state',
  ADMIN_STATE_LABEL = 'State',
  ADMIN_CITY_CATEGORY = 'city-category',
  ADMIN_CITY_CATEGORY_LABEL = 'City Category',
  ADMIN_CITY = 'city',
  ADMIN_CITY_LABEL = 'City',
  ADMIN_DISTRICT = 'district',
  ADMIN_DISTRICT_LABEL = 'District',
  ADMIN_POSTAL_ADDRESS = 'postal-address',
  ADMIN_POSTAL_ADDRESS_LABEL = 'Postal Address',
  ADMIN_VEHICLE_CHARGE_CAPACITY = 'vehicle-charge-capacity',
  ADMIN_VEHICLE_CHARGE_CAPACITY_LABEL = 'Vehicle Charge Capacity',
  ADMIN_VEHICLE_MANUFACTURE = 'vehicle-manufacture',
  ADMIN_VEHICLE_MANUFACTURE_LABEL = 'Vehicle Manufacture',
  ADMIN_VEHICLE_MODEL = 'vehicle-model',
  ADMIN_VEHICLE_MODEL_LABEL = 'Vehicle Model',
  ADMIN_VEHICLE_PORT = 'vehicle',
  ADMIN_VEHICLE_PORT_LABEL = 'Vehicle Port',
  ADMIN_REVIEW_MANAGEMENT = 'reviews',
  ADMIN_REVIEW_MANAGEMENT_LABEL = 'Reviews',
  ADMIN_VERSION_MANAGEMENT = 'version',
  ADMIN_VERSION_MANAGEMENT_LABEL = 'Version',
  ADMIN_LOGOUT_LABEL = 'LogOut',
}
