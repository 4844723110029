<!-- <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 pt-3">
            <div class="col-sm-6">
                <h2 class="m-0 text-light fs-48">{{redirectUrls.ADMIN_VEHICLES_LABEL}}</h2>
            </div>
        </div>
    </div>
</div> -->
<div class="container-fluid">
    <div class="row row-m">
        <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                    <button class="step-trigger">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 1}">1</span>
                    </button>
                </div>
                <div class="line" [ngClass]="{'line-active': stepId > 1}"></div>
                <div class="step" data-target="#test-l-2">
                    <button class="step-trigger">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 2}">2</span>
                    </button>
                </div>
                <div class="line" [ngClass]="{'line-active': stepId > 2}"></div>
                <div class="step" data-target="#test-l-3">
                    <button class="step-trigger">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 3}">3</span>
                    </button>
                </div>
                <div class="line" [ngClass]="{'line-active': stepId > 3}"></div>
                <div class="step" data-target="#test-l-4">
                    <button class="step-trigger">
                      <span class="bs-stepper-circle" [ngClass]="{'bs-stepper-active': stepId >= 4}">4</span>
                    </button>
                </div>
            </div>
            <div class="bs-stepper-content">
                <div id="test-l-1" class="content">
                    <h3 class="m-0 text-light" *ngIf="stepId == 1">{{redirectUrls.ADMIN_VEHICLE_MANUFACTURE_LABEL}}</h3>

                    <form (ngSubmit)="onSubmit()" [formGroup]="vehicleManfactureForm">
                        <div class="row">
                            <div class="form-group col-lg-4 col-p">
                                <!-- <label for="name">Brnad Name<sup>*</sup></label> -->
                                <input type="text" class="form-control" #brnadName maxlength="60" formControlName="name" name="name" id="name" placeholder="Manufacture Name*" aria-label="name" [ngClass]="{ 'is-invalid': submitted && vehicleManfactureForm.get('name').errors }">
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                  {{brnadName.value.length}}/60
                              </small>
                                <div *ngIf="submitted && vehicleManfactureForm.get('name').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleManfactureForm.get('name').errors.required">Brand Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <!-- <label for="organizationName">Organization Name<sup>*</sup></label> -->
                                <input type="text" class="form-control" formControlName="organizationName" #organizationName maxlength="60" name="organizationName" id="organizationName" placeholder="Organization Name*" aria-label="name" [ngClass]="{ 'is-invalid': submitted && vehicleManfactureForm.get('organizationName').errors }">
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                  {{organizationName.value.length}}/60
                              </small>
                                <div *ngIf="submitted && vehicleManfactureForm.get('organizationName').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleManfactureForm.get('organizationName').errors.required">Organization Name is required.
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-4 col-p">
                                    <div class="form-check form-switch form-control">
                                        <input class="form-check-input" type="checkbox"
                                            id="flexSwitchCheckChecked" formControlName="status">
                                        <label class="form-check-label" for="flexSwitchCheckChecked">
                                            Yes/No<sup>*</sup></label>
                                    </div>
                                </div> -->
                            <div class="form-group col-lg-8 col-p">
                                <!-- <label for="description">Description<sup>*</sup></label> -->
                                <textarea type="text" class="form-control" #description maxlength="100" formControlName="description" name="description" id="description" placeholder="Description" aria-label="description" [ngClass]="{ 'is-invalid': submitted && vehicleManfactureForm.get('description').errors }"></textarea>
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                        {{description.value.length}}/100
                                    </small>
                                <div *ngIf="submitted && vehicleManfactureForm.get('description').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleManfactureForm.get('description').errors.required">
                                        Description is required.
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                    <!-- <button class="btn btn-danger btn-sm border float-right add-btn" (click)="onCancel()"
                        ><span>Cancel</span></button> -->
                    <!-- <button class="btn btn-danger btn-sm border float-right add-btn" (click)="next(2)"><span>Next</span></button> -->
                    <button class="btn btn-danger btn-sm border float-right add-btn" (click)="onSubmitManufacture()"><span>Next</span></button>
                </div>
                <div id="test-l-2" class="content">
                    <h3 class="m-0 text-light" *ngIf="stepId == 2">{{redirectUrls.ADMIN_VEHICLE_CHARGE_CAPACITY_LABEL}}</h3>
                    <form [formGroup]="vehicleCapacityForm">
                        <div class="row">
                            <div class="form-group col-lg-4 col-p">
                                <!-- <label for="chargeCapacityInKWH">Charge Capacity In KWH<sup>*</sup></label> -->
                                <input type="text" maxlength="10" #cahrgekmp class="form-control" formControlName="chargeCapacityInKWH" chargeCapacityInKWH="chargeCapacityInKWH" id="chargeCapacityInKWH" placeholder=" Charge Capacity In KWH" aria-label="chargeCapacityInKWH" [ngClass]="{ 'is-invalid': submitted && vehicleCapacityForm.get('chargeCapacityInKWH').errors }">
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                  {{cahrgekmp.value.length}}/10
                              </small>
                                <div *ngIf="submitted && vehicleCapacityForm.get('chargeCapacityInKWH').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleCapacityForm.get('chargeCapacityInKWH').errors.required">Charge Capacity In KWH is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <select class="form-select" formControlName="chargingType" [ngClass]="{ 'is-invalid': submitted && vehicleCapacityForm.get('chargingType').errors }">
                                     <option value="" disabled selected>-- Select Electricity Type* --</option>
                                     <option *ngFor="let item of elecityList" [value]="item">
                                         {{item}}
                                     </option>
                                 </select>
                                <div *ngIf="submitted && vehicleCapacityForm.get('chargingType').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleCapacityForm.get('chargingType').errors.required">Electricity Type is required.
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-4 col-p">
                            <label for="stat">Status<sup>*</sup></label>
                            <div class="form-check form-switch form-control">
                                <input class="form-check-input" type="checkbox"
                                    id="flexSwitchCheckChecked" formControlName="status">
                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                    Yes/No</label>
                            </div>
                        </div> -->
                            <div class="form-group col-lg-8 col-p">
                                <!-- <label for="description">Description<sup>*</sup></label> -->
                                <textarea type="text" maxlength="100" #cahrgedescription class="form-control" formControlName="description" name="description" id="description" placeholder=" Description" aria-label="description" [ngClass]="{ 'is-invalid': submitted && vehicleCapacityForm.get('description').errors }"></textarea>
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                {{cahrgedescription.value.length}}/100
                            </small>
                                <div *ngIf="submitted && vehicleCapacityForm.get('description').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleCapacityForm.get('description').errors.required">
                                        Description is required.
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                    <!-- <button class="btn btn-danger btn-sm border float-right add-btn" (click)="next(3)"><span>Next</span></button> -->
                    <button class="btn btn-danger btn-sm border float-right add-btn" (click)="onSubmitCapacity()"><span>Next</span></button>
                </div>
                <div id="test-l-3" class="content">
                    <h3 class="m-0 text-light" *ngIf="stepId == 3">{{redirectUrls.ADMIN_VEHICLE_PORT_LABEL}}</h3>
                    <form [formGroup]="vehiclePortForm">
                        <div class="row">
                            <form [formGroup]="vehiclePortForm">
                                <div class="row">
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="name">Name<sup>*</sup></label> -->
                                        <input type="text" class="form-control" formControlName="name" name="name" id="name" placeholder="Port Name" aria-label="name" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('name').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('name').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('name').errors.required">Name is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="maxPower">Max Power<sup>*</sup></label> -->
                                        <input type="text" class="form-control" formControlName="maxPower" name="maxPower" id="maxPower" placeholder=" Max Power" aria-label="maxPower" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('maxPower').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('maxPower').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('maxPower').errors.required">Max Power is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="maxVolts">Max Volts<sup>*</sup></label> -->
                                        <input type="text" class="form-control" formControlName="maxVolts" name="maxVolts" id="maxVolts" placeholder=" Max Volts" aria-label="maxVolts" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('maxVolts').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('maxVolts').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('maxVolts').errors.required">Max Volts is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="maxAmps">Max Amps<sup>*</sup></label> -->
                                        <input type="text" class="form-control" formControlName="maxAmps" name="maxAmps" id="maxAmps" placeholder=" Max Amps" aria-label="maxAmps" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('maxAmps').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('maxAmps').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('maxAmps').errors.required">Max Amps is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="level">Level<sup>*</sup></label> -->
                                        <input type="text" numberOnly class="form-control" formControlName="level" name="level" id="level" placeholder=" Level" aria-label="level" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('level').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('level').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('level').errors.required">Level is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="pins">Pins<sup>*</sup></label> -->
                                        <input type="text" numberOnly class="form-control" formControlName="pins" name="pins" id="pins" placeholder=" Pins" aria-label="pins" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('pins').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('pins').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('pins').errors.required">Pins is required.
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="form-group col-lg-4 col-p">
                                        <input type="text" class="form-control" formControlName="lockSupport" name="lockSupport" id="lockSupport" placeholder=" Lock Support" aria-label="lockSupport" [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('lockSupport').errors }">
                                        <div *ngIf="submitted && vehiclePortForm.get('lockSupport').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('lockSupport').errors.required">Lock Support is required.
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group col-lg-4 col-p">
                                        <!-- <label for="level">&nbsp;Lock Support</label> -->
                                        <div class="form-control">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="lockSupport" name="lockSupport" formControlName="lockSupport">
                                                <label class="form-check-label pl-4" for="lockSupport">Lock Support</label>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="form-group col-lg-4 col-p">
                                            <label for="stat">Status<sup>*</sup></label>
                                            <div class="form-check form-switch form-control">
                                                <input class="form-check-input" type="checkbox"
                                                    id="flexSwitchCheckChecked" formControlName="status">
                                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                                    Yes/No</label>
                                            </div>
                                        </div> -->
                                    <div class="form-group col-lg-8 col-p">
                                        <!-- <label for="description">Description<sup>*</sup></label> -->
                                        <textarea type="text" class="form-control" formControlName="description" name="description" id="description" placeholder=" Description" aria-label="description" maxlength="50" #description [ngClass]="{ 'is-invalid': submitted && vehiclePortForm.get('description').errors }"></textarea>
                                        <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                                {{description.value.length}}/50
                                            </small>
                                        <div *ngIf="submitted && vehiclePortForm.get('description').errors" class="invalid-feedback">
                                            <div *ngIf="vehiclePortForm.get('description').errors.required">
                                                Description is required.
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <hr class="edit-last-separator-hr" />
                                <!-- {{vehiclePortForm.value | json}} -->
                            </div>
                        </div>
                    </form>
                    <!-- <button class="btn btn-danger btn-sm border float-right add-btn" (click)="next(4)"><span>Next</span></button> -->
                    <button class="btn btn-danger btn-sm border float-right add-btn" (click)="onSubmitPort()"><span>Next</span></button>
                </div>
                <div id="test-l-4" class="content">
                    <h3 class="m-0 text-light" *ngIf="stepId == 4">{{redirectUrls.ADMIN_VEHICLE_MODEL_LABEL}}</h3>
                    <form [formGroup]="vehicleModelForm">
                        <div class="row">
                            <div class="form-group col-lg-4 col-p">
                                <!-- <label for="name">Name<sup>*</sup></label> -->
                                <input type="text" class="form-control" formControlName="name" name="name" id="name" placeholder="Model Name" aria-label="name" [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('name').errors }">
                                <div *ngIf="submitted && vehicleModelForm.get('name').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('name').errors.required">Name is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <input type="text" class="form-control" formControlName="modalNumber" name="modalNumber" id="modalNumber" placeholder="Model Number" aria-label="modalNumber" [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('modalNumber').errors }">
                                <div *ngIf="submitted && vehicleModelForm.get('modalNumber').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('modalNumber').errors.required">Model Number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <input type="text" class="form-control" formControlName="batteryCapacityInKWH" name="batteryCapacityInKWH" id="batteryCapacityInKWH" placeholder=" Battery Capacity In KWH" aria-label="batteryCapacityInKWH" [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('batteryCapacityInKWH').errors }">
                                <div *ngIf="submitted && vehicleModelForm.get('batteryCapacityInKWH').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('batteryCapacityInKWH').errors.required">Battery Capacity In KWH is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <input type="text" class="form-control" numberOnly maxlength="4" formControlName="modelYear" name="modelYear" id="modelYear" placeholder="Year Of Model" aria-label="modelYear" [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('modelYear').errors }">
                                <div *ngIf="submitted && vehicleModelForm.get('modelYear').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('modelYear').errors.required">Year Of Model is required.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4 col-p">
                                <input type="text" class="form-control" formControlName="variant" name="variant" id="variant" placeholder=" Variant (Platinum / Basic)" aria-label="variant" [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('variant').errors }">
                                <div *ngIf="submitted && vehicleModelForm.get('variant').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('variant').errors.required">Variant (Platinum / Basic) is required.
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group col-lg-4 col-p">
                            <label for="name">Vehicle Manufactur<sup>*</sup></label>
                            <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="vehicleManufacturId">
                           <option value="" selected>-- Select --</option>
                           <option *ngFor="let item of vehicleManufacturList" [value]="item?.vehicleManufacturId">
                               {{item?.name}}
                           </option>
                       </select> <div *ngIf="submitted && vehicleModelForm.get('vehicleManufacturId').errors" class="invalid-feedback">
                                <div *ngIf="vehicleModelForm.get('vehicleManufacturId').errors.required">Vehicle Manufactur is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4 col-p">
                            <label for="vehiclePortId">Vehicle Port<sup>*</sup></label>
                            <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="vehiclePortId">
                           <option value="" selected>-- Select --</option>
                           <option *ngFor="let item of vehiclePortList" [value]="item?.vehiclePortId">
                               {{item?.name}}
                           </option>
                       </select> <div *ngIf="submitted && vehicleModelForm.get('vehiclePortId').errors" class="invalid-feedback">
                                <div *ngIf="vehicleModelForm.get('vehiclePortId').errors.required">Vehicle Port is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4 col-p">
                            <label for="name">Vehicle Charge Capacity<sup>*</sup></label>
                            <select class="form-select" id="lan" aria-describedby="lanLabel" formControlName="vehicleChargeCapacityId">
                           <option value="" selected>-- Select --</option>
                           <option *ngFor="let item of chargeCapacityList" [value]="item?.vehicleChargeCapacityId">
                               {{item?.chargeCapacityInKWH}}
                           </option>
                       </select> <div *ngIf="submitted && vehicleModelForm.get('vehicleChargeCapacityId').errors" class="invalid-feedback">
                                <div *ngIf="vehicleModelForm.get('vehicleChargeCapacityId').errors.required">Vehicle Charge Capacity is required.
                                </div>
                            </div>
                        </div> -->
                            <!-- <div class="form-group col-lg-4 col-p">
                            <label for="stat">Status<sup>*</sup></label>
                            <div class="form-check form-switch form-control">
                                <input class="form-check-input" type="checkbox"
                                    id="flexSwitchCheckChecked" formControlName="status">
                                <label class="form-check-label" for="flexSwitchCheckChecked">
                                    Yes/No</label>
                            </div>
                        </div> -->
                            <div class="form-group col-lg-8 col-p">
                                <!-- <label for="description">Description<sup>*</sup></label> -->
                                <textarea type="text" class="form-control" formControlName="description" name="description" id="description" placeholder=" Description" aria-label="description" maxlength="50" #description [ngClass]="{ 'is-invalid': submitted && vehicleModelForm.get('description').errors }"></textarea>
                                <small class="dis-inline lineH-1 float-right input-hint mb-0">
                                {{description.value.length}}/50
                            </small>
                                <div *ngIf="submitted && vehicleModelForm.get('description').errors" class="invalid-feedback">
                                    <div *ngIf="vehicleModelForm.get('description').errors.required">
                                        Description is required.
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>
                    <button class="btn btn-danger btn-sm border float-right add-btn" (click)="onSubmitModel()"><span>Submit</span></button>
                </div>
            </div>
        </div>


    </div>
</div>
