import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VEHICLE_CHARGE_CAPACITY_URLS } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { FormButtons } from '../core/enums/form-buttons.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
@Component({
  selector: 'app-vehicle-charge-capacity',
  templateUrl: './vehicle-charge-capacity.component.html',
  styleUrls: ['./vehicle-charge-capacity.component.scss']
})
export class VehicleChargeCapacityComponent implements OnInit {
  redirectUrls = RedirectRoutes;
  tHeaders: any = [
    {
      header: "Charge Capacity In KWH",
      key: "chargeCapacityInKWH"
    },
    {
      header: "Description",
      key: "description"
    },
    {
      header: "Status",
      key: "status"
    }];
  noDataFound: boolean;
  categoryList: any[] = [];
  formShow: boolean;
  submitted: boolean;
  postForm: FormGroup;
  buttonName: any;
  commonEnum = CommonEnum;
  statusList: any = [
    {
      val: true,
      label: 'Yes'
    },
    {
      val: false,
      label: 'No'
    }
  ];
  fileName1: any;
  processing: boolean;
  buttonType = FormButtons;
  currentUser: any;
  url: string | ArrayBuffer;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private sweetAlert: SweetalertService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.loadData();
  }

  //key val return
  getColValue(colValue) {
    if (Array.isArray(colValue)) {
      const arrayElements = [];
      colValue.forEach((ele) => {
        if (typeof ele !== 'object') {
          arrayElements.push(ele);
        }
      });
      return arrayElements.toString();
    }
    return colValue;
  }
  loadData() {
    const url = VEHICLE_CHARGE_CAPACITY_URLS.getAll;
    this.noDataFound = false;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      url ,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.categoryList = res.responseData;
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
  createForm() {
    this.postForm = this.fb.group({
      // vehicleChargeCapacityId: [0],
      _id: [this.currentUser._id],
        chargeCapacityInKWH: [''],
        status: true,
        description: ['',Validators.required],
      });
    this.buttonName = this.commonEnum.SAVE;
    this.submitted = false;
    this.url = null;
  }
  get formControls() { return this.postForm.controls; }
  statusChange(value){
    this.formControls.status.patchValue(value);
  }
  cancel() {
    this.addTask();
    this.createForm();
  }
  addTask() {
    this.formShow = !this.formShow;
  }
  update(item) {
    this.addTask();
    this.buttonName = this.commonEnum.UPDATE;
    if (item !== undefined) {
      this.postForm.patchValue(item);
      this.postForm.setControl('vehicleChargeCapacityId', new FormControl(item.vehicleChargeCapacityId));
    }
  }

  save(type?: any) {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = VEHICLE_CHARGE_CAPACITY_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.postForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.cancel();
            this.loadData();
          }
          this.processing = false;
        }
      );
  }
  deleteConfirm(row) {
    this.sweetAlert.confirmation(CommonEnum.DELETE, (result) => {
      if (result) {
        let url = VEHICLE_CHARGE_CAPACITY_URLS.deleteById;
        let obj ={ 'userId': this.currentUser._id,
        "vehicleChargeCapacityId": row.vehicleChargeCapacityId
         }
        this.commonService.commonApiCall(
          url,
          HttpMethod.POST,
          obj,
          (res, statusFlag) => {
            if (statusFlag) {
              this.loadData();
            }
          }
        );
      }
    });
  }

}
