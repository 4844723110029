import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRY_URLS, STATE_URLS } from '../core/configurations/url';
import { AlertInfo } from '../core/enums/alert-info';
import { CommonEnum } from '../core/enums/common.enum';
import { FormButtons } from '../core/enums/form-buttons.enum';
import { HttpMethod } from '../core/enums/http-handler.enum';
import { RedirectRoutes } from '../core/enums/route.enum';
import { AppService } from '../core/services/app.service';
import { CommonService } from '../core/services/common.service';
import { SweetalertService } from '../core/services/sweetalert.service';
@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit  {
  redirectUrls = RedirectRoutes;
  tHeaders: any = [
    {
      header: "Name",
      key: "name"
    },
    {
      header: "Short Name",
      key: "shortName"
    },
    {
      header: "Country",
      key: "country"
    }];
  noDataFound: boolean;
  categoryList: any[] = [];
  formShow: boolean;
  submitted: boolean;
  postForm: FormGroup;
  buttonName: any;
  commonEnum = CommonEnum;
  statusList: any = [
    {
      val: true,
      label: 'Yes'
    },
    {
      val: false,
      label: 'No'
    }
  ];
  fileName1: any;
  processing: boolean;
  buttonType = FormButtons;
  currentUser: any;
  url: string | ArrayBuffer;
  countryList: any = [];
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private sweetAlert: SweetalertService,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.appService.getUserDetails();
    this.createForm();
    this.loadData();
    this.countryData();
  }

  //key val return
  getColValue(colValue) {
    if (Array.isArray(colValue)) {
      const arrayElements = [];
      colValue.forEach((ele) => {
        if (typeof ele !== 'object') {
          arrayElements.push(ele);
        }
      });
      return arrayElements.toString();
    }
    return colValue;
  }
  loadData() {
    const url = STATE_URLS.getAll;
    this.noDataFound = false;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      url ,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.categoryList = res.responseData;
          if(!res.responseData.length){
            this.noDataFound = true;
          }
        }
      }
    );
  }
   countryData() {
    const url = COUNTRY_URLS.getAll;
    let obj ={ 'userId': this.currentUser._id}
    this.commonService.commonApiCall(
      url ,
      HttpMethod.POST,
      null,
      (res, statusFlag) => {
        if (statusFlag) {
          this.countryList = res.responseData;
        }
      }
    );
  }
  createForm() {
    this.postForm = this.fb.group({
      // stateId: [0],
        name: ['', [Validators.required, Validators.maxLength(50)]],
        userId: [this.currentUser._id],
        countryId: [''],
        // status: true,
        shortName: [''],
      });
    this.buttonName = this.commonEnum.SAVE;
    this.submitted = false;
    this.url = null;
  }
  get formControls() { return this.postForm.controls; }
  statusChange(value){
    this.formControls.status.patchValue(value);
  }
  cancel() {
    this.addTask();
    this.createForm();
  }
  addTask() {
    this.formShow = !this.formShow;
  }
  update(item) {
    this.addTask();
    this.buttonName = this.commonEnum.UPDATE;
    if (item !== undefined) {
      this.postForm.patchValue(item);
      this.postForm.setControl('stateId', new FormControl(item.stateId));
    }
  }

  save(type?: any) {
    this.submitted = true;
    if (this.postForm.invalid) {
      this.sweetAlert.showMessage(
        AlertInfo.ERROR,
        CommonEnum.MANDOTORY_FIELDS
      );
      return;
    }
    let url = STATE_URLS.createOrUpdate;
    this.processing = true;
      this.commonService.commonApiCall(
        url,
        HttpMethod.FORMDATA_POST,
        this.postForm.value,
        (res, statusFlag) => {
          if (statusFlag) {
            this.submitted = false;
            this.cancel();
            this.loadData();
          }
          this.processing = false;
        }
      );
  }
  deleteConfirm(row) {
    this.sweetAlert.confirmation(CommonEnum.DELETE, (result) => {
      if (result) {
        let url = STATE_URLS.deleteById;
        let obj ={ 'userId': this.currentUser._id,
        "stateId": row.stateId
         }
        this.commonService.commonApiCall(
          url,
          HttpMethod.POST,
          obj,
          (res, statusFlag) => {
            if (statusFlag) {
              this.loadData();
            }
          }
        );
      }
    });
  }

}
