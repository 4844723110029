import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  isCollapsed: boolean;
  activeLink: number = 1; // Default active link
  targetcredit = true;
  targetreplace = false;
  targetreturn = false;
  targetcookie = false;
  targetuse = false;

  constructor(private scroller: ViewportScroller) {
    this.isCollapsed = true;
  }

  ngOnInit(): void {}

  setActiveLink(linkNumber: number) {
    this.activeLink = linkNumber;
    if (this.activeLink == 1) {
      // this.scroller.scrollToAnchor('targetcredit');
      this.targetcredit = true;
      this.targetreplace = false;
      this.targetreturn = false;
      this.targetcookie = false;
      this.targetuse = false;
    }
    else if (this.activeLink == 2) {
      // document.getElementById('targetreplace').scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      //   inline: 'nearest',
      // });
      this.targetcredit = false;
      this.targetreplace = true;
      this.targetreturn = false;
      this.targetcookie = false;
      this.targetuse = false;
    }
    else if (this.activeLink == 3) {
      // document.getElementById('targetreturn').scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      //   inline: 'nearest',
      // });
      this.targetcredit = false;
      this.targetreplace = false;
      this.targetreturn = true;
      this.targetcookie = false;
      this.targetuse = false;
    }
    else if (this.activeLink == 4) {
      // document.getElementById('targetcookie').scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      //   inline: 'nearest',
      // });
      this.targetcredit = false;
      this.targetreplace = false;
      this.targetreturn = false;
      this.targetcookie = true;
      this.targetuse = false;
    }
    else if (this.activeLink == 5) {
      // document.getElementById('targetuse').scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      //   inline: 'nearest',
      // });
      this.targetcredit = false;
      this.targetreplace = false;
      this.targetreturn = false;
      this.targetcookie = false;
      this.targetuse = true;
    }
  }

  collapseNavMenu() {
    if (!this.isCollapsed) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
